// api.js
import axios from 'axios';
import { toast } from 'react-toastify';
import config from  '../configs';


export const httpService = axios.create({
  baseURL: config.apiBaseUrl
});

httpService.interceptors.request.use(
  (configure) => {
    //console.log(configure, "configure");
    const token = localStorage.getItem('accessToken');
    if (token && token !== null) {
      // Create a new object with the headers to avoid the no-param-reassign warning
      return {
        ...configure,
        headers: {
          ...configure.headers,
          Authorization: `Bearer ${token}`
        }
      };
    }
    return configure;
  },
  (error) => {
    return Promise.reject(error);
  }
);

//* Axios interceptor for handling status code errors
httpService.interceptors.response.use(
  (response) => response,
  async (error) => {
    // console.log(error, "error error");
    //const navigate = useNavigate();
    // const originalRequest = error.config;

    if (error.response) {
      const { status, data } = error.response;

      if (status === 401) {
          localStorage.clear();
          toast.error(data.message);
          setTimeout(function () {
            window.location.reload(false);
        }, 500);
        // Unauthorized error (e.g., token expired)
        // const refreshTokenValue = store.getState().auth.refreshToken;
        // if (refreshTokenValue) {
        //   try {
        //     // Use your API endpoint to refresh the token
        //     // const response = await api.post("/auth/refresh", {
        //     //   refreshToken: refreshTokenValue,
        //     // });
        //     // // Update the access token with the new one
        //     // //store.dispatch(updateAccessToken(response.data.accessToken));
        //     // // Retry the original request with the new access token
        //     // originalRequest.headers[
        //     //   "Authorization"
        //     // ] = `Bearer ${response.data.accessToken}`;
        //     // return api(originalRequest);
        //   } catch (refreshError) {
        //     // Handle token refresh error (e.g., refresh token expired)
        //     console.error("Token refresh failed:", refreshError);
        //     ///store.dispatch(logout()); // Logout the user or handle the error
        //   }
        // } else {
        //   // No refresh token available, handle logout or redirect to login
        //   console.error("No refresh token available.");
        //   //store.dispatch(logout()); // Logout the user or handle the error
        // }

      } else if (status === 403) {
        // Handle forbidden error (e.g., insufficient permissions)
        // Redirect to a page or display an error message
      } else if (status === 400) {
        // Handle forbidden error (e.g., insufficient permissions)
        // Redirect to a page or display an error message
      } else if (status === 429) {
        if (data && data.message) {
          toast.error(data.message);
        }
        // Handle forbidden error (e.g., insufficient permissions)
        // Redirect to a page or display an error message
      } else if (status === 409) {
        if (data && data.message) {
          toast.error(data.message);
        }
      }
    }

    return Promise.reject(error);
  }
);

//export default httpService;



// import axios from 'axios';

// axios.interceptors.request.use(
//   function (config) {
//     // if(localStorage.getItem('accessToken')){
//     //     config.headers.common['Authorization'] = localStorage.getItem('accessToken');
//     // }
//     return config;
//   },
//   function (error) {
//     return Promise.reject(error);
//   }
// );

// axios.interceptors.response.use(
//   (response) => {
//     return response;
//   },
//   (error) => {
//     //  let axois_url = error.response.config.url;

//     if (error.response.status === 401) {
//       localStorage.removeItem('accessToken');
//       localStorage.removeItem('refreshToken');
//       localStorage.removeItem('userData');
//       localStorage.removeItem('roles');
//       localStorage.removeItem('modules');

//       return (window.location.href = '/');
//     }
//     return Promise.reject(error);
//   }
// );

// export const httpService = {
//   get,
//   post,
//   put,
//   delete: _delete,
// };

// function get(url) {
//   if (localStorage.getItem('accessToken')) {
//     axios.defaults.headers.common['Authorization'] =
//       'Bearer ' + localStorage.getItem('accessToken');
//   }
//   return axios
//     .get(url)
//     .then(handleResponse)
//     .then((res) => {
//       return res;
//     })
//     .catch(function (error) {
//       console.log(error);
//     });
// }

// function post(url, postData) {
//   if (localStorage.getItem('accessToken')) {
//     axios.defaults.headers.common['Authorization'] =
//       'Bearer ' + localStorage.getItem('accessToken');
//   }
//   return axios
//     .post(url, postData)
//     .then(handleResponse)
//     .then((res) => {
//       return res;
//     })
//     .catch(function (error) {
//       console.log(error);
//     });
// }

// function put(url, postData) {
//   if (localStorage.getItem('accessToken')) {
//     axios.defaults.headers.common['Authorization'] =
//       'Bearer ' + localStorage.getItem('accessToken');
//   }
//   return axios
//     .put(url, postData)
//     .then(handleResponse)
//     .then((res) => {
//       return res;
//     })
//     .catch(function (error) {
//       console.log(error);
//     });
// }

// function _delete(url) {
//   if (localStorage.getItem('accessToken')) {
//     axios.defaults.headers.common['Authorization'] =
//       'Bearer ' + localStorage.getItem('accessToken');
//   }
//   return axios
//     .delete(url)
//     .then(handleResponse)
//     .then((res) => {
//       return res;
//     })
//     .catch(function (error) {
//       console.log(error);
//     });
// }

// function handleResponse(response) {
//   if (response.status !== 200) {
//     if (response.status === 401) {
//       // auto logout if 401 response returned from api
//       //location.reload(true);
//     }
//     const error =
//       (response.data && response.data.message) || response.statusText;
//     return Promise.reject(error);
//   }
//   return response.data;
// }
