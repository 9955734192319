import { TICKET } from '../configs/ApiActionUrl';
import { httpService } from './httpService';
export const ticketService = {
    getAll,
    add,
    edit,
    update,
    deleteTicket,
    changeOrder
}

function getAll() {
    return httpService.get(TICKET.GETALL)
        .then(res => {
            // Check the status and return data if it's truthy
            if (res.data) {
                return res.data;
            }
            return res;
        });
}

function add(data){
    return httpService.post(TICKET.ADD, data, {headers: {'Content-Type': 'multipart/form-data'}})
    .then(res => {
        if (res.data) {
            return res.data;
        }
        return res;
    });
}
function edit(id){
    return httpService.get(TICKET.GET_INDIVIDUAL+"/"+id)
        .then(res => {
            if (res.data) {
                return res.data;
            }
            return res;
        });
}
function update(code, data){
    return httpService.put(TICKET.UPDATE_INDIVIDUAL+"/"+code, data, {headers: {'Content-Type': 'multipart/form-data'}})   
    .then(res => {
        if (res.data) {
            return res.data;
        }
        return res;
    });
}


function deleteTicket (code){
    return httpService.delete(TICKET.DELETE+"/"+code, {headers: {'Content-Type': 'multipart/form-data'}}) 
        .then(res => {
        if (res.data) {
            return res.data;
        }
        return res;
    });
}

function changeOrder(code, newRankOrder){
    return httpService.put(TICKET.CHANGE_ORDER+"/rankOrder/"+code, {newRankOrder})   
    .then(res => {
        if (res.data) {
            return res.data;
        }
        return res;
    });
}
