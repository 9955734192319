export const settingTypes = {
    GETALL_SETTING_REQUEST  : 'GETALL_SETTING_REQUEST',
    GETALL_SETTING_SUCCESS  : 'GETALL_SETTING_SUCCESS',
    GETALL_SETTING_ERROR  : 'GETALL_SETTING_ERROR',

    ADD_SETTING_REQUEST : 'ADD_SETTING_REQUEST',
    ADD_SETTING_SUCCESS : 'ADD_SETTING_SUCCESS',
    ADD_SETTING_ERROR  : 'ADD_SETTING_ERROR',

    EDIT_SETTING_REQUEST : 'EDIT_SETTING_REQUEST',
    EDIT_SETTING_SUCCESS : 'EDIT_SETTING_SUCCESS',
    EDIT_SETTING_ERROR  : 'EDIT_SETTING_ERROR',

    UPDATE_SETTING_REQUEST : 'UPDATE_SETTING_REQUEST',
    UPDATE_SETTING_SUCCESS : 'UPDATE_SETTING_SUCCESS',
    UPDATE_SETTING_ERROR  : 'UPDATE_SETTING_ERROR',

    CHANGE_PASSWORD_REQUEST : 'CHANGE_PASSWORD_REQUEST',
    CHANGE_PASSWORD_SUCCESS : 'CHANGE_PASSWORD_SUCCESS',
    CHANGE_PASSWORD_ERROR  : 'CHANGE_PASSWORD_ERROR',

}