import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import CardActions from "@mui/material/CardActions";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import config from "../../configs";
import { gamesliderAction } from "../../redux/actions/gamesliderAction";

export default function GameSliderView() {
  const [statuss, setStatuss] = useState(true);
  const [data, setData] = React.useState([]);
  const [images, setImages] = React.useState([]);
  const dispatch = useDispatch();
  const { id } = useParams();

  const handleStatusChange = (event) => {
    setStatuss(event.target.value);
  };

  useEffect(() => {
    dispatch(gamesliderAction.edit(id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const { gameSliderData, gameSliderData_status } = useSelector(
    (state) => state.gameslider
  );

  useEffect(() => {
    if (gameSliderData_status === true) {
      setData(gameSliderData);
    }
  }, [gameSliderData_status, gameSliderData]);

  useEffect(() => {
    if (data && data !== undefined && data !== "") {
      if (data.status !== undefined) {
        setStatuss(data.status);
      }
      if (data.imagePath && data.imagePath !== undefined) {
        let arrValue = [];
        let arrObject = {
          path: data.imagePath,
        };
        arrValue.push(arrObject);
        setImages(arrValue);
      }
    }
  }, [data]);

  return (
    <Box sx={{ mb: 3 }} className="cardWrapper">
      <div className="pageTitleWrap">
        <Stack direction="row" spacing={1} alignItems="center">
          <Typography
            type="submit"
            variant="text"
            component={Link}
            to={"/gameslider/view/" + id}
          >
            {" "}
            Game Slider
          </Typography>
          <Typography className="" component={"h2"} variant="h2">
            / View Slider
          </Typography>
        </Stack>
      </div>
      <Formik
        initialValues={{
          name: data && data.name ? data.name : "",
          sliderUrl:
            data && data.sliderUrl ? data.sliderUrl : "",
          status: statuss,
        }}
        enableReinitialize
        onSubmit={(values, { setErrors, setSubmitting }) => {}}
      >
        {({
          errors,
          touched,
          values,
          handleChange,
          handleBlur,
          handleSubmit,
          handleReset,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit}>
            <Card sx={{}} variant="outlined" className="formWrapper">
              <CardHeader title="View" />
              <CardContent>
                <Grid container spacing={2} alignItems="stretch">
                  <Grid item lg={5} xl={5} sx={{ pr: 5 }}>
                    <TextField
                      error={errors.productName && touched.productName}
                      label="Name"
                      fullWidth
                      name="name"
                      value={values.name}
                      onChange={handleChange}
                      disabled
                      onBlur={handleBlur}
                      helperText={errors.name && touched.name && errors.name}
                      margin="normal"
                      variant="outlined"
                    />
                    <TextField
                      error={errors.sliderUrl && touched.sliderUrl}
                      label="URL"
                      fullWidth
                      name="sliderUrl"
                      value={values.sliderUrl}
                      disabled
                      onChange={handleChange}
                      onBlur={handleBlur}
                      helperText={
                        errors.sliderUrl &&
                        touched.sliderUrl &&
                        errors.sliderUrl
                      }
                      margin="normal"
                      variant="outlined"
                    />
                    <FormControl
                      sx={{ mt: 2, width: "100%", textAlign: "left" }}
                    >
                      <FormLabel id="demo-row-radio-buttons-group-label">
                        Status{" "}
                      </FormLabel>
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="status"
                        value={statuss}
                        onChange={(event) => {
                          handleChange(event);
                          handleStatusChange(event);
                        }}
                      >
                        <FormControlLabel
                          disabled
                          value={true}
                          control={<Radio />}
                          label="Active"
                        />
                        <FormControlLabel
                          disabled
                          value={false}
                          control={<Radio />}
                          label="Inactive"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>

                  <Grid item lg={7} xl={7} sx={{}}>
                    <div className="upload__image-wrapper">
                      <Card
                        sx={{
                          height: "95%",
                          border: "1px solid rgba(20,20,20,0.2)",
                          minHeight: "250px",
                          maxHeight: "250px",
                          display: "flex",
                          justifyContent: "center",
                          background:"#f8f8f8",
                          alignItems: "center",
                        }}
                        elevation={0}
                      >
                        {images && images.length > 0 ? (
                          images.map((image, index) => (
                            <div key={index} className="image-item">
                              <CardMedia
                                component="img"
                                height="100%"
                                image={
                                  image.path
                                    ? config.apiBaseUrl + "/" + image.path
                                    : image.data_url
                                }
                                alt="green iguana"
                              />
                              <div className="image-item__btn-wrapper"></div>
                            </div>
                          ))
                        ) : (
                          <CardContent
                            sx={{ p: "0 !important", height: "100%" }}
                          >
                            <Box>
                              <span className="upload_icon">
                                <i className="icon-upload"></i>
                              </span>
                              <span className="upload_content">
                                Upload Icon
                              </span>
                            </Box>
                          </CardContent>
                        )}
                      </Card>
                      <Stack
                        className="upload__helpText"
                        direction="row"
                        spacing={2}
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Typography  variant="body1" component="p" >
                            File Size: Max 5 Mb
                        </Typography>
                        <Typography  variant="body1" component="p" >
                            File Resolution: above <b>1280 x 720</b> or <b>16:9</b> aspect ratio
                        </Typography> 
                        <Typography  variant="body1" component="p" >
                            File Format: .jpeg .jpg .png .bmp 
                        </Typography>
                      </Stack>
                    </div>
                  </Grid>
                </Grid>
              </CardContent>
              <CardActions sx={{ justifyContent: "center" }}>
                <Button
                  type="button"
                  variant="contained"
                  color="primary"
                  component={Link}
                  to={"/gameslider/edit/" + id}
                >
                  Edit
                </Button>
              </CardActions>
            </Card>
          </form>
        )}
      </Formik>
    </Box>
  );
}
