import { newsarticleTypes } from '../types/newsarticleTypes'
const initialState = {loading:false, newsarticle_data:[], newsarticle_status:false, newsArticleData:[], newsArticleeData_status:false};
export const newsarticle = (state = initialState , action) => {
    switch(action.type){
        case newsarticleTypes.GETALL_NEWSARTICLE_REQUEST:
            return {
                loading: true
            };
        case newsarticleTypes.GETALL_NEWSARTICLE_SUCCESS :
            return {
                ...state,
                loading: false,
                newsarticle_status:true,
                newsarticle_data:action.data.NewsArticle
            }
            
        case newsarticleTypes.GETALL_NEWSARTICLE_ERROR :
            return {
                loading: false,
                error : action.error
            }
            case newsarticleTypes.ADD_NEWSARTICLE_REQUEST :
                return {
                    loading: true,
                    data : action.data
                };
            case newsarticleTypes.ADD_NEWSARTICLE_SUCCESS :
                return  {
                    loading: false,
                    item:action.data
                }
            case newsarticleTypes.ADD_NEWSARTICLE_ERROR :
                return {
                    loading: false,
                    error : action.error,
                }    
            case newsarticleTypes.EDIT_NEWSARTICLE_REQUEST :
                return {
                    loading: true,
                };
            case newsarticleTypes.EDIT_NEWSARTICLE_SUCCESS :
                return  {
                    ...state,
                    loading: false,
                    newsArticleData_status: true, 
                    newsArticleData:action.data,
                }
            case newsarticleTypes.EDIT_NEWSARTICLE_ERROR :
                return {
                    loading: false,
                    error : action.error
                }
    
            case newsarticleTypes.UPDATE_NEWSARTICLE_REQUEST :
                return {
                    loading: true,
                    data : action.data
                };
            case newsarticleTypes.UPDATE_NEWSARTICLE_SUCCESS :
                return  {
                    loading: false,
                    item:action.data
                }
            case newsarticleTypes.UPDATE_NEWSARTICLE_ERROR :
                return {
                    loading: false,
                    error : action.error, 
                }
            default :
                return state
    }
}