export const cueIntegrationTypes = {
    GETALL_CUE_INTEGRATION_REQUEST  : 'GETALL_CUE_INTEGRATION_REQUEST',
    GETALL_CUE_INTEGRATION_SUCCESS  : 'GETALL_CUE_INTEGRATION_SUCCESS',
    GETALL_CUE_INTEGRATION_ERROR  : 'GETALL_CUE_INTEGRATION_ERROR',

    ADD_CUE_INTEGRATION_REQUEST : 'ADD_CUE_INTEGRATION_REQUEST',
    ADD_CUE_INTEGRATION_SUCCESS : 'ADD_CUE_INTEGRATION_SUCCESS',
    ADD_CUE_INTEGRATION_ERROR  : 'ADD_CUE_INTEGRATION_ERROR',

    EDIT_CUE_INTEGRATION_REQUEST : 'EDIT_CUE_INTEGRATION_REQUEST',
    EDIT_CUE_INTEGRATION_SUCCESS : 'EDIT_CUE_INTEGRATION_SUCCESS',
    EDIT_CUE_INTEGRATION_ERROR  : 'EDIT_CUE_INTEGRATION_ERROR',

    UPDATE_CUE_INTEGRATION_REQUEST : 'UPDATE_CUE_INTEGRATION_REQUEST',
    UPDATE_CUE_INTEGRATION_SUCCESS : 'UPDATE_CUE_INTEGRATION_SUCCESS',
    UPDATE_CUE_INTEGRATION_ERROR  : 'UPDATE_CUE_INTEGRATION_ERROR',
}