import { userAuthTypes } from '../types/userAuthTypes';
const initialState = { verifyLinkStatus: false, isLoading: false };

export const userauthentication = (state = initialState, action) => {
  switch (action.type) {
    case userAuthTypes.USER_RESET_PASSWORD_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case userAuthTypes.USER_RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.data,
      };
    case userAuthTypes.USER_RESET_PASSWORD_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    case userAuthTypes.USER_GET_RESET_PASSWORD_REQUEST:
      return {
        ...state,
        verifyLinkStatus: '',
        isLoading: true,
      };
    case userAuthTypes.USER_GET_RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        verifyLinkStatus: action.data,
        data: action.data,
      };
    case userAuthTypes.USER_GET_RESET_PASSWORD_ERROR:
      return {
        ...state,
        isLoading: false,
        verifyLinkStatus: false,
        error: action.error,
      };
    default:
      return state;
  }
};
