import { STADIUM_MAP } from '../configs/ApiActionUrl';
import { httpService } from './httpService';
export const mapService = { 
    getAll,
    add,
    edit,
    update,
    deleteMap,
    changeOrder
}

function getAll(){   
    return httpService.get(STADIUM_MAP.GETALL)
        .then(res => {
            if (res.data) {
                return res.data;
            }
            return res;
        });
}

function add(data){
    return httpService.post(STADIUM_MAP.ADD, data, {headers: {'Content-Type': 'multipart/form-data'}})
    .then(res => {
        if (res.data) {
            return res.data;
        }
        return res;
    });
}
function edit(id){
    return httpService.get(STADIUM_MAP.GET_INDIVIDUAL+"/"+id)
        .then(res => {
            if (res.data) {
                return res.data;
            }
            return res;
        });
}
function update(code, data){
    return httpService.put(STADIUM_MAP.UPDATE_INDIVIDUAL+"/"+code, data, {headers: {'Content-Type': 'multipart/form-data'}})   
    .then(res => {
        if (res.data) {
            return res.data;
        }
        return res;
    });
}


function deleteMap (code){
    return httpService.delete(STADIUM_MAP.DELETE+"/"+code, {headers: {'Content-Type': 'multipart/form-data'}}) 
        .then(res => {
            if (res.data) {
                return res.data;
            }
        return res;
    });
}

function changeOrder(code, newRankOrder){
    return httpService.put(STADIUM_MAP.CHANGE_ORDER+"/rankOrder/"+code, {newRankOrder})   
    .then(res => {
        if (res.data) {
            return res.data;
        }
        return res;
    });
}
