import { ticketTypes } from '../types/ticketTypes'
const initialState = {loading:false, tickets_data:[], tickets_status:false, ticketData:[], ticketData_status:false};
export const tickets = (state = initialState , action) => {
    switch(action.type){
        case ticketTypes.GETALL_TICKET_REQUEST:
            return {
                loading: true
            };
        case ticketTypes.GETALL_TICKET_SUCCESS :
            return {
                ...state,
                loading: false,
                tickets_status:true,
                tickets_data:action.data.tickets  
            }
            
        case ticketTypes.GETALL_TICKET_ERROR :
            return {
                loading: false,
                error : action.error
            }
            case ticketTypes.ADD_TICKET_REQUEST :
                return {
                    loading: true,
                    data : action.data
                };
            case ticketTypes.ADD_TICKET_SUCCESS :
                return  {
                    loading: false,
                    item:action.data
                }
            case ticketTypes.ADD_TICKET_ERROR :
                return {
                    loading: false,
                    error : action.error, 
                }    
            case ticketTypes.DELETE_TICKET_REQUEST :
                return state
            case ticketTypes.DELETE_TICKET_SUCCESS :
                return  { 
                    loading: false,
                    item:action.data
                    //items:state.items.filter(item => item.id !== action.id)
                }
            case ticketTypes.DELETE_TICKET_ERROR :
                return {}
            case ticketTypes.EDIT_TICKET_REQUEST :
                return {
                    loading: true,
                };
            case ticketTypes.EDIT_TICKET_SUCCESS :
                return  {
                    ...state,
                    loading: false,
                    ticketData_status: true, 
                    ticketData:action.data,
                }
            case ticketTypes.EDIT_TICKET_ERROR :
                return {
                    loading: false,
                    error : action.error
                }
    
            case ticketTypes.UPDATE_TICKET_REQUEST :
                return {
                    loading: true,
                    data : action.data
                };
            case ticketTypes.UPDATE_TICKET_SUCCESS :
                return  {
                    loading: false,
                    item:action.data
                }
            case ticketTypes.UPDATE_TICKET_ERROR :
                return {
                    loading: false,
                    error : action.error, 
                }
            case ticketTypes.CHANGE_ORDER_TICKET_REQUEST :
                return {
                    loading: true,
                    data : action.data
                };
            case ticketTypes.CHANGE_ORDER_TICKET_SUCCESS :
                return  {
                    loading: false,
                    item:action.data
                }
            case ticketTypes.CHANGE_ORDER_TICKET_ERROR :
                return {
                    loading: false,
                    error : action.error, 
                }

            default :
                return state
    }
}