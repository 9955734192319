import { authTypes } from '../types/authTypes';
import { authService } from '../../services/authService';
import { toast } from 'react-toastify';

export const authAction = {
    login,
    forgotPassword,
    resetPassword,
    logout,
    getRestPassword,
    sessionExpired
}

function login(postData, setErrors, setSubmitting){
    return dispatch => {
        dispatch({type:authTypes.LOGIN_REQUEST});
        authService.login(postData).then(res => {
            console.log(res, "res");
            if(res.status){
                console.log(res.message, "true");
               dispatch({type:authTypes.LOGIN_SUCCESS, data:res.data});
               toast.success(res.message);
               setSubmitting(false);
            }else{
                dispatch({type:authTypes.LOGIN_ERROR, error:res.error});
                toast.error(res.message);
                setSubmitting(false);
                if(res.error) {
                    setErrors(res.error)
                }
            }
        })
    }
}

function forgotPassword(postData, setErrors, navigate, setSubmitting){
    return dispatch => {
        dispatch({type:authTypes.FORGOT_PASSWORD_REQUEST, postData});
        authService.forgotPassword(postData).then(res => {
            if(res.status){
               dispatch({type:authTypes.FORGOT_PASSWORD_SUCCESS, data:res.data});
               toast.success(res.message);
               setSubmitting(false);
               navigate("/");
            }else{
                dispatch({type:authTypes.FORGOT_PASSWORD_ERROR, error:res.error});                
                toast.error(res.message)
                if(res.error)
                setErrors(res.error)
                setSubmitting(false);
            }
        })
    }
}


function resetPassword(code, postData, setErrors,  navigate, setSubmitting){
    return dispatch => {
        dispatch({type:authTypes.RESET_PASSWORD_REQUEST, postData});
        authService.resetPassword(code, postData).then(res => {
            if(res.status){
               dispatch({type:authTypes.RESET_PASSWORD_SUCCESS, data:res.data});
               setSubmitting(false);
               navigate("/");
              toast.success(res.message);
            }else{
                dispatch({type:authTypes.RESET_PASSWORD_ERROR, error:res.error});                
                toast.error(res.message)
                setSubmitting(false);
                if(res.error)
                setErrors(res.error)
            }
        })
    }
}

function getRestPassword(postData){
    return dispatch => {
        dispatch({type:authTypes.GET_RESET_PASSWORD_REQUEST, postData});
        authService.getResetPassword(postData).then(res => {
            if(res.status){
               dispatch({type:authTypes.GET_RESET_PASSWORD_SUCCESS, data:res.link_status});
               //toast.success(res.message)
            }else{
                dispatch({type:authTypes.GET_RESET_PASSWORD_ERROR, error:res.error});
                //toast.error(res.message)
            }
        })
    }
}

function logout(){
    return dispatch => {
        authService.logout()
        dispatch({type:authTypes.LOGOUT});
        toast.success("Logged out successfully")
    }
}

function sessionExpired(){
    return dispatch => {
        authService.logout()
        dispatch({type:authTypes.LOGOUT});
        //toast.success("Logged out successfully")
    }
}
