import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams  } from 'react-router-dom';
import Typography from '@mui/material/Typography'; 
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { ticketAction } from '../../redux/actions/ticketAction';
import config from '../../configs';
import { Loader } from "../../errorPages/Loader";


export default function TicketView() {
    const [statuss, setStatuss] = useState(false);
    const [images, setImages] = React.useState([]);
    const [data, setData] = React.useState([]);
    const dispatch = useDispatch();

    const { id } = useParams();


  
    const handleStatusChange = (event) => {
        setStatuss(event.target.value);
    };

    useEffect(() => { 
        dispatch(ticketAction.edit(id)); 
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);
    
    const {ticketData , ticketData_status , loading } = useSelector(state => state.tickets);   

    useEffect(() => { 
        if(ticketData_status === true){
            setData(ticketData);
        }
    }, [ticketData_status, ticketData])


    useEffect(() => { 
        if(data && data!==undefined && data !== ""){
            if(data.status !== undefined ){
                setStatuss(data.status);
            }
            if((data.imagePath && data.imagePath !== undefined) ){
                let arrValue = [];
                let arrObject = {
                    path: data.imagePath
                }   
                arrValue.push(arrObject)
                //console.log(arrObject, "arrValue");
                setImages(arrValue);                    
            }
        }
    }, [data])
  
  return (
    <Box sx={{ mb:3 }} className='cardWrapper'>
        {loading ? <Loader/> : '' }
         <div className="pageTitleWrap">
            <Stack  direction="row" spacing={1}   alignItems="center">
                <Typography type="submit" variant="text" component={Link} to={"/ticket"} > Ticket List</Typography>
                <Typography className="" component={"h2"} variant="h2" >
                    / View Ticket
                </Typography>
            </Stack>
        </div>
        <Formik
            initialValues={{
                eventName : (data && data.eventName) ? data.eventName : '',
                buttonText: (data && data.buttonText) ? data.buttonText : '',
                buttonUrl :(data && data.buttonUrl) ? data.buttonUrl : '',
                status: statuss,
            }}
            enableReinitialize
            onSubmit={(values, {setErrors, setSubmitting}) => { 
            }}>
            {({ errors, touched, values, handleChange, handleBlur, handleSubmit, handleReset, isSubmitting }) => (
            <form onSubmit={handleSubmit}> 
             <Card sx={{ pb:"0px !important"}} variant="outlined" className="formWrapper">
                 <CardHeader title="View"/>
                 <CardContent > 
                     <Grid container spacing={2} alignItems="stretch">
                         <Grid item lg={5} xl={5} sx={{pr:5}}>
                             <TextField
                                 error={errors.eventName && touched.eventName}
                                 label="Banner Name"
                                 fullWidth
                                 name="eventName"
                                 value={values.eventName}
                                 onChange={handleChange}
                                 onBlur={handleBlur}
                                 helperText={(errors.eventName && touched.eventName) && errors.eventName}
                                 margin="normal"
                                 variant="outlined"
                                 disabled
                             />
                             <TextField
                                 error={errors.buttonText && touched.buttonText}
                                 label="Button Text"
                                 fullWidth 
                                 name="buttonText"
                                 value={values.buttonText}
                                 onChange={handleChange}
                                 onBlur={handleBlur}
                                 helperText={(errors.buttonText && touched.buttonText) && errors.buttonText}
                                 margin="normal"
                                 variant="outlined"
                                 //required={false}
                                 disabled
                                 //InputLabelProps={{ required: true }} 
                             />
                             <TextField
                                 error={errors.buttonUrl && touched.buttonUrl}
                                 label="URL"
                                 fullWidth 
                                 name="buttonUrl"
                                 value={values.buttonUrl}
                                 onChange={handleChange}
                                 onBlur={handleBlur}
                                 helperText={(errors.buttonUrl && touched.buttonUrl) && errors.buttonUrl}
                                 margin="normal"
                                 variant="outlined"
                                 //required={false}
                                 disabled
                                 //InputLabelProps={{ required: true }} 
                             />
                             <FormControl sx={{mt:2, width:"100%", textAlign:"left"}}>
                                 <FormLabel id="demo-row-radio-buttons-group-label">Status</FormLabel>
                                 <RadioGroup
                                     row
                                     aria-labelledby="demo-row-radio-buttons-group-label"
                                     name="status"
                                     value={statuss}
                                     onChange={(event) => {
                                         handleChange(event);
                                         handleStatusChange(event); 
                                     }}
                                 >
                                     <FormControlLabel disabled value={true} control={<Radio />} label="Active" />
                                     <FormControlLabel disabled value={false} control={<Radio />} label="Inactive" />
                                 </RadioGroup>
                             </FormControl>
                         </Grid>
                     
                         <Grid item lg={7} xl={7} sx={{}} >
                      
                            <div className="upload__image-wrapper">
                                <Card sx={{height:"95%", border:"1px solid rgba(20,20,20,0.2)", minHeight:"250px", maxHeight:"250px", display:"flex", justifyContent:"center", alignItems:"center"}} elevation={0}>
                                 {images && images.length > 0 ?
                                     images.map((image, index) => (
                                         <div key={index} className="image-item">
                                     <CardMedia
                                         component="img"
                                         height="100%"
                                         image={image.path ? config.apiBaseUrl+'/'+image.path : image.data_url}
                                         alt="green iguana"
                                     />
                                             <div className="image-item__btn-wrapper">
                                             </div>
                                         </div>
                                     ))
                                     :
                                     <CardContent sx={{p:"0 !important", height:"100%"}}>
                                         <Box>
                                              <span className='upload_icon'><i className='icon-upload'></i></span>
                                             <span className='upload_content'>Upload Banner Image</span>
                                         </Box>
                                     </CardContent>
                                    }
                                  </Card>
                                 <Stack className='upload__helpText' direction="row" spacing={2} justifyContent="space-between"  alignItems="center">
                                     <Typography  variant="body1" component="p" >
                                         File Size: Max 5 Mb
                                     </Typography>
                                     <Typography  variant="body1" component="p" >
                                         File Format: .jpeg .jpg .png .bmp 
                                         </Typography>
                                 </Stack>
                             </div>
                         </Grid>
                     </Grid> 
                 </CardContent>
             </Card>  
            </form>
            )}
        </Formik>    
    </Box>
  )
}
