import { shopTypes } from '../types/shopTypes'
const initialState = {loading:false, shops_data:[], shops_status:false, productData:[], productData_status:false};
export const shops = (state = initialState , action) => {
    switch(action.type){
        case shopTypes.GETALL_SHOP_REQUEST:
            return {
                loading: true
            };
        case shopTypes.GETALL_SHOP_SUCCESS :
            return {
                ...state,
                loading: false,
                shops_status:true,
                shops_data:action.data.shopList 
            }
            
        case shopTypes.GETALL_SHOP_ERROR :
            return {
                loading: false,
                error : action.error
            }
            case shopTypes.ADD_SHOP_REQUEST :
                return {
                    loading: true,
                    data : action.data
                };
            case shopTypes.ADD_SHOP_SUCCESS :
                return  {
                    loading: false,
                    item:action.data
                }
            case shopTypes.ADD_SHOP_ERROR :
                return {
                    loading: false,
                    error : action.error, 
                }    
            case shopTypes.DELETE_SHOP_REQUEST :
                return state
            case shopTypes.DELETE_SHOP_SUCCESS :
                return  { 
                    loading: false,
                    item:action.data
                    //items:state.items.filter(item => item.id !== action.id)
                }
            case shopTypes.DELETE_SHOP_ERROR :
                return {}
            case shopTypes.EDIT_SHOP_REQUEST :
                return {
                    loading: true,
                };
            case shopTypes.EDIT_SHOP_SUCCESS :
                return  {
                    ...state,
                    loading: false,
                    productData_status: true, 
                    productData:action.data,
                }
            case shopTypes.EDIT_SHOP_ERROR :
                return {
                    loading: false,
                    error : action.error
                }
    
            case shopTypes.UPDATE_SHOP_REQUEST :
                return {
                    loading: true,
                    data : action.data
                };
            case shopTypes.UPDATE_SHOP_SUCCESS :
                return  {
                    loading: false,
                    item:action.data
                }
            case shopTypes.UPDATE_SHOP_ERROR :
                return {
                    loading: false,
                    error : action.error, 
                }
            case shopTypes.CHANGE_ORDER_SHOP_REQUEST :
                return {
                    loading: true,
                    data : action.data
                };
            case shopTypes.CHANGE_ORDER_SHOP_SUCCESS :
                return  {
                    loading: false,
                    item:action.data
                }
            case shopTypes.CHANGE_ORDER_SHOP_ERROR :
                return {
                    loading: false,
                    error : action.error, 
                }

            default :
                return state
    }
}