export const promotileTypes = {
    GETALL_PROMOTILE_REQUEST  : 'GETALL_PROMOTILE_REQUEST',
    GETALL_PROMOTILE_SUCCESS  : 'GETALL_PROMOTILE_SUCCESS',
    GETALL_PROMOTILE_ERROR  : 'GETALL_PROMOTILE_ERROR',

    ADD_PROMOTILE_REQUEST : 'ADD_PROMOTILE_REQUEST',
    ADD_PROMOTILE_SUCCESS : 'ADD_PROMOTILE_SUCCESS',
    ADD_PROMOTILE_ERROR  : 'ADD_PROMOTILE_ERROR',

    EDIT_PROMOTILE_REQUEST : 'EDIT_PROMOTILE_REQUEST',
    EDIT_PROMOTILE_SUCCESS : 'EDIT_PROMOTILE_SUCCESS',
    EDIT_PROMOTILE_ERROR  : 'EDIT_PROMOTILE_ERROR',

    UPDATE_PROMOTILE_REQUEST : 'UPDATE_PROMOTILE_REQUEST',
    UPDATE_PROMOTILE_SUCCESS : 'UPDATE_PROMOTILE_SUCCESS',
    UPDATE_PROMOTILE_ERROR  : 'UPDATE_PROMOTILE_ERROR',
}