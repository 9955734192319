import { PLAYERINFO } from '../configs/ApiActionUrl';
import { httpService } from './httpService';
export const playerInfoService = {
    getAll,
    getSingleItem,
    update
}

function getAll(){   
    return httpService.get(PLAYERINFO.GETALL)
        .then(res => {
            if (res.data) {
                return res.data;
            }
            return res;
        });
}
function getSingleItem(id){
    return httpService.get(PLAYERINFO.GET_INDIVIDUAL+"/"+id)
        .then(res => {
            if (res.data) {
                return res.data;
            }
            return res;
        });
}
function update(code, data){
    return httpService.put(PLAYERINFO.UPDATE_INDIVIDUAL+"/"+code, data, {headers: {'Content-Type': 'multipart/form-data'}})   
    .then(res => {
        if (res.data) {
            return res.data;
        }
        return res;
    });
}
