import React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import logo from '../assets/img/fc_dallas_logo.png';
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';

const UserSuccessResetPassword = () => {
  return (
    <div className="auth loginbox main-content my-5">
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
          background: '#fff',
        }}
      >
        <Box
          sx={{
            // m: 1,
            textAlign: 'center',
            p: 4,
            background: '#e6e6e6',
            borderRadius: '8px',
            //},
          }}
        >
          <img src={logo} alt="Logo" />
          <Paper
            elevation={0}
            sx={{
              m: 1,
              mt: 3,
              background: '#ffffff',
              width: 320,
              padding: '44px 50px 44px 44px',
              textAlign: 'center',
              border: '1px solid #E5E5E5',
              boxShadow: '0px 4px 29px 0px rgba(0, 1, 1, 0.15)',
              borderRadius: '4px',
              boxSizing: 'border-box',
            }}
          >
            <Avatar
              sx={{
                justifyContent: 'center',
                margin: '0  auto 20px',
                width: 65,
                height: 65,
                bgcolor: '#001f5b',
              }}
            >
              <DoneOutlineIcon />
            </Avatar>
            <Typography
              variant="h3"
              gutterBottom
              component="h3"
              mb={1}
              sx={{ fontSize: '30px' }}
            >
              Congratulations!
            </Typography>
            <Typography variant="subtitle1" gutterBottom component="" mb={1}>
              Your password has been changed successfully.
            </Typography>
          </Paper>
        </Box>
      </Box>
    </div>
  );
};
export default UserSuccessResetPassword;
