export const shopTypes = {
    GETALL_SHOP_REQUEST  : 'GETALL_SHOP_REQUEST',
    GETALL_SHOP_SUCCESS  : 'GETALL_SHOP_SUCCESS',
    GETALL_SHOP_ERROR  : 'GETALL_SHOP_ERROR',

    ADD_SHOP_REQUEST : 'ADD_SHOP_REQUEST',
    ADD_SHOP_SUCCESS : 'ADD_SHOP_SUCCESS',
    ADD_SHOP_ERROR  : 'ADD_SHOP_ERROR',

    EDIT_SHOP_REQUEST : 'EDIT_SHOP_REQUEST',
    EDIT_SHOP_SUCCESS : 'EDIT_SHOP_SUCCESS',
    EDIT_SHOP_ERROR  : 'EDIT_SHOP_ERROR',

    UPDATE_SHOP_REQUEST : 'UPDATE_SHOP_REQUEST',
    UPDATE_SHOP_SUCCESS : 'UPDATE_SHOP_SUCCESS',
    UPDATE_SHOP_ERROR  : 'UPDATE_SHOP_ERROR',

    DELETE_SHOP_REQUEST : 'DELETE_SHOP_REQUEST',
    DELETE_SHOP_SUCCESS : 'DELETE_SHOP_SUCCESS',
    DELETE_SHOP_ERROR  : 'DELETE_SHOP_ERROR',

    CHANGE_ORDER_SHOP_REQUEST : 'CHANGE_ORDER_SHOP_REQUEST',
    CHANGE_ORDER_SHOP_SUCCESS : 'CHANGE_ORDER_SHOP_SUCCESS',
    CHANGE_ORDER_SHOP_ERROR  : 'CHANGE_ORDER_SHOP_ERROR',


}