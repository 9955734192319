import { bannerTypes } from '../types/bannerTypes'
const initialState = {loading:false, banners_data:[], banners_status:false, bannerData:[], bannerData_status:false};
export const banners = (state = initialState , action) => {
    switch(action.type){
        case bannerTypes.GETALL_BANNER_REQUEST:
            return {
                loading: true
            };
        case bannerTypes.GETALL_BANNER_SUCCESS :
            return {
                ...state,
                loading: false,
                banners_status:true,
                banners_data:action.data.OfferBanners 
            }
            
        case bannerTypes.GETALL_BANNER_ERROR :
            return {
                loading: false,
                error : action.error
            }
            case bannerTypes.ADD_BANNER_REQUEST :
                return {
                    loading: true,
                    data : action.data
                };
            case bannerTypes.ADD_BANNER_SUCCESS :
                return  {
                    loading: false,
                    item:action.data
                }
            case bannerTypes.ADD_BANNER_ERROR :
                return {
                    loading: false,
                    error : action.error, 
                }    
            case bannerTypes.DELETE_BANNER_REQUEST :
                return state
            case bannerTypes.DELETE_BANNER_SUCCESS :
                return  { 
                    loading: false,
                    item:action.data
                    //items:state.items.filter(item => item.id !== action.id)
                }
            case bannerTypes.DELETE_BANNER_ERROR :
                return {}
            case bannerTypes.EDIT_BANNER_REQUEST :
                return {
                    loading: true,
                };
            case bannerTypes.EDIT_BANNER_SUCCESS :
                return  {
                    ...state,
                    loading: false,
                    bannerData_status: true, 
                    bannerData:action.data,
                }
            case bannerTypes.EDIT_BANNER_ERROR :
                return {
                    loading: false,
                    error : action.error
                }
    
            case bannerTypes.UPDATE_BANNER_REQUEST :
                return {
                    loading: true,
                    data : action.data
                };
            case bannerTypes.UPDATE_BANNER_SUCCESS :
                return  {
                    loading: false,
                    item:action.data
                }
            case bannerTypes.UPDATE_BANNER_ERROR :
                return {
                    loading: false,
                    error : action.error, 
                }
            case bannerTypes.CHANGE_ORDER_BANNER_REQUEST :
                return {
                    loading: true,
                    data : action.data
                };
            case bannerTypes.CHANGE_ORDER_BANNER_SUCCESS :
                return  {
                    loading: false,
                    item:action.data
                }
            case bannerTypes.CHANGE_ORDER_BANNER_ERROR :
                return {
                    loading: false,
                    error : action.error, 
                }

            default :
                return state
    }
}