import { tradableBitsTypes } from '../types/tradableBitsTypes'
const initialState = {loading:false, bits_data:[], bits_status:false, tradableBitsData:[], tradableBitsData_status:false};
export const tradablebits = (state = initialState , action) => {
    switch(action.type){
        case tradableBitsTypes.GETALL_TRADABLEBITS_REQUEST:
            return {
                loading: true
            };
        case tradableBitsTypes.GETALL_TRADABLEBITS_SUCCESS :
            return {
                ...state,
                loading: false,
                bits_status:true,
                bits_data:action.data.tradableBits  
            }
            
        case tradableBitsTypes.GETALL_TRADABLEBITS_ERROR :
            return {
                loading: false,
                error : action.error
            }
            case tradableBitsTypes.ADD_TRADABLEBITS_REQUEST :
                return {
                    loading: true,
                    data : action.data
                };
            case tradableBitsTypes.ADD_TRADABLEBITS_SUCCESS :
                return  {
                    loading: false,
                    item:action.data
                }
            case tradableBitsTypes.ADD_TRADABLEBITS_ERROR :
                return {
                    loading: false,
                    error : action.error, 
                }    
            case tradableBitsTypes.EDIT_TRADABLEBITS_REQUEST :
                return {
                    loading: true,
                };
            case tradableBitsTypes.EDIT_TRADABLEBITS_SUCCESS :
                return  {
                    ...state,
                    loading: false,
                    tradableBitsData_status: true, 
                    tradableBitsData:action.data,
                }
            case tradableBitsTypes.EDIT_TRADABLEBITS_ERROR :
                return {
                    loading: false,
                    error : action.error
                }
    
            case tradableBitsTypes.UPDATE_TRADABLEBITS_REQUEST :
                return {
                    loading: true,
                    data : action.data
                };
            case tradableBitsTypes.UPDATE_TRADABLEBITS_SUCCESS :
                return  {
                    loading: false,
                    item:action.data
                }
            case tradableBitsTypes.UPDATE_TRADABLEBITS_ERROR :
                return {
                    loading: false,
                    error : action.error, 
                }
            default :
                return state
    }
}