import { mapTypes } from '../types/mapTypes';
import { mapService } from '../../services/mapService';
import { toast } from 'react-toastify';

export const mapAction = {
    getAll,
    add,
    edit,
    update,
    deleteMap,
    changeOrder
}

function getAll() {
    return dispatch => {
        dispatch({type:mapTypes.GETALL_MAP_REQUEST}); 
        mapService.getAll().then(res => {
            if (res.status) {
                dispatch({type:mapTypes.GETALL_MAP_SUCCESS, data: res.data });
                //toast.success(res.message);
            } else {
                dispatch({type:mapTypes.GETALL_MAP_ERROR}); 
                toast.error(res.message);
            }
        })

    }
}
function add(data, setErrors, setSubmitting, navigate){
    return dispatch => {
        dispatch({type:mapTypes.ADD_MAP_REQUEST});
        mapService.add(data).then(res => {
            if(res.status){
               dispatch({type:mapTypes.ADD_MAP_SUCCESS, data});
               toast.success(res.message);
               setSubmitting(false);
               navigate(-1);
            }else{
                dispatch({type:mapTypes.ADD_MAP_ERROR, error:res.error});
                toast.error(res.message);
                setSubmitting(false);
                if(res.error) {
                    setErrors(res.error);
                }
            }
        })
    }
}

function deleteMap(code, closeModal){ 
    return dispatch => {
        dispatch({type:mapTypes.DELETE_MAP_REQUEST});
        mapService.deleteMap(code).then(res => { 
            if(res.status){
                dispatch({type:mapTypes.DELETE_MAP_SUCCESS, code});
                toast.success(res.message);
                closeModal();
                setTimeout(function () {
                    dispatch(mapAction.getAll());
                }, 500);
            }else{
                dispatch({type:mapTypes.DELETE_MAP_ERROR, error:res.error});
                toast.error(res.message);
            }
        })
    }
}

function update(code, data, setErrors, setSubmitting, navigate){    
    return dispatch => {
        dispatch({type:mapTypes.UPDATE_MAP_REQUEST});
        mapService.update(code,  data).then(res => {
            if(res.status){
                dispatch({type:mapTypes.UPDATE_MAP_SUCCESS, data:res.data});
                toast.success(res.message);
                setSubmitting(false);
                navigate(-1);
            }else{ 
                dispatch({type:mapTypes.UPDATE_MAP_ERROR, error:res.error});
                toast.error(res.message);
                setSubmitting(false);
                if(res.error) {
                    setErrors(res.error);
                    setSubmitting(false);
                }
            }
        })
    }
}

function edit(id){
    return dispatch => {
        dispatch({type:mapTypes.EDIT_MAP_REQUEST});
        mapService.edit(id).then(res => {
            if(res.status){
                dispatch({type:mapTypes.EDIT_MAP_SUCCESS, data:res.data})
            }else{
                dispatch({type:mapTypes.EDIT_MAP_ERROR, error:res.error});
                toast.error(res.message);
            }
        })
    }
}

function changeOrder(id, newRankOrder){
    return dispatch => {
        dispatch({type:mapTypes.CHANGE_ORDER_MAP_REQUEST});
        mapService.changeOrder(id, newRankOrder).then(res => {
            if(res.status){
                dispatch({type:mapTypes.CHANGE_ORDER_MAP_SUCCESS});
                toast.success(res.message);
            }else{
                dispatch({type:mapTypes.CHANGE_ORDER_MAP_ERROR, error:res.error});
                toast.error(res.message);
            }
        })
    }
}
