import React, { useEffect }  from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate  } from 'react-router-dom';
import { Formik } from 'formik';
import logo from '../../assets/img/fc_dallas_logo.png';
import login_bg from '../../assets/img/forgot_password_bg.png';
import { ForgotPaswdValidate } from '../../assets/validation/validation';
import { authAction } from './../../redux/actions/authAction';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import { Loader } from '../../errorPages/Loader';

const ForgetPasswordForm = (props) => {
    let navigate = useNavigate();
    const { loggedIn, isLoading } = useSelector(state => state.authentication); 
    const dispatch = useDispatch();


    // reset login status
    useEffect(() => { 
        if(loggedIn){
            navigate('/dashboard'); 
        }
    }, [loggedIn, navigate]);


    return (
        <div>
        { isLoading === true ? <Loader/> : '' }
        <Formik
            initialValues={{
                email: '',
            }}
            enableReinitialize
            validationSchema={ForgotPaswdValidate}
            onSubmit={(values, {setErrors, setSubmitting}) => {
                // var form_data = new FormData();
                // form_data.append('email', values.email);
               dispatch(authAction.forgotPassword(values, setErrors, navigate, setSubmitting));
            }}>
            {({ errors, touched, values, handleChange, handleBlur, handleSubmit,  isSubmitting }) => (
                <form onSubmit={handleSubmit}> 
                    <div className="form_wrapper">
                        <Box sx={{
                              display: 'flex',
                              flexWrap: 'wrap',
                              justifyContent: 'center',
                              alignItems: 'center',
                              height: '100vh',
                        }}>
                            <div>
                            <Box className='login__wrap'>
                                <Grid container alignItems={"center"}>
                                    <Grid item xs={6} sx={{p:6  }}>
                                        <img src={logo} alt="Logo" />
                                        <Typography variant="h3" gutterBottom component=""  mb={2}>
                                            Forget Password
                                        </Typography>
                                        <Paper elevation={0} sx={{background:"none"}}>
                                            <TextField
                                                error={errors.email && touched.email}
                                                label="Email Address"
                                                fullWidth 
                                                name="email"
                                                value={values.email}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                helperText={(errors.email && touched.email) && errors.email}
                                                margin="normal"
                                                variant="standard"
                                                required={false}
                                                InputLabelProps={{ required: true }} 
                                                InputProps={{
                                                    endAdornment:
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                            aria-label="toggle password visibility"
                                                            disabled
                                                            //onClick={handleClickShowPassword}
                                                            edge="end"
                                                            >
                                                            {<i className='icon-email'></i>}
                                                            </IconButton>
                                                        </InputAdornment>
                                                }}

                                            />
                                            <Button 
                                                type="submit"
                                                className='submit_btn' 
                                                variant="contained" 
                                                sx={{mt:5, mb:2}}
                                                size="large"
                                            >
                                               {isSubmitting ? <CircularProgress size={20}/> :  "Submit"}
                                            </Button>
                                        </Paper>
                                        <Typography variant="body1" mt={2} align="center">
                                            Back to <Link to="/">Login</Link>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <img src={login_bg} alt="Logo" />
                                    </Grid>
                                </Grid>
                            </Box>
                            <Typography className='copyright_p' variant="body1" mt={2} align="center">
                                ©{props.year}
                            </Typography>
                            </div>
                        </Box>
                    </div>
                </form>
            )}
        </Formik>  
        </div>
    );
};
  export default ForgetPasswordForm; 