import { fanCamTypes } from '../types/fanCamTypes';
import { fanCamService } from '../../services/fanCamService'; 
import { toast } from 'react-toastify';

export const fanCamAction = { 
    getAll,
    add,
    edit,
    update,
}

function getAll() {
    return dispatch => {
        dispatch({type:fanCamTypes.GETALL_FANCAM_REQUEST}); 
        fanCamService.getAll().then(res => {
            if (res.status) {
                dispatch({type:fanCamTypes.GETALL_FANCAM_SUCCESS, data: res.data });
                //toast.success(res.message);
            } else {
                dispatch({type:fanCamTypes.GETALL_FANCAM_ERROR}); 
                toast.error(res.message);
            }
        })

    }
}
function add(data, setErrors, setSubmitting, navigate){
    return dispatch => {
        dispatch({type:fanCamTypes.ADD_FANCAM_REQUEST});
        fanCamService.add(data).then(res => {
            if(res.status){
               dispatch({type:fanCamTypes.ADD_FANCAM_SUCCESS, data});
               toast.success(res.message);
               setSubmitting(false);
               setTimeout(function () {
                    dispatch(fanCamAction.getAll());
                }, 500);
               //navigate(-1);
            }else{
                dispatch({type:fanCamTypes.ADD_FANCAM_ERROR, error:res.error});
                toast.error(res.message);
                setSubmitting(false);
                if(res.error) {
                    setErrors(res.error);
                }
            }
        })
    }
}


function update(code, data, setErrors, setSubmitting, navigate){    
    return dispatch => {
        dispatch({type:fanCamTypes.UPDATE_FANCAM_REQUEST});
        fanCamService.update(code,  data).then(res => {
            if(res.status){
                dispatch({type:fanCamTypes.UPDATE_FANCAM_SUCCESS, data:res.data});
                toast.success(res.message);
                setSubmitting(false);
                navigate("/fan-cam/view/"+code);
            }else{ 
                dispatch({type:fanCamTypes.UPDATE_FANCAM_ERROR, error:res.error});
                toast.error(res.message);
                setSubmitting(false);
                if(res.error) {
                    setErrors(res.error);
                    setSubmitting(false);
                }
            }
        })
    }
}

function edit(id){
    return dispatch => {
        dispatch({type:fanCamTypes.EDIT_FANCAM_REQUEST});
        fanCamService.edit(id).then(res => {
            if(res.status){
                dispatch({type:fanCamTypes.EDIT_FANCAM_SUCCESS, data:res.data})
            }else{
                dispatch({type:fanCamTypes.EDIT_FANCAM_ERROR, error:res.error});
                toast.error(res.message);
            }
        })
    }
}
