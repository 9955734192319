import React, { useEffect }  from 'react';
import { useDispatch, useSelector,  } from 'react-redux';
import { useNavigate, useParams  } from 'react-router-dom';
import { Formik } from 'formik';
import logo from '../../assets/img/fc_dallas_logo.png';
import login_bg from '../../assets/img/reset_pasword_bg.png';
// import '../../assets/css/custom.css'
import { ResetPasswordValidate } from '../../assets/validation/validation';
import { authAction } from './../../redux/actions/authAction';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Grid from '@mui/material/Grid';
import { Loader } from '../../errorPages/Loader';
import CircularProgress from '@mui/material/CircularProgress';
import LinkExpired from '../../errorPages/LinkExpired'; 


const ResetPasswordForm = (props) => {
    const dispatch = useDispatch();
    const { id } = useParams(); 
    let navigate = useNavigate();
    useEffect(() =>{
        if(id) { 
            dispatch(authAction.getRestPassword(id)); 
        }
    },[dispatch, id])

    const { verifyLinkStatus, isLoading } = useSelector(state => state.authentication);  

   if(verifyLinkStatus === true) {
        return (
            <div>
            { isLoading === true ? <Loader/> : '' }
            <Formik
                initialValues={{
                    password : '',
                    confirmPassword: ''
                }}
                enableReinitialize
                validationSchema={ResetPasswordValidate}
                onSubmit={(values, {setErrors, setSubmitting}) => {
                    dispatch(authAction.resetPassword(id, values, setErrors, navigate, setSubmitting));
                }}>
                {({ errors, touched, values, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                    <form onSubmit={handleSubmit}> 
                        <div className="form_wrapper">
                            <Box sx={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: '100vh',
                            }}>
                                <div>
                                <Box className='login__wrap'>
                                    <Grid container alignItems={"center"}>
                                        <Grid item xs={6} sx={{p:6  }}>
                                            <img src={logo} alt="Logo" />
                                            <Typography variant="h3" gutterBottom component=""  mb={2}>
                                                Reset Password
                                            </Typography>
                                            <Paper elevation={0} sx={{background:"none"}}>
                                            <TextField
                                                    id="filled-password-input"
                                                    fullWidth
                                                    variant="standard"
                                                    label="New Password"
                                                    name="password"
                                                    type={'password'}
                                                    autoComplete="new-password"
                                                    margin="normal"
                                                    error={errors.password && touched.password}
                                                    value={values.password}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    helperText={(errors.password && touched.password) && errors.password}
                                                    required={false}
                                                    InputLabelProps={{ required: true }} 
                                                    InputProps={{
                                                        endAdornment:
                                                            <InputAdornment position="end">
                                                                <IconButton
                                                                aria-label="toggle password visibility"
                                                                disabled
                                                                //onClick={handleClickShowPassword}
                                                                edge="end"
                                                                >
                                                                {<i className='icon-password'></i>}
                                                                </IconButton>
                                                            </InputAdornment>
                                                    }}
                                                />
                                                <TextField
                                                    id="filled-password-input"
                                                    fullWidth
                                                    variant="standard"
                                                    label="Confirm Password"
                                                    name="confirmPassword"
                                                    type={'password'}
                                                    autoComplete="confirm-password"
                                                    margin="normal"
                                                    error={errors.confirmPassword && touched.confirmPassword}
                                                    value={values.confirmPassword}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    helperText={(errors.confirmPassword && touched.confirmPassword) && errors.confirmPassword}
                                                    required={false}
                                                    InputLabelProps={{ required: true }} 
                                                    InputProps={{
                                                        endAdornment:
                                                            <InputAdornment position="end">
                                                                <IconButton
                                                                disabled
                                                                aria-label="toggle password visibility"
                                                                //onClick={handleClickShowPassword}
                                                                edge="end"
                                                                >
                                                                {<i className='icon-password'></i>}
                                                                </IconButton>
                                                            </InputAdornment>
                                                    }}
                                                />
                                                <Button 
                                                    type="submit"
                                                    className='submit_btn' 
                                                    variant="contained" 
                                                    sx={{mt:5, mb:2}}
                                                    size="large"
                                                >
                                                {isSubmitting ? <CircularProgress size={20}/> :  "Submit"}
                                                    
                                                </Button>
                                            </Paper>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <img src={login_bg} alt="Logo" />
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Typography className='copyright_p' variant="body1" mt={2} align="center">
                                    ©{props.year}
                                </Typography>
                                </div>
                            </Box>
                        </div>
                    </form>
                )}
            </Formik>  
            </div>
        );
    }else if(verifyLinkStatus === false) {
        return (
            <div>
            { isLoading === true ? <Loader/> : false }
                <LinkExpired />
            </div>
        );
    }else {
        return (
            <div>
            { isLoading === true ? <Loader/> : false }
            </div>
        );
    }
};
  export default ResetPasswordForm; 