import React, { useState, useEffect, lazy, Suspense } from 'react';
import Box from '@mui/material/Box';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams  } from 'react-router-dom';
import Typography from '@mui/material/Typography'; 
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { playerInfoAction } from '../../redux/actions/playerInfoAction';
import CircularProgress from '@mui/material/CircularProgress';
import config from '../../configs';
import { Loader } from "../../errorPages/Loader";


export default function PlayerInfoEdit() {
    const [statuss, setStatuss] = useState(true);
    const [images, setImages] = React.useState([]);
    const [orginalImages, setOrignalImages] = React.useState([]);
    const [data, setData] = React.useState([]);
    const [imageLoader, setImageLoader] = React.useState(true);
    const dispatch = useDispatch();
    const { id } = useParams();
  
    const handleStatusChange = (event) => {
        setStatuss(event.target.value);
    };

    useEffect(() => { 
        dispatch(playerInfoAction.getSingleItem(id)); 
           // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);
    
    const {singlePlayer , singlePlayerStatus , loading } = useSelector(state => state.playerInfo);   

    useEffect(() => { 
        if(singlePlayerStatus === true){
            setData(singlePlayer);
        }
    }, [singlePlayerStatus, singlePlayer])


    useEffect(() => { 
        setImageLoader(true);
        if(data && data!==undefined && data !== ""){
            if(data.status !== undefined ){
                setStatuss(data.status);
            }

            if(data?.headshot && data?.headshot?.original){
                setOrignalImages(data?.headshot?.original);
            }


            if((data?.headshot && data?.headshot?.alter_original)){
                let arrValue = [];
                let arrObject = {
                    path: data?.headshot?.alter_original
                }   
                arrValue.push(arrObject)
                setImages(arrValue);                    
            }else {
                setImages([]);            
            }
        }

        const timeoutId = setTimeout(() => {
            setImageLoader(false);
        }, 500);

        return () => {
            clearTimeout(timeoutId);
        };

    }, [data])

    console.log(images, "images");
  
  return (
    <Box sx={{ mb:3 }} className='cardWrapper'>
        {loading ? <Loader/> : '' }
         <div className="pageTitleWrap">
            <Stack  direction="row" spacing={1}   alignItems="center">
                <Typography type="submit" variant="text" component={Link} to={"/player-info"} > Player Info List</Typography>
                <Typography className="" component={"h2"} variant="h2" >
                    / View Player
                </Typography>
            </Stack>
        </div>
        <Formik
            initialValues={{
                firstName : (data && data.first_name) ? data.first_name : '',
                lastName : (data && data.last_name) ? data.last_name : '',
                shirtNumber : (data && data.jersey_number) ? data.jersey_number : '',

                altFirstName: data?.alter_first_name || '',
                altLastName: data?.alter_last_name || '',
                altShirtNumber: data?.alter_jersey_number || '',
            }}
            enableReinitialize
            onSubmit={(values) => {
                console.log(values, "values");
            }}>
            {({ errors, touched, values, handleChange, handleBlur, handleSubmit, handleReset, isSubmitting, setFieldValue }) => (
            <form onSubmit={handleSubmit}> 
                <Card sx={{}} variant="outlined" className="formWrapper">
                    <CardHeader title="View"/>
                    <CardContent > 
                        <Grid container spacing={2} alignItems="stretch">
                            <Grid item lg={6} xl={6} sx={{ pr:3}}>
                                <Grid container spacing={2} alignItems="stretch">
                                    <Grid item lg={6} xl={6} sx={{pr:3}}>
                                        <TextField
                                            error={errors.firstName && touched.firstName}
                                            label="First Name"
                                            fullWidth 
                                            name="firstName"
                                            value={values.firstName}
                                            disabled
                                            onChange={handleChange}
                                            onBlur={(e) => {
                                                const val = (e.target.value || '').replace(/\s+/gi, ' ');
                                                setFieldValue(e.target.name, val.trim());
                                                handleBlur(e);
                                            }}
                                            helperText={(errors.firstName && touched.firstName) && errors.firstName}
                                            margin="normal"
                                            variant="outlined"
                                            sx={{
                                                mb:3,
                                            }}
                                        />
                                    </Grid>
                                    <Grid item lg={6} xl={6}>
                                        <TextField
                                            error={errors.lastName && touched.lastName}
                                            label="Last Name"
                                            fullWidth 
                                            name="lastName"
                                            value={values.lastName}
                                            onChange={handleChange}
                                            disabled
                                            onBlur={handleBlur}
                                            helperText={(errors.lastName && touched.lastName) && errors.lastName}
                                            margin="normal"
                                            sx={{
                                                mb:3,
                                            }}
                                            variant="outlined"
                                        />
                                    </Grid>
                                </Grid>
                                <div className="upload__image-wrapper">
                                    <Card sx={{height:"95%", border:"1px solid rgba(20,20,20,0.2)", minHeight:"250px", maxHeight:"250px", display:"flex", justifyContent:"center", alignItems:"center"}} elevation={0}>
                                        <Suspense fallback={<div>Loading...</div>}>
                                            {imageLoader ? (
                                                <CircularProgress size={20}/> 
                                            ) : <CardMedia
                                                    component="img"
                                                    height="250"
                                                    image={orginalImages}
                                                    alt="green iguana"
                                                    sx={{objectFit: "contain" }}
                                                />  }
                                        </Suspense>
                                    </Card>

                                    <Stack className='upload__helpText' direction="row" spacing={2} justifyContent="space-between"  alignItems="center">
                                        <Typography  variant="body1" component="p" 
                                        sx={{
                                            minHeight:"14px"
                                        }}
                                        ></Typography>
                                    </Stack>
                                </div>
                                <TextField
                                    error={errors.shirtNumber && touched.shirtNumber}
                                    label="Shirt Number"
                                    fullWidth 
                                    name="shirtNumber"
                                    disabled
                                    value={values.shirtNumber}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    helperText={(errors.shirtNumber && touched.shirtNumber) && errors.shirtNumber}
                                    margin="normal"
                                    variant="outlined"
                                    sx={{
                                        mb:3,
                                    }}
                                />
                                <FormControl sx={{mt:2, width:"100%", textAlign:"left"}}>
                                    <FormLabel id="demo-row-radio-buttons-group-label">Status </FormLabel>
                                    <RadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="status"
                                        value={statuss}
                                        onChange={handleStatusChange}
                                    >
                                        <FormControlLabel value={true} control={<Radio />}  disabled label="Active" />
                                        <FormControlLabel value={false} control={<Radio />} disabled label="Inactive" />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                            <Grid item lg={6} xl={6} sx={{ pl:3}}>
                                <Grid container spacing={2} alignItems="stretch">
                                    <Grid item lg={6} xl={6} sx={{pr:3}}>
                                        <TextField
                                            error={errors.altFirstName && touched.altFirstName}
                                            label="Alternate First Name"
                                            fullWidth 
                                            disabled
                                            name="altFirstName"
                                            value={values.altFirstName}
                                            onChange={handleChange}
                                            onBlur={(e) => {
                                                const val = (e.target.value || '').replace(/\s+/gi, ' ');
                                                setFieldValue(e.target.name, val.trim());
                                                handleBlur(e);
                                            }}
                                            helperText={(errors.altFirstName && touched.altFirstName) && errors.altFirstName}
                                            margin="normal"
                                            sx={{mb:3}}
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid item lg={6} xl={6}>
                                        <TextField
                                            error={errors.altLastName && touched.altLastName}
                                            label="Alternate Last Name"
                                            fullWidth 
                                            name="altLastName"
                                            disabled
                                            value={values.altLastName}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            helperText={(errors.altLastName && touched.altLastName) && errors.altLastName}
                                            margin="normal"
                                            sx={{mb:3}}
                                            variant="outlined"
                                        />
                                    </Grid>
                                </Grid>
                                <div className="upload__image-wrapper">
                                    <Card sx={{height:"95%", border:"1px solid rgba(20,20,20,0.2)", minHeight:"250px", maxHeight:"250px", display:"flex", justifyContent:"center", alignItems:"center"}} elevation={0}>
                                    {images && images.length > 0 ?
                                        images.map((image, index) => {
                                            return(
                                                <div key={index} className="image-item">
                                                {imageLoader ? (
                                                    <CircularProgress size={20}/> 
                                                ) : (
                                                    <>
                                                        <CardMedia
                                                            component="img"
                                                            height="250"
                                                            image={image.path ? config.apiBaseUrl+'/'+image.path : image.data_url}
                                                            alt="green iguana"
                                                            sx={{ objectFit: "contain" }}
                                                        />
                                                    </>
                                                )}
                                                </div>
                                            )
                                        })
                                        :
                                        <CardContent sx={{p:"0 !important", height:"100%"}}>
                                            <Box 
                                            // onClick={onImageUpload}
                                            // {...dragProps}
                                            >
                                                <span className='upload_icon user'><i className='icon-user'></i></span>
                                            </Box>
                                        </CardContent>
                                        
                                        }
                                    </Card>
                                    <Stack className='upload__helpText' direction="row" spacing={2} justifyContent="space-between"  alignItems="center">
                                        <Typography  variant="body1" component="p">
                                            File Size: Max 5 Mb
                                        </Typography>
                                        <Typography  variant="body1" component="p">
                                            File Format: .jpeg .jpg .png .bmp 
                                        </Typography>
                                    </Stack>
                                </div>
                                <TextField
                                    error={errors.altShirtNumber && touched.altShirtNumber}
                                    label="Alternate Shirt Number"
                                    fullWidth 
                                    disabled
                                    name="altShirtNumber"
                                    value={values.altShirtNumber}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    helperText={(errors.altShirtNumber && touched.altShirtNumber) && errors.altShirtNumber}
                                    margin="normal"
                                    variant="outlined"
                                />
                            </Grid>
                        </Grid> 
                    </CardContent>
                </Card>  
            </form>
            )}
        </Formik>    
    </Box>
  )
}
