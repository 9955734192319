import * as React from 'react';
import { NavLink } from "react-router-dom";
import { useDispatch} from "react-redux";
import { useLocation} from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import CardHeader from '@mui/material/CardHeader';
import Tooltip from '@mui/material/Tooltip';
//import { createGlobalStyle } from 'styled-components';
import AdminLogo from "../../assets/img/admin_fc_dallas_logo.png"
import { authAction } from './../../redux/actions/authAction';
import ChangePassword from './ChangePassword'; 
import VideocamOutlinedIcon from '@mui/icons-material/VideocamOutlined';
import LightModeOutlinedIcon from '@mui/icons-material/LightModeOutlined';
import ViewCarouselIcon from '@mui/icons-material/ViewCarousel';
import CampaignIcon from '@mui/icons-material/Campaign';
import ArticleIcon from '@mui/icons-material/Article';
import { Scrollbars } from 'react-custom-scrollbars-2';
const drawerWidth = 140;



function SideBarLayout(props) {
  const dispatch = useDispatch();
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);


  const today = new Date();
  const year = today.getFullYear();

  const location = useLocation()
  var route = location.pathname.split("/");

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };



  const drawer = (
    <div className="sideMenuList">
      <Toolbar>
        {/* {(props.companyInfo.logoURL) ? 
              <img src={config.apiBaseUrl+'/'+props.companyInfo.logoURL} alt="logoPic" width="180" />  
          : '' } */}
        <img src={AdminLogo} alt="FC Dallas Logo" />

      </Toolbar>
      <Divider />
      <Box>
        <Scrollbars 
          universal
          autoHeight
          autoHide
          autoHeightMin={700}
          autoHeightMax={720}
          >
        <List sx={{p:0}}>
            <ListItem  component={NavLink} to="/shop"  className={(route[1] === 'shop') ? "active" : ""}>
            <ListItemIcon className="menu__icon">
              <i className='icon-shop'></i>
            </ListItemIcon>
            <ListItemText primary="Shop" />
          </ListItem>
          <ListItem  component={NavLink} to="/ticket"  className={(route[1] === 'ticket') ? "active" : ""}>
            <ListItemIcon className="menu__icon">
                <i className='icon-ticket'>
                    <span className='path1'></span>
                    <span className='path2'></span>
                    <span className='path3'></span>
                    <span className='path4'></span>
                    <span className='path5'></span>
                    <span className='path6'></span>
                    <span className='path7'></span>
                    <span className='path8'></span>
                </i>
            </ListItemIcon>
            <ListItemText primary="Tickets" />
          </ListItem>
          <ListItem  component={NavLink} to="/offer"  className={(route[1] === 'offer') ? "active" : ""}>
            <ListItemIcon className="menu__icon">
              <i className='icon-offer'></i>
            </ListItemIcon>
            <ListItemText primary="Offer Banner" />
          </ListItem>
          <ListItem  component={NavLink} to="/stadium-map"  className={(route[1] === 'stadium-map') ? "active" : ""}>
            <ListItemIcon className="menu__icon">
              <i className='icon-stadium'></i>
            </ListItemIcon>
            <ListItemText primary="Stadium Map" />
          </ListItem>
          <ListItem  component={NavLink} to="/tradablebits"  className={(route[1] === 'tradablebits') ? "active" : ""}>
            <ListItemIcon className="menu__icon">
              <i className='icon-bits'></i>
            </ListItemIcon>
            <ListItemText primary="TradableBits" />
          </ListItem>
          <ListItem  component={NavLink} to="/fan-cam"  className={(route[1] === 'fan-cam') ? "active" : ""}>
            <ListItemIcon className="menu__icon">
              <VideocamOutlinedIcon/>
              {/* <i className='icon-bits'></i> */}
            </ListItemIcon>
            <ListItemText primary="Fan Cam" />
          </ListItem>
          <ListItem  component={NavLink} to="/cue-integration"  className={(route[1] === 'cue-integration') ? "active" : ""}>
            <ListItemIcon className="menu__icon">
              {/* <i className='icon-bits'></i> */}
              <LightModeOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="Cue Integration" />
          </ListItem>
           <ListItem  component={NavLink} to="/player-info"  className={(route[1] === 'player-info') ? "active" : ""}>
            <ListItemIcon className="menu__icon">
              <i className='icon-user-info'></i>
            </ListItemIcon>
            <ListItemText primary="Player Info" />
          </ListItem>

          <ListItem  component={NavLink} to="/gameslider"  className={(route[1] === 'gameslider') ? "active" : ""}>
            <ListItemIcon className="menu__icon">
              {/* <i className='icon-bits'></i> */}
              <ViewCarouselIcon />
            </ListItemIcon>
            <ListItemText primary="Game Slider" />
          </ListItem>

          <ListItem  component={NavLink} to="/promotile"  className={(route[1] === 'promotile') ? "active" : ""}>
            <ListItemIcon className="menu__icon">
              {/* <i className='icon-bits'></i> */}
              <CampaignIcon />
            </ListItemIcon>
            <ListItemText primary="Promo Tile" />
          </ListItem>

          <ListItem  component={NavLink} to="/news"  className={(route[1] === 'news') ? "active" : ""}>
            <ListItemIcon className="menu__icon">
              {/* <i className='icon-bits'></i> */}
              <ArticleIcon />
            </ListItemIcon>
            <ListItemText primary="News" />
          </ListItem>

          <ListItem  component={NavLink} to="/settings"  className={(route[1] === 'settings') ? "active" : ""}>
            <ListItemIcon className="menu__icon">
              <i className='icon-settings'></i>
            </ListItemIcon>
            <ListItemText primary="General Settings" />
          </ListItem>

          {/* <ListItem className='copyRight'>
            <ListItemIcon className="copyRightIcon">
              ©
            </ListItemIcon>
            <ListItemText primary={year} />
          </ListItem> */}
        </List>
      </Scrollbars>
      </Box>
    </div>
  );


  const container = window !== undefined ? () => window().document.body : undefined; 
  return (
    <Box sx={{ display: 'flex',  background: '#FBFCFD' }}>
        <CssBaseline />
        <AppBar
            color="inherit"
            elevation={0}
            position="fixed"
            sx={{
                width: { sm: `calc(100% - ${drawerWidth}px)` },
                ml: { sm: `${drawerWidth}px` },
                background: '#FFFFFF',
            }}
        >
            <Toolbar>
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    edge="start"
                    onClick={handleDrawerToggle}
                    sx={{ mr: 2, display: { sm: 'none' } }}
                >
                    <MenuIcon />
                </IconButton>

                <CardHeader
                    sx={{ padding:"10px" }}
                    title ="Welcome Admin"
                />            
                <Box sx={{ flexGrow: 1 }} />
                <Box sx={{ flexGrow: 0 }}>
                <Stack
                    direction="row"
                    spacing={2}
                    //className="block_top_btn"
                    sx={{ alignItems: "center" }}
                >
                  <Tooltip title="Settings" arrow>
                      <IconButton
                          color="inherit"
                          aria-label="open drawer"
                          //edge="start"
                          onClick={handleOpenUserMenu}
                      // sx={{ mr: 2, display: { sm: 'none' } }}
                      >
                          <i className='icon-settings'></i>
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Logout" arrow>
                      <IconButton
                          color="inherit"
                          aria-label="open drawer"
                          //edge="start"
                          onClick={() => { dispatch(authAction.logout()) }}
                      >
                          <i className='icon-logout'></i>
                      </IconButton>
                    </Tooltip>
                </Stack>
                <Menu
                    sx={{}}
                    id="menu-appbar"
                    anchorEl={anchorElUser}
                    PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        width:"250px",
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                        },
                        '&:before': {
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: 'background.paper',
                        transform: 'translateY(-50%) rotate(45deg)',
                        zIndex: 0,
                        },
                    },
                    }}
                    anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                    }}
                    open={Boolean(anchorElUser)}
                    onClose={handleCloseUserMenu}
                >
                    <MenuItem onClick={handleOpen}
                    sx={{justifyContent:"space-between"}}
                    >
                    Change Password 
                    <i className='icon-password'></i>
                    </MenuItem>
                    
                </Menu>
                </Box>
            </Toolbar>
        </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { background:'#fff', boxShadow:"0px 0px 33.66px 0.34px rgba(45, 45, 46, 0.08)", boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': {background:'#fff', boxShadow:"0px 0px 33.66px 0.34px rgba(45, 45, 46, 0.08)", border:'none', boxSizing: 'border-box', width: drawerWidth },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      {open === true ?   
        <ChangePassword openmodal={open} onCloseModal={() => setOpen(false)} />
      : ''}
    </Box>
  );
}


export default SideBarLayout;
