import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Typography from '@mui/material/Typography'; 
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
//import CardMedia from '@mui/material/CardMedia';
import CardActions from '@mui/material/CardActions';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
//import IconButton from '@mui/material/IconButton';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
//import ImageUploading from "react-images-uploading";
import CircularProgress from '@mui/material/CircularProgress';
//import config from '../../configs';
import { NewsArticleValidation } from '../../assets/validation/validation';
//import FormHelperText from '@mui/material/FormHelperText';
import { newsArticleAction } from '../../redux/actions/newsArticleAction'; 
import { Loader } from "../../errorPages/Loader";
import Divider from '@mui/material/Divider';

export default function NewsArticleEdit() {
    const [articleSatusOne, setArticleSatusOne] = useState(true);
    const [articleSatusTwo, setArticleSatusTwo] = useState(true);
    const [articleSatusThree, setArticleSatusThree] = useState(true);
    const [articleSatusFour, setArticleSatusFour] = useState(true);
    const [data, setData] = React.useState([]);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id } = useParams();
    const maxNumber = 1;

    // const onChange = (imageList, addUpdateIndex) => {
    //   setImages(imageList);
    //   if(imageList.length > 0) {
    //     setImageError("");
    //   }
    // };

    // const onErrorChange = (errors, files) => {
    //     if(errors){
    //         setImages([]);
    //     }
    // };
    
    // const handleStatusChange = (event) => {
    //     setStatuss(event.target.value);
    // };
  

    useEffect(() => { 
        dispatch(newsArticleAction.edit(id)); 
           // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);
    
    const {newsArticleData , newsArticleData_status , loading } = useSelector(state => state.newsarticle);  
     

    useEffect(() => { 
        if(newsArticleData_status === true){
            setData(newsArticleData);
        }
    }, [newsArticleData_status, newsArticleData])


    useEffect(() => { 
        if(data && data!==undefined && data !== ""){
            setArticleSatusOne(data.statusOne);
            setArticleSatusTwo(data.statusTwo);
            setArticleSatusThree(data.statusThree);
            setArticleSatusFour(data.statusFour);
        }
    }, [data])


  return (
    <Box sx={{ mb:3 }} className='cardWrapper'>
         {loading ? <Loader/> : '' }
         <div className="pageTitleWrap">
            <Stack  direction="row" spacing={1}   alignItems="center">
                <Typography type="submit" variant="text" component={Link} to={"/news/view/"+id} > News</Typography>
                <Typography className="" component={"h2"} variant="h2" >
                    / Edit
                </Typography>
            </Stack>
        </div>
        <Formik
            initialValues={{
                articleOne: data && data.articleOne ? data.articleOne : "",
                articleTwo: data && data.articleTwo ? data.articleTwo : "",
                articleThree: data && data.articleThree ? data.articleThree : "",
                articleFour: data && data.articleFour ? data.articleFour : "",
                statusOne: data && data.statusOne ? 'true' : 'false',
                statusTwo: data && data.statusTwo ? 'true' : 'false',
                statusThree: data && data.statusThree ? 'true' : 'false',
                statusFour: data && data.statusFour ? 'true' : 'false',
              }}
            validationSchema={NewsArticleValidation}
            enableReinitialize
            onSubmit={(values, {setErrors, setSubmitting}) => {
                // if(values) {
                //     values.statusOne = articleSatusOne ? 'true' : 'false'
                //     values.statusTwo = articleSatusTwo ? 'true' : 'false'
                //     values.statusThree = articleSatusThree ? 'true' : 'false'
                //     values.statusFour = articleSatusFour ? 'true' : 'false'
                // }
                
                dispatch(newsArticleAction.update(id, values, setErrors, setSubmitting, navigate));
            }}>
            {({ errors, touched, values, handleChange, handleBlur, handleSubmit, handleReset, isSubmitting, setFieldValue }) => (
            <form onSubmit={handleSubmit}> 
                <Card sx={{}} variant="outlined" className="formWrapper">
                    <CardHeader title="Edit"/>
                        <CardContent> 
                            <Grid container spacing={2} alignItems="center" direction="row" justifyContent="center">
                                <Grid item lg={2} xl={2} sx={{pr:2}}>
                                    <Typography className="" component={"h6"} variant="h6" sx={{ fontSize: 15 }}>
                                        Position 1
                                    </Typography>
                                </Grid>

                                <Grid item lg={6} xl={6} sx={{pr:6}}>
                                    <TextField
                                        error={errors.articleOne && touched.articleOne}
                                        label="Article ID"
                                        fullWidth 
                                        name="articleOne"
                                        value={values.articleOne}
                                        onChange={(e) => {
                                           handleChange(e);
                                        }}
                                        onBlur={handleBlur}
                                        helperText={(errors.articleOne && touched.articleOne) && errors.articleOne}
                                        margin="normal"
                                        variant="outlined"
                                        required={false}
                                        InputLabelProps={{ required: true }} 
                                    />
                                </Grid>

                                <Grid item lg={4} xl={4} sx={{pr:4}}>
                                    <FormControl sx={{mt:2, width:"100%", textAlign:"left"}}>
                                        <FormLabel id="demo-row-radio-buttons-group-label">Status <span>*</span></FormLabel>
                                        <RadioGroup
                                            row
                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                            name="statusOne"
                                            value={articleSatusOne ? "true" : "false"}
                                            // checked={articleSatusOne}
                                            onChange={(event) => {
                                                handleChange(event);
                                                setArticleSatusOne(event.target.value === 'true');
                                            }}
                                        >
                                            <FormControlLabel value="true" control={<Radio />} label="Active" />
                                            <FormControlLabel value="false"control={<Radio />} label="Inactive" />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} sx={{mt:3, mb:2}}>
                                <Divider orientation="horizontal" />
                            </Grid>

                            <Grid container spacing={2} alignItems="center" direction="row" justifyContent="center">
                                <Grid item lg={2} xl={2} sx={{pr:2}}>
                                    <Typography className="" component={"h6"} variant="h6" sx={{ fontSize: 15 }}>
                                        Position 2
                                    </Typography>
                                </Grid>

                                <Grid item lg={6} xl={6} sx={{pr:6}}>
                                    <TextField
                                        error={errors.articleTwo && touched.articleTwo}
                                        label="Article ID"
                                        fullWidth 
                                        name="articleTwo"
                                        value={values.articleTwo}
                                        onChange={(e) => {
                                            handleChange(e);
                                            setFieldValue('articleTwo', e.target.value);
                                        }}
                                        onBlur={handleBlur}
                                        helperText={(errors.articleTwo && touched.articleTwo) && errors.articleTwo}
                                        margin="normal"
                                        variant="outlined"
                                        required={false}
                                        InputLabelProps={{ required: true }} 
                                    />
                                </Grid>

                                <Grid item lg={4} xl={4} sx={{pr:4}}>
                                    <FormControl sx={{mt:2, width:"100%", textAlign:"left"}}>
                                        <FormLabel id="demo-row-radio-buttons-group-label">Status <span>*</span></FormLabel>
                                        <RadioGroup
                                            row
                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                            name="statusTwo"
                                            value={articleSatusTwo ? "true" : "false"}
                                            //checked={articleSatusTwo ? "true" : "false"}
                                            onChange={(event) => {
                                                handleChange(event);
                                                setArticleSatusTwo(event.target.value === 'true');
                                            }}
                                        >
                                            <FormControlLabel value={true} control={<Radio />} label="Active" />
                                            <FormControlLabel value={false} control={<Radio />} label="Inactive" />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} sx={{mt:3, mb:2}}>
                                <Divider orientation="horizontal" />
                            </Grid>

                            <Grid container spacing={2} alignItems="center" direction="row" justifyContent="center">
                                <Grid item lg={2} xl={2} sx={{pr:2}}>
                                    <Typography className="" component={"h6"} variant="h6" sx={{ fontSize: 15 }}>
                                        Position 3
                                    </Typography>
                                </Grid>

                                <Grid item lg={6} xl={6} sx={{pr:6}}>
                                    <TextField
                                        error={errors.articleThree && touched.articleThree}
                                        label="Article ID"
                                        fullWidth 
                                        name="articleThree"
                                        value={values.articleThree}
                                        onChange={(e) => {
                                            handleChange(e);
                                            setFieldValue('articleThree', e.target.value);
                                        }}
                                        onBlur={handleBlur}
                                        helperText={(errors.articleThree && touched.articleThree) && errors.articleThree}
                                        margin="normal"
                                        variant="outlined"
                                        required={false}
                                        InputLabelProps={{ required: true }} 
                                    />
                                </Grid>

                                <Grid item lg={4} xl={4} sx={{pr:4}}>
                                    <FormControl sx={{mt:2, width:"100%", textAlign:"left"}}>
                                        <FormLabel id="demo-row-radio-buttons-group-label">Status <span>*</span></FormLabel>
                                        <RadioGroup
                                            row
                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                            name="statusThree"
                                            value={articleSatusThree ? "true" : "false"}
                                            onChange={(event) => {
                                                handleChange(event);
                                                setArticleSatusThree(event.target.value === 'true');
                                            }}
                                        >
                                            <FormControlLabel value={true} control={<Radio />} label="Active" />
                                            <FormControlLabel value={false} control={<Radio />} label="Inactive" />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} sx={{mt:3, mb:2}}>
                                <Divider orientation="horizontal" />
                            </Grid>

                            <Grid container spacing={2} alignItems="center" direction="row" justifyContent="center">
                                <Grid item lg={2} xl={2} sx={{pr:2}}>
                                    <Typography className="" component={"h6"} variant="h6" sx={{ fontSize: 15 }}>
                                        Position 4
                                    </Typography>
                                </Grid>

                                <Grid item lg={6} xl={6} sx={{pr:6}}>
                                    <TextField
                                        error={errors.articleFour && touched.articleFour}
                                        label="Article ID"
                                        fullWidth 
                                        name="articleFour"
                                        value={values.articleFour}
                                        onChange={(e) => {
                                            handleChange(e);
                                            setFieldValue('articleFour', e.target.value);
                                        }}
                                        onBlur={handleBlur}
                                        helperText={(errors.articleFour && touched.articleFour) && errors.articleFour}
                                        margin="normal"
                                        variant="outlined"
                                        required={false}
                                        InputLabelProps={{ required: true }} 
                                    />
                                </Grid>

                                <Grid item lg={4} xl={4} sx={{pr:4}}>
                                    <FormControl sx={{mt:2, width:"100%", textAlign:"left"}}>
                                        <FormLabel id="demo-row-radio-buttons-group-label">Status <span>*</span></FormLabel>
                                        <RadioGroup
                                            row
                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                            name="statusFour"
                                            value={articleSatusFour ? "true" : "false"}
                                            onChange={(event) => {
                                                handleChange(event);
                                                setArticleSatusFour(event.target.value === 'true');
                                            }}
                                        >
                                            <FormControlLabel value={true} control={<Radio />} label="Active" />
                                            <FormControlLabel value={false} control={<Radio />} label="Inactive" />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                            </Grid>

                        </CardContent>

                        <CardActions sx={{justifyContent:"center"}}>
                        <Button type="submit" variant="contained" color="primary"
                          disabled ={isSubmitting} 
                        > {isSubmitting ? <CircularProgress size={20}/> :  "Update"}</Button> 
                        <Button type="button" variant="contained" color="secondary"
                        onClick={(e) => {    
                            handleReset();
                            navigate("/news/view/"+id);
                        }}
                        >Cancel</Button>
                    </CardActions>

                </Card>  
            </form>
            )}
        </Formik> 
    </Box>
  )
}
