import React, {useState, useEffect} from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from 'react-redux';
import { useTable, useSortBy, usePagination, useAsyncDebounce, useGlobalFilter, useFilters } from "react-table";
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Tooltip from '@mui/material/Tooltip';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import InputAdornment from '@mui/material/InputAdornment';
import {Link } from "react-router-dom";
import DeleteDialog from "./delete";
import { mapAction } from "../../redux/actions/mapAction";
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import Select from "react-select"
import { Loader } from "../../errorPages/Loader";

const Styles = styled.div `
  padding: 0rem;
  table {
    margin: auto;
    table-layout: fixed;
    width: 100%;
    border-spacing: 0;
    border-collapse: collapse;
    transition: background-color 0.5s ease;
    thead {
      tr {
        :first-child {
          font-size: 14px;
          line-height: 19px;
          color: var(--1on1-dark);
          th {
            :first-child {
              text-align: center;
            }
            :last-child {
              text-align: left;
            }
          }
        }
      }
    }
    th,
    td {
      margin: 10px;
      padding: 0.5rem;
      height: 30px;
      text-align: left;
      width: 20%;
      :first-child {
        width: 10%;
        vertical-align:middle;
        i {
          font-size: 25px;
          margin-top:20px
        }
      }
      :nth-child(2) {
        width: 40%;
      }
      :nth-last-child(2) {
        width: 25%;
      }
      :last-child {
        width: 20%;
        text-align: left;
      }
    }
  }
`;

const Tr = styled.tr`
  background-color: white;
  display: ${({ isDragging }) => (isDragging ? "table" : "")};
`;

const dataStatus = [
    { value: 'All', label: 'All' },
    { value: true, label: 'Active' },
    { value: false, label: 'Inactive' },
  
  ]
const styles = {

  option: (styles, state) => ({
      ...styles,
      cursor: 'pointer',
      textAlign:"left"
  }),
  control: (styles) => ({
      ...styles,
      cursor: 'pointer',
      'borderRadius': '5px',
      //'boxShadow': '0px 9px 24px 0px rgba(0, 0, 0, 0.04)',
      border: '1px solid rgba(0, 0, 0, 0.23)',
      'width' : '100%',
      'maxWidth': '250px',
      'minWidth': '250px',
      'height': '40px',
      'fontSize': '16px',
       textAlign:"center"
  }),

};
function Table({
  columns,
  data,
  updateMyData,
  reorderData,
  loading
}) {

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        //rows,
        prepareRow,
        page, // Instead of using 'rows', we'll use page,
        // which has only the rows for the active page
    
        // The rest of these things are super handy, too ;)
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        setFilter,
        setGlobalFilter,
        globalFilter,
        state: { pageIndex, pageSize },
      } = useTable(
        {
          columns,
          data,
          initialState: { pageIndex: 0 },
          updateMyData,
          reorderData,
        },
        useGlobalFilter,
        useFilters,
        useAsyncDebounce,
        useSortBy,
        usePagination,
      )
    const [value, setValue] = React.useState(globalFilter);
    const [status, setStatus] = useState({ value: 'All', label: 'All' });
    const dispatch = useDispatch();
    const handleSearch = useAsyncDebounce(value => {
      setGlobalFilter(value || undefined)
    }, 200)
    

  const handleDragEnd = result => {
    const { source, destination } = result;
    if (!destination) return;
    reorderData(source.index, destination.index);
    setTimeout(function () {
      dispatch(mapAction.changeOrder(result.draggableId, (result.destination.index+1))); 
    }, 500);
    setStatus({ value: 'All', label: 'All' })
  };

  return (
    <>
    <div className="searchWrapper">
        <Stack direction="row" spacing={2} justifyContent="flex-end"  alignItems="center">
        <Select
          classNamePrefix="select2" 
          className="customselect"
          options={dataStatus}
          value={status}
          components={{
              IndicatorSeparator: () => null,
          }}
          styles={styles}

          onChange={(e) => {
              if (e.value === "All") {
                  setStatus({ value: e.value, label: e.label })
                  setFilter("status");
              }
              else {
                  setStatus({ value: e.value, label: e.label })
                  setFilter("status", e.label);
              }
          }
          } 
      />
        <TextField
             label="Search"
             variant="outlined" 
             size="small"
             value={value}
             onChange={e => {
                setValue(e.target.value);
                handleSearch(e.target.value);
              }}
             InputProps={{
                endAdornment:
                    <InputAdornment position="end">
                        <IconButton
                        aria-label="toggle password visibility"
                        disabled
                        //onClick={handleClickShowPassword}
                        edge="end"
                        >
                        {<i className='icon-search'></i>}
                        </IconButton>
                    </InputAdornment>
            }}
        />
          <Tooltip title="Add Stadium" arrow>
            <IconButton
                sx={{m:"2px"}}
                variant="contained"
                color="primary" 
                component={Link}
                to={"/stadium-map/add"}
                //</Stack>onClick={() => gotoPage(0)} disabled={!canPreviousPage}
                >
                <i className="icon-add"></i>
            </IconButton>
          </Tooltip>
        </Stack>
    </div>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                {column.render('Header')}
                {/* Add a sort direction indicator */}
                <span className="sortArrow">
                  {column.isSorted
                    ? column.isSortedDesc
                      ? <span className="down"><i className="icon-drop_down"></i></span>
                      : <span className="up"><i className="icon-drop_down"></i></span>
                    : ''}
                </span>
              </th>
              ))}
            </tr>
          ))}
        </thead>
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="table-body">
            {(provided, snapshot) => (
              <tbody ref={provided.innerRef} {...provided.droppableProps}  {...getTableBodyProps()}>
              {page.length > 0 ?
                page.map((row, i) => {
                  prepareRow(row);
                  return (
                    <Draggable
                      draggableId={row.original._id}
                      key={row.original._id}
                      index={row.index}
                    >
                      {(provided, snapshot) => {
                        return (
                          <Tr
                            {...row.getRowProps()}
                            {...provided.draggableProps}
                            // {...provided.dragHandleProps}
                            ref={provided.innerRef}
                            isDragging={snapshot.isDragging}
                          >
                            {row.cells.map(cell => (
                              <td {...cell.getCellProps()}>
                                {cell.render("Cell", {
                                  dragHandleProps: provided.dragHandleProps,
                                  isSomethingDragging: snapshot.draggingOver
                                })}
                              </td>
                            ))}
                          </Tr>
                        );
                      }}
                    </Draggable>
                  );
                })
                :
                <tr>
                  <td
                    style={{padding:"20px", backgroundColor: "#e7e7e7", textAlign:"center" }}
                    colSpan={columns.length}
                  >
                   {loading ? <Loader/> : 
                    "No Records Found"
                    }
                  </td>
                </tr>
                }
                {provided.placeholder}

              </tbody>
            )}
          </Droppable>
        </DragDropContext>
      </table>
      <div className="pagination">
      <Stack direction="row" spacing={2}  justifyContent="space-between" >
      <Stack direction="row" spacing={2}  alignItems="center">
       <Box sx={{display:"flex", alignItems:"center", gap:"10px"}}>
        <span>
          Go to page: </span>

          <TextField type="number"  
            defaultValue={pageIndex + 1} 
            size="small"
            sx={{maxWidth:"80px", width:"100%"}}
            onChange={e => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0
                gotoPage(page)
            }}
            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', min:"1" }} />
        </Box>
        <Box sx={{display:"flex", alignItems:"center", gap:"10px"}}>
        <span>
          Show </span>
            <TextField
              sx={{maxWidth:"80px", width:"100%"}}
              id={"dropdownInput"}
              defaultValue={pageSize}
              size="small"
              select
              onChange={e => {
                setPageSize(Number(e.target.value))
              }}
            >
              {[10, 20, 30, 40, 50].map(pageSize => (
                <MenuItem key={pageSize} value={pageSize}>{pageSize}</MenuItem>
                ))}
            </TextField>
        </Box>  
        </Stack>
        <Stack direction="row" spacing={2}  alignItems="center">
        <Typography  variant="body1" component="p">
            <span> Page 
                <strong> {pageIndex + 1} of {pageOptions.length}
                </strong>
            </span>
        </Typography>
        <div>
        <IconButton
            sx={{m:"2px"}}
            variant="contained"
            color="secondary" 
            onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
            <KeyboardDoubleArrowLeftIcon/>
        </IconButton>
        <IconButton 
           sx={{m:"2px"}}
            variant="contained"
            color="secondary" 
            onClick={() => previousPage()} disabled={!canPreviousPage}>
           <KeyboardArrowLeftIcon/>
        </IconButton>
        <IconButton 
           sx={{m:"2px"}}
            variant="contained"
            color="secondary" 
            onClick={() => nextPage()} disabled={!canNextPage}>
          <KeyboardArrowRightIcon/>
        </IconButton>
        <IconButton
            sx={{m:"2px"}}
            variant="contained"
            color="secondary" 
            onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
            <KeyboardDoubleArrowRightIcon/>
        </IconButton>
        </div>
      </Stack>
       
        </Stack>
      </div>
    </>
  );
}

const UpDownArrow = props => (
  <span
    {...props.dragHandleProps}
    className={props.className}
    aria-label="move"
    role="img"
  >
    <DragIndicatorIcon sx={{  lineHeight:"normal", verticalAlign:"middle"}}/>
    {/* ↕️ */}
  </span>
);
const StyledUpDownArrow = styled(UpDownArrow)`
  padding: 0px;
  display: block;
  line-height: normal;
`;

const Description = styled.span`
  display: flex;
  align-items: center;
  position: relative;
  justify-content: center;
  line-height: normal;
`;


function MapList(props) {
const [data, setData] = React.useState([]);
const [deleteOpen,  setDeleteOpen ] = React.useState(false);
const dispatch = useDispatch();
const [stadiumId, setStadiumId] = React.useState(null);


  useEffect(() => { 
    dispatch(mapAction.getAll()); 
}, [dispatch]);


const {maps_data , maps_status , loading } = useSelector(state => state.maps);   

  useEffect(() => { 
    if(maps_status === true){
      setData(maps_data);
  }

}, [maps_status, maps_data])

  const columns = React.useMemo(() => {
    const DescriptionCell = props => {
      return (
        <Description>
          <StyledUpDownArrow {...props} />
        </Description>
      );
    };    
    
    return [
    {
        Header: "",
        accessor: "_id",
        Cell: DescriptionCell, 
        disableSortBy: true,

    },   
    {
        Header: "Stadium Name",
        accessor: "stadiumName",
        Cell: ({value}) => {
          return (
            <Typography className="" noWrap component={"p"} variant="body1" >{value}</Typography>

          )
        }
    },
    // {
    //     Header: "Banner Text",
    //     accessor: "buttonText",
    // },
    {
      Header: "Status",
      filter: 'includes',
      disableSortBy: true, 
      id: "status",
      accessor: status => status.status === true ? "Active" : "Inactive"
  },
    {
        Header: "Actions",
        disableSortBy: true,
        width: 100,
        id:"action",
        accessor: params => {
          return (
            <Stack direction="row" spacing={2}  justifyContent="flex-left" className="block_top_btn" >
            <Tooltip title="View Stadium" arrow>
              <IconButton variant="contained"
                  color="secondary"
                  component={Link}
                  to={"/stadium-map/view/"+params._id}
                  className='btn editBtn' size="small">
                <i className='icon-view'></i>
              </IconButton>
              </Tooltip>
              <Tooltip title="Edit Stadium" arrow>
              <IconButton variant="contained"
                  color="secondary"
                  component={Link}
                  to={"/stadium-map/edit/"+params._id}
                  className='btn editBtn' size="small">
                <i className='icon-edit'></i> 
              </IconButton>
              </Tooltip>
              <Tooltip title="Delete Stadium" arrow>
              <IconButton variant="contained"
                  color="secondary"
                  //sx={{minWidth:"auto", padding:"10px"}}
                  onClick={() => { 
                      setDeleteOpen(true);
                      setStadiumId(params._id);
                  }}
                  className='btn editBtn' size="small">
                <i className='icon-delete'></i>
              </IconButton>
              </Tooltip>
            </Stack >
          )
        }
      }

    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

    const updateMyData = (rowIndex, columnID, newValue) => {
        setData(oldData =>
        oldData.map((row, index) => {
            if (index === rowIndex) {
            return {
                ...oldData[rowIndex],
                [columnID]: newValue
            };
            }
            return row;
        })
        );
    };
  const reorderData = (startIndex, endIndex) => {
    const newData = [...data];
    const [movedRow] = newData.splice(startIndex, 1);
    newData.splice(endIndex, 0, movedRow);
    setData(newData);
  };

  return (
    <div className ="dataTable__wrap">
        <div className="pageTitleWrap">
          <Typography className="" component={"h2"} variant="h2" >Stadium Map List</Typography>
        </div>
        <Styles>
        {loading ? <Loader/> : 
          <Table
              columns={columns}
              data={data}
              updateMyData={updateMyData}
              reorderData={reorderData}
              loading={loading}
          />
          }
        </Styles>
        {deleteOpen ?
        <DeleteDialog open={deleteOpen} stadium_id={stadiumId}  close={() => setDeleteOpen(false)} />
      :''}
    </div>
  );
}


export default MapList;


