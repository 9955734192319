import { SETTINGS } from '../configs/ApiActionUrl';
import { httpService } from './httpService';
export const settingService = {
    getAll,
    update,
    changePassword,
}

function getAll(){   
    return httpService.get(SETTINGS.GET+"/getSettingsData")
        .then(res => {
            if (res.data) {
                return res.data;
            }
            return res;
        });
}
function update(data){
    return httpService.put(SETTINGS.UPDATE+"/updateSettings", data)    
    .then(res => {
        if (res.data) {
            return res.data;
        }
        return res;
    });
}


function changePassword(data){
    return httpService.post(SETTINGS.CHANGE_PASSWORD+"/changePassword", data)    
    .then(res => {
        if (res.data) {
            return res.data;
        }
        return res;
    });
}
