import './App.css';
import './assets/css/icon_style.css';
import './assets/css/custom.css' 
import AppRoutes from './Router';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; 
import { createTheme, ThemeProvider } from '@mui/material/styles';


function App(props) { 
  
  //let path = window.location.pathname;
	//var route = path.split("/");
  const theme = createTheme({
    typography: {
      fontFamily: 'Univers LT Std',
    },
    palette: {
      primary: {
        main: '#001f5b',
      },
      secondary: {
        main: '#c6093b',
      },
      teritary: {
        main: '#dcdce0',
      },
    },
    components: {
      // Name of the component
      MuiButton: {
        styleOverrides: {
          // Name of the slot
          root: {
            // Some CSS
            fontSize: '18px',
            boxShadow:'none',
            fontWeight:"bold",
            color: "#fff",
            borderRadius:"5px",
            padding:"3.25px 20px 3.25px",
            minWidth:"150px",
            textTransform:"none"
          },
          containedPrimary: {
              background: "#c6093b",
              border:"1px solid #c6093b",
              "&:hover" : {
                backgroundColor: "#fff",
                border:"1px solid #c6093b",
                color: "#c6093b",
                boxShadow:"none"
              }
          },
          containedSecondary: {
            background: "#dcdce0",
            border:"1px solid #dcdce0",
            color:"#000000",
            "&:hover" : {
              backgroundColor: "#fff",
              border:"1px solid #dcdce0",
              color: "#000000",
              boxShadow:"none"
            }
          }
   
        },
      },
      MuiIconButton: {
        styleOverrides: {
          // Name of the slot
          root: {
            // Some CSS
            fontSize: '18px',
            boxShadow:'none',
            borderRadius:"5px",
            padding:"8px",
            textTransform:"none",
          },
          colorPrimary: {
            padding:"0px",
            fontSize: '22px',
            width:"50px",
            height:"40px",
            background: "#c6093b",
            border:"1px solid #c6093b",
            color:"#ffffff",
            "&:hover" : {
              backgroundColor: "#fff",
              border:"1px solid #c6093b",
              color: "#c6093b",
              boxShadow:"none"
            }
          },
          colorSecondary: {
            padding:"0px",
            width:"32px",
            height:"32px",
            background: "#dcdce0",
            border:"1px solid rgba(20,20,20,0.2)",
            color:"#000000",
            "&:hover" : {
              backgroundColor: "#fff",
              border:"1px solid #dcdce0",
              color: "#000000",
              boxShadow:"none"
            }
          }
        },
      },
    }   
  });
  return (
    
    <ThemeProvider theme={theme}>
      <ToastContainer
        position="top-right"
        autoClose={2500}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    <div className="App">
      <AppRoutes />  
    </div>
    </ThemeProvider>
  );
}

export default App;
