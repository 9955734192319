import { mapTypes } from '../types/mapTypes'
const initialState = {loading:false, maps_data:[], maps_status:false, mapData:[], mapData_status:false};
export const maps = (state = initialState , action) => {
    switch(action.type){
        case mapTypes.GETALL_MAP_REQUEST:
            return {
                loading: true
            };
        case mapTypes.GETALL_MAP_SUCCESS :
            return {
                ...state,
                loading: false,
                maps_status:true,
                maps_data:action.data.StadiumMaps 
            }
            
        case mapTypes.GETALL_MAP_ERROR :
            return {
                loading: false,
                error : action.error
            }
            case mapTypes.ADD_MAP_REQUEST :
                return {
                    loading: true,
                    data : action.data
                };
            case mapTypes.ADD_MAP_SUCCESS :
                return  {
                    loading: false,
                    item:action.data
                }
            case mapTypes.ADD_MAP_ERROR :
                return {
                    loading: false,
                    error : action.error, 
                }    
            case mapTypes.DELETE_MAP_REQUEST :
                return state
            case mapTypes.DELETE_MAP_SUCCESS :
                return  { 
                    loading: false,
                    item:action.data
                    //items:state.items.filter(item => item.id !== action.id)
                }
            case mapTypes.DELETE_MAP_ERROR :
                return {}
            case mapTypes.EDIT_MAP_REQUEST :
                return {
                    loading: true,
                };
            case mapTypes.EDIT_MAP_SUCCESS :
                return  {
                    ...state,
                    loading: false,
                    mapData_status: true, 
                    mapData:action.data,
                }
            case mapTypes.EDIT_MAP_ERROR :
                return {
                    loading: false,
                    error : action.error
                }
    
            case mapTypes.UPDATE_MAP_REQUEST :
                return {
                    loading: true,
                    data : action.data
                };
            case mapTypes.UPDATE_MAP_SUCCESS :
                return  {
                    loading: false,
                    item:action.data
                }
            case mapTypes.UPDATE_MAP_ERROR :
                return {
                    loading: false,
                    error : action.error, 
                }
            case mapTypes.CHANGE_ORDER_MAP_REQUEST :
                return {
                    loading: true,
                    data : action.data
                };
            case mapTypes.CHANGE_ORDER_MAP_SUCCESS :
                return  {
                    loading: false,
                    item:action.data
                }
            case mapTypes.CHANGE_ORDER_MAP_ERROR :
                return {
                    loading: false,
                    error : action.error, 
                }

            default :
                return state
    }
}