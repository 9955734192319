import React from 'react';
import { useDispatch } from 'react-redux';
import { Formik } from 'formik';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import { ChangePasswordValidation } from '../../assets/validation/validation';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
//import { Loader } from '../../errorPages/Loader';
import { settingAction } from '../../redux/actions/settingAction';
import CircularProgress from '@mui/material/CircularProgress';


/*Modal Style */
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: 600,
  width:'100%', 
  bgcolor: 'background.paper',
  border: 'none',
  boxShadow: 24,
  borderRadius:"6px",
  p: 0,
};

export default function ChangePassword(props) {
    const dispatch = useDispatch();

  return (
    <div>
        <Modal
            className="cmn__form__modal changePassword_modal"
            keepMounted 
            open={props.openmodal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Typography id="modal-modal-title" variant="h4" component="h2">
                    Change Password
                </Typography>
                <Formik
                    initialValues={{
                        oldpassword : '',
                        password: '',
                        confirmPassword:''
                    }}
                    enableReinitialize
                    validationSchema={ChangePasswordValidation}
                    onSubmit={(values, {setErrors, setSubmitting}) => { 
                        // var form_data = new FormData();
                        // form_data.append('oldpassword', values.oldpassword);
                        // form_data.append('password', values.password);
                        // form_data.append('confirmPassword', values.confirmPassword);
                        dispatch(settingAction.changePassword(values, setErrors, props.onCloseModal));
                    }}>
                    {({ errors, touched, values, handleChange, handleBlur, handleSubmit, handleReset, isSubmitting }) => (
                        <form onSubmit={handleSubmit}> 
                            <div className="modalform">
                                <Box sx={{}}>
                                    <Box sx={{
                                        m: 0,
                                        background:'#fff',
                                        padding:'0',
                                        borderRadius: "0 0 20px 20px"
                                    }}>
                                        <Paper elevation={0}>
                                            <TextField
                                                id="filled-oldpassword-input"
                                                fullWidth
                                                label="Current Password"
                                                name="oldpassword"
                                                type="password"
                                                autoComplete="oldpassword"
                                                margin="normal"
                                                error={errors.oldpassword && touched.oldpassword}
                                                value={values.oldpassword}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                helperText={(errors.oldpassword && touched.oldpassword) && errors.oldpassword}
                                                required={false}
                                                InputLabelProps={{ required: true }} 
                                                InputProps={{
                                                    endAdornment:
                                                        <InputAdornment position="end" sx={{ml:0}}>
                                                            <IconButton
                                                            aria-label="toggle password visibility"
                                                            edge="end"
                                                            sx={{color:"inherit !important"}}
                                                            disabled
                                                            >
                                                            {<i className='icon-password'></i>}
                                                            </IconButton>
                                                        </InputAdornment>
                                                }}

                                            />
                                            <TextField
                                                id="filled-password-input"
                                                fullWidth
                                                label="New password"
                                                name="password"
                                                type="password"
                                                autoComplete="password"
                                                margin="normal"
                                                error={errors.password && touched.password}
                                                value={values.password}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                helperText={(errors.password && touched.password) && errors.password}
                                                required={false}
                                                InputLabelProps={{ required: true }} 
                                                InputProps={{
                                                    endAdornment:
                                                        <InputAdornment position="end"  sx={{ml:0}}>
                                                            <IconButton
                                                            aria-label="toggle password visibility"
                                                            edge="end"
                                                            sx={{color:"inherit !important"}}
                                                            disabled
                                                            >
                                                            {<i className='icon-password'></i>}
                                                            </IconButton>
                                                        </InputAdornment>
                                                }}

                                            />
                                            <TextField
                                                id="filled-confirmPassword-input"
                                                fullWidth
                                                label="Confirm Password"
                                                name="confirmPassword"
                                                type="password"
                                                autoComplete="confirmPassword"
                                                margin="dense"
                                                error={errors.confirmPassword && touched.confirmPassword}
                                                value={values.confirmPassword}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                helperText={(errors.confirmPassword && touched.confirmPassword) && errors.confirmPassword}
                                                required={false}
                                                InputLabelProps={{ required: true }} 
                                                InputProps={{
                                                    endAdornment:
                                                        <InputAdornment position="end"  sx={{ml:0}}>
                                                            <IconButton
                                                            aria-label="toggle password visibility"
                                                            edge="end"
                                                            sx={{color:"inherit !important"}}
                                                            disabled
                                                            >
                                                            {<i className='icon-password'></i>}
                                                            </IconButton>
                                                        </InputAdornment>
                                                }}

                                            />
                                            <Box
                                                className='button_group' 
                                                sx={{
                                                display: 'flex',
                                                columnGap: '10px',
                                            }}>
                                                <Button 
                                                    type="submit"
                                                    className='save__btn' 
                                                    variant="contained" 
                                                    size="large"
                                                    disabled={isSubmitting}
                                                    >
                                                   {isSubmitting ? <CircularProgress size={20}/> :  "Save"}
                                                </Button>
                                                <Button 
                                                    type="button" 
                                                    className='cancel__btn' 
                                                    variant="contained" 
                                                    color="secondary"
                                                    size="large"
                                                    onClick={(e) => {    
                                                        props.onCloseModal();
                                                        handleReset();
                                                    }}
                                                >
                                                    Cancel
                                                </Button>
                                            </Box>
                                        </Paper>
                                    </Box>
                                </Box>
                            </div>
                        </form>
                    )}
                </Formik>  
            </Box>
        </Modal>
    </div>
  );
}
