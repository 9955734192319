import { shopTypes } from '../types/shopTypes';
import { shopService } from '../../services/shopService';
import { toast } from 'react-toastify';

export const shopAction = {
    getAll,
    add,
    edit,
    update,
    deleteProduct,
    changeOrder
}

function getAll() {
    return dispatch => {
        dispatch({type:shopTypes.GETALL_SHOP_REQUEST}); 
        shopService.getAll().then(res => {
            if (res.status) {
                dispatch({type:shopTypes.GETALL_SHOP_SUCCESS, data: res.data });
                //toast.success(res.message);
            } else {
                dispatch({type:shopTypes.GETALL_SHOP_ERROR}); 
                toast.error(res.message);
            }
        })

    }
}
function add(data, setErrors, setSubmitting, navigate){
    return dispatch => {
        dispatch({type:shopTypes.ADD_SHOP_REQUEST});
        shopService.add(data).then(res => {
            if(res.status){
               dispatch({type:shopTypes.ADD_SHOP_SUCCESS, data});
               toast.success(res.message);
               setSubmitting(false);
               navigate(-1);
            }else{
                dispatch({type:shopTypes.ADD_SHOP_ERROR, error:res.error});
                toast.error(res.message);
                setSubmitting(false);
                if(res.error) {
                    setErrors(res.error);
                }
            }
        })
    }
}

function deleteProduct(code, closeModal){ 
    return dispatch => {
        dispatch({type:shopTypes.DELETE_SHOP_REQUEST});
        shopService.deleteProduct(code).then(res => { 
            if(res.status){
                dispatch({type:shopTypes.DELETE_SHOP_SUCCESS, code});
                toast.success(res.message);
                closeModal();
                setTimeout(function () {
                    dispatch(shopAction.getAll());
                }, 500);
            }else{
                dispatch({type:shopTypes.DELETE_SHOP_ERROR, error:res.error});
                toast.error(res.message);
            }
        })
    }
}

function update(code, data, setErrors, setSubmitting, navigate){    
    return dispatch => {
        dispatch({type:shopTypes.UPDATE_SHOP_REQUEST});
        shopService.update(code,  data).then(res => {
            if(res.status){
                dispatch({type:shopTypes.UPDATE_SHOP_SUCCESS, data:res.data});
                toast.success(res.message);
                setSubmitting(false);
                navigate(-1);
            }else{ 
                dispatch({type:shopTypes.UPDATE_SHOP_ERROR, error:res.error});
                toast.error(res.message);
                setSubmitting(false);
                if(res.error) {
                    setErrors(res.error);
                    setSubmitting(false);
                }
            }
        })
    }
}

function edit(id){
    return dispatch => {
        dispatch({type:shopTypes.EDIT_SHOP_REQUEST});
        shopService.edit(id).then(res => {
            if(res.status){
                dispatch({type:shopTypes.EDIT_SHOP_SUCCESS, data:res.data})
            }else{
                dispatch({type:shopTypes.EDIT_SHOP_ERROR, error:res.error});
                toast.error(res.message);
            }
        })
    }
}

function changeOrder(id, newRankOrder){
    return dispatch => {
        dispatch({type:shopTypes.CHANGE_ORDER_SHOP_REQUEST});
        shopService.changeOrder(id, newRankOrder).then(res => {
            if(res.status){
                dispatch({type:shopTypes.CHANGE_ORDER_SHOP_SUCCESS});
                toast.success(res.message);
            }else{
                dispatch({type:shopTypes.CHANGE_ORDER_SHOP_ERROR, error:res.error});
                toast.error(res.message);
            }
        })
    }
}
