import { tradableBitsTypes } from '../types/tradableBitsTypes';
import { tradableBitsService } from '../../services/tradableBitsService'; 
import { toast } from 'react-toastify';

export const tradableBitsAction = { 
    getAll,
    add,
    edit,
    update,
}

function getAll() {
    return dispatch => {
        dispatch({type:tradableBitsTypes.GETALL_TRADABLEBITS_REQUEST}); 
        tradableBitsService.getAll().then(res => {
            if (res.status) {
                dispatch({type:tradableBitsTypes.GETALL_TRADABLEBITS_SUCCESS, data: res.data });
                //toast.success(res.message);
            } else {
                dispatch({type:tradableBitsTypes.GETALL_TRADABLEBITS_ERROR}); 
                toast.error(res.message);
            }
        })

    }
}
function add(data, setErrors, setSubmitting, navigate){
    return dispatch => {
        dispatch({type:tradableBitsTypes.ADD_TRADABLEBITS_REQUEST});
        tradableBitsService.add(data).then(res => {
            if(res.status){
               dispatch({type:tradableBitsTypes.ADD_TRADABLEBITS_SUCCESS, data});
               toast.success(res.message);
               setSubmitting(false);
               setTimeout(function () {
                    dispatch(tradableBitsAction.getAll());
                }, 500);
               //navigate(-1);
            }else{
                dispatch({type:tradableBitsTypes.ADD_TRADABLEBITS_ERROR, error:res.error});
                toast.error(res.message);
                setSubmitting(false);
                if(res.error) {
                    setErrors(res.error);
                }
            }
        })
    }
}


function update(code, data, setErrors, setSubmitting, navigate){    
    return dispatch => {
        dispatch({type:tradableBitsTypes.UPDATE_TRADABLEBITS_REQUEST});
        tradableBitsService.update(code,  data).then(res => {
            if(res.status){
                dispatch({type:tradableBitsTypes.UPDATE_TRADABLEBITS_SUCCESS, data:res.data});
                toast.success(res.message);
                setSubmitting(false);
                navigate("/tradablebits/view/"+code);
            }else{ 
                dispatch({type:tradableBitsTypes.UPDATE_TRADABLEBITS_ERROR, error:res.error});
                toast.error(res.message);
                setSubmitting(false);
                if(res.error) {
                    setErrors(res.error);
                    setSubmitting(false);
                }
            }
        })
    }
}

function edit(id){
    return dispatch => {
        dispatch({type:tradableBitsTypes.EDIT_TRADABLEBITS_REQUEST});
        tradableBitsService.edit(id).then(res => {
            if(res.status){
                dispatch({type:tradableBitsTypes.EDIT_TRADABLEBITS_SUCCESS, data:res.data})
            }else{
                dispatch({type:tradableBitsTypes.EDIT_TRADABLEBITS_ERROR, error:res.error});
                toast.error(res.message);
            }
        })
    }
}
