import { AUTH } from '../configs/ApiActionUrl';
import { httpService } from './httpService';
import { history } from '../helpers/history.js'; 

export const authService = {
    login,
    forgotPassword,
    resetPassword,
    logout,
    getResetPassword
}

function login(postData){    
    return httpService.post(AUTH.LOGIN, postData)
      .then(res => {
        const accessToken = res?.data?.data?.accessToken;
        const refreshToken = res?.data?.data?.refreshToken;

        if (accessToken && refreshToken) {
            localStorage.setItem('accessToken', accessToken);
            localStorage.setItem('refreshToken', refreshToken);
        }
        if (res.data) {
            return res.data;
        }
        // if(res.data && res.data.data){
        //     // store user details and jwt token in local storage to keep user logged in between page refreshes
        //     localStorage.setItem('accessToken', res.data.data.accessToken);
        //     localStorage.setItem('refreshToken', res.data.data.refreshToken);
        // }
        return res;
    }).catch(function (error) {
        console.log(error);
    });
}
function forgotPassword(postData){
    return httpService.post(AUTH.FORGOT_PASSWORD, postData)
      .then(res => {
        if (res.data) {
            return res.data;
        }
        return res;
    }).catch(function (error) {
        console.log(error);
    });
}

function resetPassword(code, postData){
    return httpService.post(AUTH.RESET_PASSWORD+"/"+code, postData)
      .then(res => {
        if (res.data) {
            return res.data;
        }
        return res;
    }).catch(function (error) {
        console.log(error);
    });
}


function getResetPassword(code){
    return httpService.get(AUTH.GET_RESET_PASSWORD+"/"+code)
      .then(res => {
        if (res.data) {
            return res.data;
        }
        return res;
    }).catch(function (error) {
        console.log(error);
    });
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('userData');
    localStorage.removeItem('modules');
    localStorage.removeItem('roles');
    history.push('/');
    setTimeout(function () {
        window.location.reload(false);
    }, 500);
}
