export const mapTypes = {
    GETALL_MAP_REQUEST  : 'GETALL_MAP_REQUEST',
    GETALL_MAP_SUCCESS  : 'GETALL_MAP_SUCCESS',
    GETALL_MAP_ERROR  : 'GETALL_MAP_ERROR',

    ADD_MAP_REQUEST : 'ADD_MAP_REQUEST',
    ADD_MAP_SUCCESS : 'ADD_MAP_SUCCESS',
    ADD_MAP_ERROR  : 'ADD_MAP_ERROR',

    EDIT_MAP_REQUEST : 'EDIT_MAP_REQUEST',
    EDIT_MAP_SUCCESS : 'EDIT_MAP_SUCCESS',
    EDIT_MAP_ERROR  : 'EDIT_MAP_ERROR',

    UPDATE_MAP_REQUEST : 'UPDATE_MAP_REQUEST',
    UPDATE_MAP_SUCCESS : 'UPDATE_MAP_SUCCESS',
    UPDATE_MAP_ERROR  : 'UPDATE_MAP_ERROR',

    DELETE_MAP_REQUEST : 'DELETE_MAP_REQUEST',
    DELETE_MAP_SUCCESS : 'DELETE_MAP_SUCCESS',
    DELETE_MAP_ERROR  : 'DELETE_MAP_ERROR',

    CHANGE_ORDER_MAP_REQUEST : 'CHANGE_ORDER_MAP_REQUEST',
    CHANGE_ORDER_MAP_SUCCESS : 'CHANGE_ORDER_MAP_SUCCESS',
    CHANGE_ORDER_MAP_ERROR  : 'CHANGE_ORDER_MAP_ERROR',


}