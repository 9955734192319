import { cueIntegrationTypes } from '../types/cueTypes';
import { cueIntegrationService } from '../../services/cueIntegrationService'; 
import { toast } from 'react-toastify';

export const cueIntegrationAction = { 
    getAll,
    add,
    edit,
    update,
}

function getAll() {
    return dispatch => {
        dispatch({type:cueIntegrationTypes.GETALL_CUE_INTEGRATION_REQUEST}); 
        cueIntegrationService.getAll().then(res => {
            if (res.status) {
                dispatch({type:cueIntegrationTypes.GETALL_CUE_INTEGRATION_SUCCESS, data: res.data });
                //toast.success(res.message);
            } else {
                dispatch({type:cueIntegrationTypes.GETALL_CUE_INTEGRATION_ERROR}); 
                toast.error(res.message);
            }
        })

    }
}
function add(data, setErrors, setSubmitting, navigate){
    return dispatch => {
        dispatch({type:cueIntegrationTypes.ADD_CUE_INTEGRATION_REQUEST});
        cueIntegrationService.add(data).then(res => {
            if(res.status){
               dispatch({type:cueIntegrationTypes.ADD_CUE_INTEGRATION_SUCCESS, data});
               toast.success(res.message);
               setSubmitting(false);
               setTimeout(function () {
                    dispatch(cueIntegrationAction.getAll());
                }, 500);
               //navigate(-1);
            }else{
                dispatch({type:cueIntegrationTypes.ADD_CUE_INTEGRATION_ERROR, error:res.error});
                toast.error(res.message);
                setSubmitting(false);
                if(res.error) {
                    setErrors(res.error);
                }
            }
        })
    }
}


function update(code, data, setErrors, setSubmitting, navigate){    
    return dispatch => {
        dispatch({type:cueIntegrationTypes.UPDATE_CUE_INTEGRATION_REQUEST});
        cueIntegrationService.update(code,  data).then(res => {
            if(res.status){
                dispatch({type:cueIntegrationTypes.UPDATE_CUE_INTEGRATION_SUCCESS, data:res.data});
                toast.success(res.message);
                setSubmitting(false);
                navigate("/cue-integration/view/"+code);
            }else{ 
                dispatch({type:cueIntegrationTypes.UPDATE_CUE_INTEGRATION_ERROR, error:res.error});
                toast.error(res.message);
                setSubmitting(false);
                if(res.error) {
                    setErrors(res.error);
                    setSubmitting(false);
                }
            }
        })
    }
}

function edit(id){
    return dispatch => {
        dispatch({type:cueIntegrationTypes.EDIT_CUE_INTEGRATION_REQUEST});
        cueIntegrationService.edit(id).then(res => {
            if(res.status){
                dispatch({type:cueIntegrationTypes.EDIT_CUE_INTEGRATION_SUCCESS, data:res.data})
            }else{
                dispatch({type:cueIntegrationTypes.EDIT_CUE_INTEGRATION_ERROR, error:res.error});
                toast.error(res.message);
            }
        })
    }
}
