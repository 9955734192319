import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams  } from 'react-router-dom';
import Typography from '@mui/material/Typography'; 
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CardActions from '@mui/material/CardActions';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import ImageUploading from "react-images-uploading";
import { BannerValidation } from '../../assets/validation/validation';
import { bannerAction } from '../../redux/actions/bannerAction';
import CircularProgress from '@mui/material/CircularProgress';
import config from '../../configs';
import FormHelperText from '@mui/material/FormHelperText';
import { Loader } from "../../errorPages/Loader";


export default function OfferBannerEdit() {
    const [statuss, setStatuss] = useState(false);
    const [images, setImages] = React.useState([]);
    const [data, setData] = React.useState([]);
    const [imageError, setImageError] = React.useState("");
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id } = useParams();
    const maxNumber = 1;
    const onChange = (imageList, addUpdateIndex) => {
      // data for submit
      setImages(imageList);
      if(imageList.length > 0) {
        setImageError("");
      }
    };

    const onErrorChange = (errors, files) => {
        if(errors){
            setImages([]);
        }
    };
  
    const handleStatusChange = (event) => {
        setStatuss(event.target.value);
    };

    useEffect(() => { 
        dispatch(bannerAction.edit(id)); 
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);
    
    const {bannerData , bannerData_status , loading } = useSelector(state => state.banners);   

    useEffect(() => { 
        if(bannerData_status === true){
            setData(bannerData);
        }
    }, [bannerData_status, bannerData])


    useEffect(() => { 
        if(data && data!==undefined && data !== ""){
            if(data.status !== undefined ){
                setStatuss(data.status);
            }
            if((data.imagePath && data.imagePath !== undefined) ){
                let arrValue = [];
                let arrObject = {
                    path: data.imagePath
                }   
                arrValue.push(arrObject)
                setImages(arrValue);                    
            }
        }
    }, [data])
  
  return (
    <Box sx={{ mb:3 }} className='cardWrapper'>
        {loading ? <Loader/> : '' }
         <div className="pageTitleWrap">
            <Stack  direction="row" spacing={1}   alignItems="center">
                <Typography type="submit" variant="text" component={Link} to={"/ticket"} > Ticket List</Typography>
                <Typography className="" component={"h2"} variant="h2" >
                    / Edit Ticket
                </Typography>
            </Stack>
        </div>
        <Formik
            initialValues={{
                bannerName : (data && data.bannerName) ? data.bannerName : '',
                buttonText: (data && data.buttonText) ? data.buttonText : '',
                buttonUrl :(data && data.buttonUrl) ? data.buttonUrl : '',
                //status: statuss,
            }}
            validationSchema={BannerValidation}
            enableReinitialize
            onSubmit={(values, {setErrors, setSubmitting}) => {
                let form_data = new FormData();
                form_data.append('bannerName', values.bannerName);
                form_data.append('buttonText', values.buttonText);
                form_data.append('buttonUrl', values.buttonUrl);
                form_data.append('status', statuss);
                if(images && images.length > 0){
                    images.map((field, idx) => {
                        if(field.file){
                            form_data.append('file', field.file);
                        }
                        return true;
                    });
                    setImageError("");
                } else {
                    setImageError("Banner Image is missing.  Please update the banner image.");
                    return false;
                }
                dispatch(bannerAction.update(id, form_data, setErrors, setSubmitting, navigate));
            }}>
            {({ errors, touched, values, handleChange, handleBlur, handleSubmit, handleReset, isSubmitting, setFieldValue }) => (
            <form onSubmit={handleSubmit}> 
                <Card sx={{}} variant="outlined" className="formWrapper">
                    <CardHeader title="Edit"/>
                    <CardContent > 
                        <Grid container spacing={2} alignItems="stretch">
                            <Grid item lg={5} xl={5} sx={{pr:5}}>
                                <TextField
                                    error={errors.bannerName && touched.bannerName}
                                    label="Banner Name"
                                    fullWidth 
                                    name="bannerName"
                                    value={values.bannerName}
                                    onChange={handleChange}
                                    onBlur={(e) => {
                                        const val = (e.target.value || '').replace(/\s+/gi, ' ');
                                        setFieldValue(e.target.name, val.trim());
                                        handleBlur(e);
                                    }}
                                    helperText={(errors.bannerName && touched.bannerName) && errors.bannerName}
                                    margin="normal"
                                    variant="outlined"
                                    required={false}
                                    InputLabelProps={{ required: true }} 
                                />
                                <TextField
                                    error={errors.buttonText && touched.buttonText}
                                    label="Button Text"
                                    fullWidth 
                                    name="buttonText"
                                    value={values.buttonText}
                                    onChange={handleChange}
                                    onBlur={(e) => {
                                        const val = (e.target.value || '').replace(/\s+/gi, ' ');
                                        setFieldValue(e.target.name, val.trim());
                                        handleBlur(e);
                                    }}
                                    helperText={(errors.buttonText && touched.buttonText) && errors.buttonText}
                                    margin="normal"
                                    variant="outlined"
                                    required={false}
                                    InputLabelProps={{ required: true }} 
                                />
                                <TextField
                                    error={errors.buttonUrl && touched.buttonUrl}
                                    label="URL"
                                    fullWidth 
                                    name="buttonUrl"
                                    value={values.buttonUrl}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    helperText={(errors.buttonUrl && touched.buttonUrl) && errors.buttonUrl}
                                    margin="normal"
                                    variant="outlined"
                                    required={false}
                                    InputLabelProps={{ required: true }} 
                                />
                                <FormControl sx={{mt:2, width:"100%", textAlign:"left"}}>
                                    <FormLabel id="demo-row-radio-buttons-group-label">Status <span>*</span></FormLabel>
                                    <RadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="status"
                                        value={statuss}
                                        onChange={handleStatusChange}
                                    >
                                        <FormControlLabel value={true} control={<Radio />} label="Active" />
                                        <FormControlLabel value={false} control={<Radio />} label="Inactive" />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                        
                            <Grid item lg={7} xl={7} sx={{}} >
                            <ImageUploading
                                value={images}
                                onChange={onChange}
                                onError={onErrorChange}
                                maxFileSize={5242880}
                                maxNumber={maxNumber}
                                dataURLKey="data_url"
                                acceptType={["jpg","bmp","png"]}
                                resolutionType="ratio"	
                                resolutionWidth={720}
                                resolutionHeight={1280}
                            >
                                {({
                                imageList,
                                onImageUpload,
                                onImageRemoveAll,
                                onImageUpdate,
                                onImageRemove,
                                isDragging,
                                dragProps,
                                errors 
                                }) => (
                                // write your building UI
                                
                                <div className="upload__image-wrapper">
                                    <Card sx={{height:"95%", border:"1px solid rgba(20,20,20,0.2)", minHeight:"250px", maxHeight:"250px", display:"flex", justifyContent:"center", alignItems:"center"}} elevation={0}>
                                    {images && images.length > 0 ?
                                        images.map((image, index) => {
                                            return(
                                                <div key={index} className="image-item">
                                                    <CardMedia
                                                        component="img"
                                                        height="100%"
                                                        image={image.path ? config.apiBaseUrl+'/'+image.path : image.data_url}
                                                        alt="green iguana"
                                                        onClick={() => onImageUpdate(index)}
                                                    />
                                                    <div className="image-item__btn-wrapper">
                                                        {/* <Button type="button" variant='contained' color="secondary" >Update</Button> */}
                                                        <IconButton className='delete_icon'  onClick={() => onImageRemove(index)}>
                                                            <i className='icon-close'></i>
                                                        </IconButton>
                                                    </div>
                                                </div>
                                            )
                                        })
                                        :
                                        <CardContent sx={{p:"0 !important", height:"100%"}}>
                                            <Box 
                                      
                                            onClick={onImageUpload}
                                            {...dragProps}
                                            >
                                                 <span className='upload_icon'><i className='icon-upload'></i></span>
                                                <span className='upload_content'>Upload Banner Image</span>
                                            </Box>
                                        </CardContent>
                                        
                                        }
                                     </Card>
                                     {errors ?
                                        <FormHelperText error={true}>
                                            {errors.acceptType && <span>Selected file type is not allow</span>} 
                                            {errors.maxFileSize && <span>Banner Image's size cannot exceed 5 MB</span>} 
                                            {errors.resolution && <span>Selected file is not matching desired resolution</span>} 
                                        </FormHelperText >
                                    : <FormHelperText error={true}>{imageError}</FormHelperText>
                                    }
                                    <Stack className='upload__helpText' direction="row" spacing={2} justifyContent="space-between"  alignItems="center">
                                        <Typography  variant="body1" component="p">
                                            File Size: Max 5 Mb
                                        </Typography>
                                        <Typography  variant="body1" component="p">
                                            File Resolution: above <b>720 x 1280</b> or <b>9:16</b> aspect ratio
                                        </Typography>
                                        <Typography  variant="body1" component="p">
                                            File Format: .jpeg .jpg .png .bmp 
                                        </Typography>
                                    </Stack>
                                </div>
                                )}
                            </ImageUploading>
                            </Grid>
                        </Grid> 
                    </CardContent>
                    <CardActions sx={{justifyContent:"center"}}>
                        <Button type="submit" variant="contained" color="primary"
                          disabled ={isSubmitting} 
                        > {isSubmitting ? <CircularProgress size={20}/> :  "Submit"}</Button> 
                        <Button type="button" variant="contained" color="secondary"
                        onClick={(e) => {    
                            handleReset();
                            navigate(-1);
                        }}
                        >Cancel</Button>
                    </CardActions>
                </Card>  
            </form>
            )}
        </Formik>    
    </Box>
  )
}
