import { newsarticleTypes } from '../types/newsarticleTypes';
import { newsarticleService } from '../../services/newsarticleService'; 
import { toast } from 'react-toastify';

export const newsArticleAction = { 
    getAll,
    add,
    edit,
    update,
}

function getAll() {
    return dispatch => {
        dispatch({type:newsarticleTypes.GETALL_NEWSARTICLE_REQUEST}); 
        newsarticleService.getAll().then(res => {
            if (res.status) {
                dispatch({type:newsarticleTypes.GETALL_NEWSARTICLE_SUCCESS, data: res.data });
                //toast.success(res.message);
            } else {
                dispatch({type:newsarticleTypes.GETALL_NEWSARTICLE_ERROR}); 
                toast.error(res.message);
            }
        })

    }
}
function add(data, setErrors, setSubmitting, navigate){
    return dispatch => {
        dispatch({type:newsarticleTypes.ADD_NEWSARTICLE_REQUEST});
        newsarticleService.add(data).then(res => {
            if(res.status){
               dispatch({type:newsarticleTypes.ADD_NEWSARTICLE_SUCCESS, data});
               toast.success(res.message);
               setSubmitting(false);
               setTimeout(function () {
                    dispatch(newsArticleAction.getAll());
                }, 500);
               //navigate(-1);
            }else{
                dispatch({type:newsarticleTypes.ADD_NEWSARTICLE_ERROR, error:res.error});
                toast.error(res.message);
                setSubmitting(false);
                if(res.error) {
                    setErrors(res.error);
                }
            }
        })
    }
}


function update(code, data, setErrors, setSubmitting, navigate){    
    return dispatch => {
        dispatch({type:newsarticleTypes.UPDATE_NEWSARTICLE_REQUEST});
        newsarticleService.update(code,  data).then(res => {
            if(res.status){
                dispatch({type:newsarticleTypes.UPDATE_NEWSARTICLE_SUCCESS, data:res.data});
                toast.success(res.message);
                setSubmitting(false);
                navigate("/news/view/"+code);
            }else{ 
                dispatch({type:newsarticleTypes.UPDATE_NEWSARTICLE_ERROR, error:res.error});
                toast.error(res.message);
                setSubmitting(false);
                if(res.error) {
                    setErrors(res.error);
                    setSubmitting(false);
                }
            }
        })
    }
}

function edit(id){
    return dispatch => {
        dispatch({type:newsarticleTypes.EDIT_NEWSARTICLE_REQUEST});
        newsarticleService.edit(id).then(res => {
            if(res.status){
                dispatch({type:newsarticleTypes.EDIT_NEWSARTICLE_SUCCESS, data:res.data})
            }else{
                dispatch({type:newsarticleTypes.EDIT_NEWSARTICLE_ERROR, error:res.error});
                toast.error(res.message);
            }
        })
    }
}
