import { USER_AUTH } from '../configs/ApiActionUrl';
import { httpService } from './httpService';

export const userAuthService = {
  userResetPassword,
  getResetPassword,
};

function userResetPassword(code, postData) {
  return httpService
    .post(USER_AUTH.RESET_PASSWORD + '/' + code, postData)
    .then((res) => {
      if (res.data) {
          return res.data;
      }
      return res;
    })
    .catch(function (error) {
      console.log(error);
    });
}

function getResetPassword(code) {
  return httpService
    .get(USER_AUTH.GET_RESET_PASSWORD + '/' + code)
    .then((res) => {
      if (res.data) {
        return res.data;
      }
      return res;
    })
    .catch(function (error) {
      console.log(error);
    });
}
