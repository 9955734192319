import { playerInfoTypes } from '../types/playerInfoTypes'
const initialState = {
    loading:false,
    allPlayers:[],
    allPlayersStatus:false,
    singlePlayer:[], 
    singlePlayerStatus:false,
    error: null, // Added to handle errors in a consistent way

};
export const playerInfo = (state = initialState , action) => {
    switch(action.type){
        case playerInfoTypes.GETALL_PLAYER_INFO_REQUEST:
            return {
                ...state,
                loading: true
            };
        case playerInfoTypes.GETALL_PLAYER_INFO_SUCCESS :
            return {
                ...state,
                loading: false,
                allPlayersStatus:true,
                allPlayers:action.data.playerList,
                error: null, // Reset error on success
            }
            
        case playerInfoTypes.GETALL_PLAYER_INFO_ERROR :
            return {
                ...state,
                loading: false,
                error : action.error
            }
        case playerInfoTypes.GET_SINGLE_PLAYER_INFO_REQUEST :
            return {
                ...state,
                loading: true,
            };
        case playerInfoTypes.GET_SINGLE_PLAYER_INFO_SUCCESS :
            return  {
                ...state,
                loading: false,
                singlePlayerStatus: true, 
                singlePlayer:action.data,
                error: null, // Reset error on success
            }
        case playerInfoTypes.GET_SINGLE_PLAYER_INFO_ERROR :
            return {
                ...state,
                loading: false,
                error : action.error
            }

        case playerInfoTypes.UPDATE_PLAYER_INFO_REQUEST :
            return {
                ...state,
                loading: true,
                data : action.data
            };
        case playerInfoTypes.UPDATE_PLAYER_INFO_SUCCESS :
            return  {
                ...state,
                loading: false,
                item:action.data,
                error: null, // Reset error on success
            }
        case playerInfoTypes.UPDATE_PLAYER_INFO_ERROR :
            return {
                ...state,
                loading: false,
                error : action.error, 
            }
        default :
            return state
    }
}