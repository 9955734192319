import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate  } from 'react-router-dom';
import Typography from '@mui/material/Typography'; 
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
//import CardMedia from '@mui/material/CardMedia';
import CardActions from '@mui/material/CardActions';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
//import IconButton from '@mui/material/IconButton';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
//import ImageUploading from "react-images-uploading";
import CircularProgress from '@mui/material/CircularProgress';
import { NewsArticleValidation } from '../../assets/validation/validation';
//import FormHelperText from '@mui/material/FormHelperText';
import { newsArticleAction } from '../../redux/actions/newsArticleAction'; 
import { Loader } from "../../errorPages/Loader";
import Divider from '@mui/material/Divider';



export default function NewsArticleAdd() {
     const [articleSatusOne, setArticleSatusOne] = useState(true);
     const [articleSatusTwo, setArticleSatusTwo] = useState(true);
     const [articleSatusThree, setArticleSatusThree] = useState(true);
     const [articleSatusFour, setArticleSatusFour] = useState(true);
     const [data, setData] = React.useState([]);
     const dispatch = useDispatch();
     const navigate = useNavigate();
    // const maxNumber = 1;

    useEffect(() => { 
        dispatch(newsArticleAction.getAll()); 
           // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);
    
    const {newsarticle_data , newsarticle_status , loading } = useSelector(state => state.newsarticle);

    useEffect(() => { 
        if(newsarticle_status === true){
            setData(newsarticle_data);
        }
    }, [newsarticle_status, newsarticle_data])


    useEffect(() => { 
        if(data && data.length > 0){
            if(data[0]._id){
                setTimeout(function () {
                    navigate("/news/view/"+data[0]._id)
                }, 100);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data])
  

  return (
    <Box sx={{ mb:3 }} className='cardWrapper'>
         {loading ? <Loader/> : '' }
         <div className="pageTitleWrap">
            <Stack  direction="row" spacing={1}  alignItems="center">
                <Typography type="submit" variant="text" component={Link} to={"/"} >News</Typography>
                <Typography className="" component={"h2"} variant="h2" >
                    / Add
                </Typography>
            </Stack>
        </div>
        <Formik
            initialValues={{
                articleOne: '',
                articleTwo: '',
                articleThree: '',
                articleFour: '',
                statusOne: 'true',
                statusTwo:'true',
                statusThree:'true',
                statusFour:'true',
              }}
              validationSchema={NewsArticleValidation}

            //enableReinitialize
            onSubmit={(values, {setErrors, setSubmitting}) => {
                //console.log(values);
                // let form_data = new FormData();
                // form_data.append('articleOne', values.articleOne);
                // form_data.append('articleTwo', values.articleTwo);
                // form_data.append('articleThree', values.articleThree);
                // form_data.append('articleFour', values.articleFour);
                // form_data.append('statusOne', articleSatusOne);
                // form_data.append('statusTwo', articleSatusTwo);
                // form_data.append('statusThree', articleSatusThree);
                // form_data.append('statusFour', articleSatusFour);
                
                dispatch(newsArticleAction.add(values, setErrors, setSubmitting, navigate));
            }}>
            {({ errors, touched, values, handleChange, handleBlur, handleSubmit, handleReset, isSubmitting, setFieldValue }) => (
            <form onSubmit={handleSubmit}> 
                <Card sx={{}} variant="outlined" className="formWrapper">
                    <CardHeader title="Add"/>
                        <CardContent> 
                            <Grid container spacing={2} alignItems="center" direction="row" justifyContent="center">
                                <Grid item lg={2} xl={2} sx={{pr:2}}>
                                    <Typography className="" component={"h6"} variant="h6" sx={{ fontSize: 15 }}>
                                        Position 1
                                    </Typography>
                                </Grid>

                                <Grid item lg={6} xl={6} sx={{pr:6}}>
                                    <TextField
                                        error={errors.articleOne && touched.articleOne}
                                        label="Article ID"
                                        fullWidth 
                                        name="articleOne"
                                        value={values.articleOne}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        helperText={(errors.articleOne && touched.articleOne) && errors.articleOne}
                                        margin="normal"
                                        variant="outlined"
                                        required={false}
                                        InputLabelProps={{ required: true }} 
                                    />
                                </Grid>

                                <Grid item lg={4} xl={4} sx={{pr:4}}>
                                    <FormControl sx={{mt:2, width:"100%", textAlign:"left"}}>
                                        <FormLabel id="demo-row-radio-buttons-group-label">Status <span>*</span></FormLabel>
                                        <RadioGroup
                                            row
                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                            name="statusOne"
                                            value={articleSatusOne ? "true" : "false"}
                                            // checked={articleSatusOne}
                                            onChange={(event) => {
                                                handleChange(event);
                                                setArticleSatusOne(event.target.value === 'true');
                                                // handleStatusChange(event,  'statusOne'); 
                                            }}
                                        >
                                            <FormControlLabel value="true" control={<Radio />} label="Active" />
                                            <FormControlLabel value="false"control={<Radio />} label="Inactive" />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} sx={{mt:3, mb:2}}>
                                <Divider orientation="horizontal" />
                            </Grid>

                            <Grid container spacing={2} alignItems="center" direction="row" justifyContent="center">
                                <Grid item lg={2} xl={2} sx={{pr:2}}>
                                    <Typography className="" component={"h6"} variant="h6" sx={{ fontSize: 15 }}>
                                        Position 2
                                    </Typography>
                                </Grid>

                                <Grid item lg={6} xl={6} sx={{pr:6}}>
                                    <TextField
                                        error={errors.articleTwo && touched.articleTwo}
                                        label="Article ID"
                                        fullWidth 
                                        name="articleTwo"
                                        value={values.articleTwo}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        helperText={(errors.articleTwo && touched.articleTwo) && errors.articleTwo}
                                        margin="normal"
                                        variant="outlined"
                                        required={false}
                                        InputLabelProps={{ required: true }} 
                                    />
                                </Grid>

                                <Grid item lg={4} xl={4} sx={{pr:4}}>
                                    <FormControl sx={{mt:2, width:"100%", textAlign:"left"}}>
                                        <FormLabel id="demo-row-radio-buttons-group-label">Status <span>*</span></FormLabel>
                                        <RadioGroup
                                            row
                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                            name="statusTwo"
                                            value={articleSatusTwo ? "true" : "false"}
                                            onChange={(event) => {
                                                handleChange(event);
                                                setArticleSatusTwo(event.target.value === 'true');
                                            }}
                                        >
                                            <FormControlLabel value={true} control={<Radio />} label="Active" />
                                            <FormControlLabel value={false} control={<Radio />} label="Inactive" />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} sx={{mt:3, mb:2}}>
                                <Divider orientation="horizontal" />
                            </Grid>

                            <Grid container spacing={2} alignItems="center" direction="row" justifyContent="center">
                                <Grid item lg={2} xl={2} sx={{pr:2}}>
                                    <Typography className="" component={"h6"} variant="h6" sx={{ fontSize: 15 }}>
                                        Position 3
                                    </Typography>
                                </Grid>

                                <Grid item lg={6} xl={6} sx={{pr:6}}>
                                    <TextField
                                        error={errors.articleThree && touched.articleThree}
                                        label="Article ID"
                                        fullWidth 
                                        name="articleThree"
                                        value={values.articleThree}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        helperText={(errors.articleThree && touched.articleThree) && errors.articleThree}
                                        margin="normal"
                                        variant="outlined"
                                        required={false}
                                        InputLabelProps={{ required: true }} 
                                    />
                                </Grid>

                                <Grid item lg={4} xl={4} sx={{pr:4}}>
                                    <FormControl sx={{mt:2, width:"100%", textAlign:"left"}}>
                                        <FormLabel id="demo-row-radio-buttons-group-label">Status <span>*</span></FormLabel>
                                        <RadioGroup
                                            row
                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                            name="statusThree"
                                            value={articleSatusThree ? "true" : "false"}
                                            onChange={(event) => {
                                                handleChange(event);
                                                setArticleSatusThree(event.target.value === 'true');
                                            }}
                                        >
                                            <FormControlLabel value={true} control={<Radio />} label="Active" />
                                            <FormControlLabel value={false} control={<Radio />} label="Inactive" />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} sx={{mt:3, mb:2}}>
                                <Divider orientation="horizontal" />
                            </Grid>

                            <Grid container spacing={2} alignItems="center" direction="row" justifyContent="center">
                                <Grid item lg={2} xl={2} sx={{pr:2}}>
                                    <Typography className="" component={"h6"} variant="h6" sx={{ fontSize: 15 }}>
                                        Position 4
                                    </Typography>
                                </Grid>

                                <Grid item lg={6} xl={6} sx={{pr:6}}>
                                    <TextField
                                        error={errors.articleFour && touched.articleFour}
                                        label="Article ID"
                                        fullWidth 
                                        name="articleFour"
                                        value={values.articleFour}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        helperText={(errors.articleFour && touched.articleFour) && errors.articleFour}
                                        margin="normal"
                                        variant="outlined"
                                        required={false}
                                        InputLabelProps={{ required: true }} 
                                    />
                                </Grid>

                                <Grid item lg={4} xl={4} sx={{pr:4}}>
                                    <FormControl sx={{mt:2, width:"100%", textAlign:"left"}}>
                                        <FormLabel id="demo-row-radio-buttons-group-label">Status <span>*</span></FormLabel>
                                        <RadioGroup
                                            row
                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                            name="statusFour"
                                            value={articleSatusFour ? "true" : "false"}
                                            onChange={(event) => {
                                                handleChange(event);
                                                setArticleSatusFour(event.target.value === 'true');
                                            }}
                                        >
                                            <FormControlLabel value={true} control={<Radio />} label="Active" />
                                            <FormControlLabel value={false} control={<Radio />} label="Inactive" />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                            </Grid>

                        </CardContent>
                        
                        <CardActions sx={{justifyContent:"center"}}>
                            <Button type="submit" variant="contained" color="primary"
                            disabled ={isSubmitting} 
                            > {isSubmitting ? <CircularProgress size={20}/> :  "Submit"}</Button> 
                            <Button type="button" variant="contained" color="secondary"
                            onClick={(e) => {    
                                handleReset();
                                navigate(-1);
                            }}
                            >Cancel</Button>
                        </CardActions>
                </Card>  
            </form>
            )}
        </Formik>    
    </Box>
  )
}
