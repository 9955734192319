export const fanCamTypes = {
    GETALL_FANCAM_REQUEST  : 'GETALL_FANCAM_REQUEST',
    GETALL_FANCAM_SUCCESS  : 'GETALL_FANCAM_SUCCESS',
    GETALL_FANCAM_ERROR  : 'GETALL_FANCAM_ERROR',

    ADD_FANCAM_REQUEST : 'ADD_FANCAM_REQUEST',
    ADD_FANCAM_SUCCESS : 'ADD_FANCAM_SUCCESS',
    ADD_FANCAM_ERROR  : 'ADD_FANCAM_ERROR',

    EDIT_FANCAM_REQUEST : 'EDIT_FANCAM_REQUEST',
    EDIT_FANCAM_SUCCESS : 'EDIT_FANCAM_SUCCESS',
    EDIT_FANCAM_ERROR  : 'EDIT_FANCAM_ERROR',

    UPDATE_FANCAM_REQUEST : 'UPDATE_FANCAM_REQUEST',
    UPDATE_FANCAM_SUCCESS : 'UPDATE_FANCAM_SUCCESS',
    UPDATE_FANCAM_ERROR  : 'UPDATE_FANCAM_ERROR',
}