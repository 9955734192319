import { cueIntegrationTypes } from '../types/cueTypes'
const initialState = {loading:false, cue_data:[], cue_status:false, cueIntegrationData:[], cueIntegrationData_status:false};
export const cueIntegration = (state = initialState , action) => {
    switch(action.type){
        case cueIntegrationTypes.GETALL_CUE_INTEGRATION_REQUEST:
            return {
                loading: true
            };
        case cueIntegrationTypes.GETALL_CUE_INTEGRATION_SUCCESS :
            return {
                ...state,
                loading: false,
                cue_status:true,
                cue_data:action.data.CUE  
            }
            
        case cueIntegrationTypes.GETALL_CUE_INTEGRATION_ERROR :
            return {
                loading: false,
                error : action.error
            }
            case cueIntegrationTypes.ADD_CUE_INTEGRATION_REQUEST :
                return {
                    loading: true,
                    data : action.data
                };
            case cueIntegrationTypes.ADD_CUE_INTEGRATION_SUCCESS :
                return  {
                    loading: false,
                    item:action.data
                }
            case cueIntegrationTypes.ADD_CUE_INTEGRATION_ERROR :
                return {
                    loading: false,
                    error : action.error, 
                }    
            case cueIntegrationTypes.EDIT_CUE_INTEGRATION_REQUEST :
                return {
                    loading: true,
                };
            case cueIntegrationTypes.EDIT_CUE_INTEGRATION_SUCCESS :
                return  {
                    ...state,
                    loading: false,
                    cueIntegrationData_status: true, 
                    cueIntegrationData:action.data,
                }
            case cueIntegrationTypes.EDIT_CUE_INTEGRATION_ERROR :
                return {
                    loading: false,
                    error : action.error
                }
    
            case cueIntegrationTypes.UPDATE_CUE_INTEGRATION_REQUEST :
                return {
                    loading: true,
                    data : action.data
                };
            case cueIntegrationTypes.UPDATE_CUE_INTEGRATION_SUCCESS :
                return  {
                    loading: false,
                    item:action.data
                }
            case cueIntegrationTypes.UPDATE_CUE_INTEGRATION_ERROR :
                return {
                    loading: false,
                    error : action.error, 
                }
            default :
                return state
    }
}