import { NEWSARTICLE } from '../configs/ApiActionUrl';
import { httpService } from './httpService';
export const newsarticleService = {
    getAll,
    add,
    edit,
    update,
}

function getAll(){   
    return httpService.get(NEWSARTICLE.GETALL)
        .then(res => {
            if (res.data) {
                return res.data;
            }
            return res;
        });
}

function add(data){
    return httpService.post(NEWSARTICLE.ADD, data)
    .then(res => {
        if (res.data) {
            return res.data;
        }
        return res;
    });
}
function edit(id){
    return httpService.get(NEWSARTICLE.GET_INDIVIDUAL+"/"+id)
        .then(res => {
            if (res.data) {
                return res.data;
            }
            return res;
        });
}
function update(code, data){
    return httpService.put(NEWSARTICLE.UPDATE_INDIVIDUAL+"/"+code, data)   
    .then(res => {
        if (res.data) {
            return res.data;
        }
        return res;
    });
}