import { promotileTypes } from '../types/promotileTypes'
const initialState = {loading:false, promotile_data:[], promotile_status:false, promoTileData:[], promoTileData_status:false};
export const promotile = (state = initialState , action) => {
    switch(action.type){
        case promotileTypes.GETALL_PROMOTILE_REQUEST:
            return {
                loading: true
            };
        case promotileTypes.GETALL_PROMOTILE_SUCCESS :
            return {
                ...state,
                loading: false,
                promotile_status:true,
                promotile_data:action.data.promoTile
            }
            
        case promotileTypes.GETALL_PROMOTILE_ERROR :
            return {
                loading: false,
                error : action.error
            }
            case promotileTypes.ADD_PROMOTILE_REQUEST :
                return {
                    loading: true,
                    data : action.data
                };
            case promotileTypes.ADD_PROMOTILE_SUCCESS :
                return  {
                    loading: false,
                    item:action.data
                }
            case promotileTypes.ADD_PROMOTILE_ERROR :
                return {
                    loading: false,
                    error : action.error,
                }    
            case promotileTypes.EDIT_PROMOTILE_REQUEST :
                return {
                    loading: true,
                };
            case promotileTypes.EDIT_PROMOTILE_SUCCESS :
                return  {
                    ...state,
                    loading: false,
                    promoTileData_status: true, 
                    promoTileData:action.data,
                }
            case promotileTypes.EDIT_PROMOTILE_ERROR :
                return {
                    loading: false,
                    error : action.error
                }
    
            case promotileTypes.UPDATE_PROMOTILE_REQUEST :
                return {
                    loading: true,
                    data : action.data
                };
            case promotileTypes.UPDATE_PROMOTILE_SUCCESS :
                return  {
                    loading: false,
                    item:action.data
                }
            case promotileTypes.UPDATE_PROMOTILE_ERROR :
                return {
                    loading: false,
                    error : action.error, 
                }
            default :
                return state
    }
}