import { bannerTypes } from '../types/bannerTypes';
import { bannerService } from '../../services/bannerService';
import { toast } from 'react-toastify';

export const bannerAction = {
    getAll,
    add,
    edit,
    update,
    deleteBanner,
    changeOrder
}

function getAll() {
    return dispatch => {
        dispatch({type:bannerTypes.GETALL_BANNER_REQUEST}); 
        bannerService.getAll().then(res => {
            if (res.status) {
                dispatch({type:bannerTypes.GETALL_BANNER_SUCCESS, data: res.data });
                //toast.success(res.message);
            } else {
                dispatch({type:bannerTypes.GETALL_BANNER_ERROR}); 
                toast.error(res.message);
            }
        })

    }
}
function add(data, setErrors, setSubmitting, navigate){
    return dispatch => {
        dispatch({type:bannerTypes.ADD_BANNER_REQUEST});
        bannerService.add(data).then(res => {
            if(res.status){
               dispatch({type:bannerTypes.ADD_BANNER_SUCCESS, data});
               toast.success(res.message);
               setSubmitting(false);
               navigate(-1);
            }else{
                dispatch({type:bannerTypes.ADD_BANNER_ERROR, error:res.error});
                toast.error(res.message);
                setSubmitting(false);
                if(res.error) {
                    setErrors(res.error);
                }
            }
        })
    }
}

function deleteBanner(code, closeModal){ 
    return dispatch => {
        dispatch({type:bannerTypes.DELETE_BANNER_REQUEST});
        bannerService.deleteBanner(code).then(res => { 
            if(res.status){
                dispatch({type:bannerTypes.DELETE_BANNER_SUCCESS, code});
                toast.success(res.message);
                closeModal();
                setTimeout(function () {
                    dispatch(bannerAction.getAll());
                }, 500);
            }else{
                dispatch({type:bannerTypes.DELETE_BANNER_ERROR, error:res.error});
                toast.error(res.message);
            }
        })
    }
}

function update(code, data, setErrors, setSubmitting, navigate){    
    return dispatch => {
        dispatch({type:bannerTypes.UPDATE_BANNER_REQUEST});
        bannerService.update(code,  data).then(res => {
            if(res.status){
                dispatch({type:bannerTypes.UPDATE_BANNER_SUCCESS, data:res.data});
                toast.success(res.message);
                setSubmitting(false);
                navigate(-1);
            }else{ 
                dispatch({type:bannerTypes.UPDATE_BANNER_ERROR, error:res.error});
                toast.error(res.message);
                setSubmitting(false);
                if(res.error) {
                    setErrors(res.error);
                    setSubmitting(false);
                }
            }
        })
    }
}

function edit(id){
    return dispatch => {
        dispatch({type:bannerTypes.EDIT_BANNER_REQUEST});
        bannerService.edit(id).then(res => {
            if(res.status){
                dispatch({type:bannerTypes.EDIT_BANNER_SUCCESS, data:res.data})
            }else{
                dispatch({type:bannerTypes.EDIT_BANNER_ERROR, error:res.error});
                toast.error(res.message);
            }
        })
    }
}

function changeOrder(id, newRankOrder){
    return dispatch => {
        dispatch({type:bannerTypes.CHANGE_ORDER_BANNER_REQUEST});
        bannerService.changeOrder(id, newRankOrder).then(res => {
            if(res.status){
                dispatch({type:bannerTypes.CHANGE_ORDER_BANNER_SUCCESS});
                toast.success(res.message);
            }else{
                dispatch({type:bannerTypes.CHANGE_ORDER_BANNER_ERROR, error:res.error});
                toast.error(res.message);
            }
        })
    }
}
