import { userAuthTypes } from '../types/userAuthTypes';
import { userAuthService } from '../../services/userAuthService';
import { toast } from 'react-toastify';

export const userAuthAction = {
  resetPassword,
  getRestPassword,
};

function resetPassword(code, postData, setErrors, setSubmitting, navigate) {
  console.log(code, 'code');
  return (dispatch) => {
    dispatch({ type: userAuthTypes.USER_RESET_PASSWORD_REQUEST, postData });
    userAuthService.userResetPassword(code, postData).then((res) => {
      if (res.status) {
        dispatch({
          type: userAuthTypes.USER_RESET_PASSWORD_SUCCESS,
          data: res.data,
        });
        setSubmitting(false);
        toast.success(res.message);
        navigate('/successful-reset-password');
      } else {
        dispatch({
          type: userAuthTypes.USER_RESET_PASSWORD_ERROR,
          error: res.error,
        });
        toast.error(res.message);
        setSubmitting(false);
        if (res.error) setErrors(res.error);
      }
    });
  };
}

function getRestPassword(postData) {
  return (dispatch) => {
    dispatch({ type: userAuthTypes.USER_GET_RESET_PASSWORD_REQUEST, postData });
    userAuthService.getResetPassword(postData).then((res) => {
      if (res && res.link_status) {
        dispatch({
          type: userAuthTypes.USER_GET_RESET_PASSWORD_SUCCESS,
          data: res.link_status,
        });
        //toast.success(res.message)
      } else {
        dispatch({
          type: userAuthTypes.USER_GET_RESET_PASSWORD_ERROR,
          error: res.error,
        });
        //toast.error(res.message)
      }
    });
  };
}
