import React, { useEffect } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Box from "@mui/material/Box";
import SideBarLayout from "./Sidebar";
import ShopList from "../shop";
import Add from "../shop/add";
import Edit from "../shop/edit";
import View from "../shop/view";
import TicketList from "../tickets";
import TicketAdd from "../tickets/add";
import TicketEdit from "../tickets/edit";
import TicketView from "../tickets/view";
import OfferBannerList from "../offerBanner";
import OfferBannerAdd from "../offerBanner/add";
import OfferBannerEdit from "../offerBanner/edit";
import OfferBannerView from "../offerBanner/view";
import MapList from "../map";
import MapAdd from "../map/add";
import MapEdit from "../map/edit";
import MapView from "../map/view";
import GeneralSettings from "../settings";
import TradableBitsAdd from "../tradableBits/add";
import TradableBitsView from "../tradableBits/view";
import TradableBitsEdit from "../tradableBits/edit";

import GameSliderAdd from "../gameSlider/add";
import GameSliderView from "../gameSlider/view";
import GameSliderEdit from "../gameSlider/edit";

import PromoTileAdd from "../promoTile/add";
import PromoTileView from "../promoTile/view";
import PromoTileEdit from "../promoTile/edit";

import NewsArticleAdd from "../newsArticle/add";
import NewsArticleView from "../newsArticle/view";
import NewsArticleEdit from "../newsArticle/edit";

import CueIntegrationAdd from "../cueIntegrate/add";
import CueIntegrationView from "../cueIntegrate/view";
import CueIntegrationEdit from "../cueIntegrate/edit";

import FanCamAdd from "../fanCam/add";
import FanCamView from "../fanCam/view";
import FanCamEdit from "../fanCam/edit";

import PlayerInfoList from "../playerInfo"; 
import PlayerInfoView from "../playerInfo/view";
import PlayerInfoEdit from "../playerInfo/edit";

import { fanCamAction } from '../../redux/actions/fanCamAction'; 
import { cueIntegrationAction } from "../../redux/actions/cueAction";
import { tradableBitsAction } from "../../redux/actions/tradablebitsAction";
import { gamesliderAction } from '../../redux/actions/gamesliderAction';
import { promoTileAction } from '../../redux/actions/promoTileAction';
import { newsArticleAction } from '../../redux/actions/newsArticleAction';

export const HeaderLayout = () => {
  const [bitsData, setBitsData] = React.useState([]);
  const [bitsAccess, setBitsAccess] = React.useState(false);

  const [fanCamData, setFanCamData] = React.useState([]);
  const [fanCamAccess, setFanCamAccess] = React.useState(false);

  const [cueData, setCueData] = React.useState([]);
  const [cueAccess, setCueAccess] = React.useState(false);

  const [sliderData, setSliderData] = React.useState([]);
  const [sliderAccess, setSliderAccess] = React.useState(false);

  const [promotileData, setPromoTileData] = React.useState([]);
  const [promotileAccess, setPromoTileAccess] = React.useState(false);

  const [newsarticleData, setNewsArticleData] = React.useState([]);
  const [newsarticleAccess, setNewsArticleAccess] = React.useState(false);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(tradableBitsAction.getAll());
    dispatch(fanCamAction.getAll());
    dispatch(cueIntegrationAction.getAll());
    dispatch(gamesliderAction.getAll());
    dispatch(promoTileAction.getAll());
    dispatch(newsArticleAction.getAll());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const { bits_data, bits_status} = useSelector((state) => state.tradablebits);
  const {fanCam_data , fanCam_status} = useSelector(state => state.fanCam);   
  const {cue_data , cue_status } = useSelector(state => state.cueIntegration);   
  const {slider_data , slider_status } = useSelector(state => state.gameslider);   
  const {promotile_data , promotile_status } = useSelector(state => state.promotile);   
  const {newsarticle_data , newsarticle_status } = useSelector(state => state.newsarticle);  

  useEffect(() => {
    if (bits_status === true) {
      setBitsData(bits_data);
    }

    if (fanCam_status === true) {
      setFanCamData(fanCam_data);
    }

    if (cue_status === true) {
      setCueData(cue_data);
    }

    if(slider_status === true){
      setSliderData(slider_data);
    }

    if(promotile_status === true){
      setPromoTileData(promotile_data);
    }

    if(newsarticle_status === true){
      setNewsArticleData(newsarticle_data);
    }

  }, [bits_status, bits_data, fanCam_data , fanCam_status, cue_data , cue_status, slider_status, slider_data, promotile_status, promotile_data, newsarticle_status, newsarticle_data]);

  useEffect(() => {
    if (bitsData && bitsData.length > 0) {
      setBitsAccess(true);
    } else {
      setBitsAccess(false);
    }

    if (fanCamData && fanCamData.length > 0) {
      setFanCamAccess(true);
    } else {
      setFanCamAccess(false);
    }

    if (cueData && cueData.length > 0) {
      setCueAccess(true);
    } else {
      setCueAccess(false);
    }

    if (sliderData && sliderData.length > 0) {
      setSliderAccess(true);
    } else {
      setSliderAccess(false);
    }

    if (promotileData && promotileData.length > 0) {
      setPromoTileAccess(true);
    } else {
      setPromoTileAccess(false);
    }

    if (newsarticleData && newsarticleData.length > 0) {
      setNewsArticleAccess(true);
    } else {
      setNewsArticleAccess(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bitsData, fanCamData, cueData, sliderData, promotileData, newsarticleData]);

  const drawerWidth = 140;
  return (
    <Box sx={{ display: "flex" }}>
      <SideBarLayout />
      <Box
        className="contnenWrap"
        component="main"
        sx={{ flexGrow: 1, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
      >
        <div className="content">
          <Routes>
            <Route exact path="/shop" element={<ShopList />} />
            <Route exact path="/shop/add" element={<Add />} />
            <Route exact path="/shop/edit/:id" element={<Edit />} />
            <Route exact path="/shop/view/:id" element={<View />} />

            <Route exact path="/ticket" element={<TicketList />} />
            <Route exact path="/ticket/add" element={<TicketAdd />} />
            <Route exact path="/ticket/edit/:id" element={<TicketEdit />} />
            <Route exact path="/ticket/view/:id" element={<TicketView />} />

            <Route exact path="/offer" element={<OfferBannerList />} />
            <Route exact path="/offer/add" element={<OfferBannerAdd />} />
            <Route exact path="/offer/edit/:id" element={<OfferBannerEdit />} />
            <Route exact path="/offer/view/:id" element={<OfferBannerView />} />

            <Route exact path="/stadium-map" element={<MapList />} />
            <Route exact path="/stadium-map/add" element={<MapAdd />} />
            <Route exact path="/stadium-map/edit/:id" element={<MapEdit />} />
            <Route exact path="/stadium-map/view/:id" element={<MapView />} />

            <Route exact path="/settings" element={<GeneralSettings />} />
            <Route exact path="/tradablebits" element={<TradableBitsAdd />} />
            <Route
              exact
              path="/tradablebits/view/:id"
              element={
                bitsAccess ? (
                  <TradableBitsView />
                ) : (
                  <Navigate to="/tradablebits" replace />
                )
              }
            />
            <Route
              exact
              path="/tradablebits/edit/:id"
              element={
                bitsAccess ? (
                  <TradableBitsEdit />
                ) : (
                  <Navigate to="/tradablebits" replace />
                )
              }
            />

            <Route exact path="/fan-cam" element={<FanCamAdd />} /> 
            <Route
              exact
              path="/fan-cam/view/:id"
              element={ 
                fanCamAccess ? (
                  <FanCamView />
                ) : (
                  <Navigate to="/fan-cam" replace />
                )
              }
            />
            <Route
              exact
              path="/fan-cam/edit/:id"
              element={
                fanCamAccess ? (
                  <FanCamEdit />
                ) : (
                  <Navigate to="/fan-cam" replace />
                )
              }
            />

            <Route exact path="/cue-integration" element={<CueIntegrationAdd />} />
            <Route
              exact
              path="/cue-integration/view/:id"
              element={
                cueAccess ? (
                  <CueIntegrationView />
                ) : (
                  <Navigate to="/cue-integration" replace />
                )
              }
            />
            <Route
              exact
              path="/cue-integration/edit/:id"
              element={
                cueAccess ? (
                  <CueIntegrationEdit />
                ) : (
                  <Navigate to="/fan-cam" replace />
                )
              }
            />

            <Route exact path="/player-info" element={<PlayerInfoList />} />
            <Route exact path="/player-info/edit/:id" element={<PlayerInfoEdit />} />
            <Route exact path="/player-info/view/:id" element={<PlayerInfoView />} />

            <Route exact path="/gameslider" element={<GameSliderAdd />} />
            <Route
              exact
              path="/gameslider/view/:id"
              element={
                sliderAccess ? (
                  <GameSliderView />
                ) : (
                  <Navigate to="/gameslider" replace />
                )
              }
            />
            <Route
              exact
              path="/gameslider/edit/:id"
              element={
                sliderAccess ? (
                  <GameSliderEdit />
                ) : (
                  <Navigate to="/gameslider" replace />
                )
              }
            />

            <Route exact path="/promotile" element={<PromoTileAdd />} />
            <Route
              exact
              path="/promotile/view/:id"
              element={
                // <PromoTileView />
                promotileAccess ? (
                  <PromoTileView />
                ) : (
                  <Navigate to="/promotile" replace />
                )
              }
            />
            <Route
              exact
              path="/promotile/edit/:id"
              element={
                promotileAccess ? (
                  <PromoTileEdit />
                ) : (
                  <Navigate to="/promotile" replace />
                )
              }
            />

            <Route exact path="/news" element={<NewsArticleAdd />} />
            <Route
              exact
              path="/news/view/:id"
              element={
                 //<NewsArticleView />
                newsarticleAccess ? (
                  <NewsArticleView />
                ) : (
                  <Navigate to="/news" replace />
                )
              }
            />
            <Route
              exact
              path="/news/edit/:id"
              element={
                newsarticleAccess ? (
                  <NewsArticleEdit />
                ) : (
                  <Navigate to="/news" replace />
                )
              }
            />


            <Route path="*" element={<Navigate to="/404" />} />
          </Routes>
        </div>
      </Box>
    </Box>
  );
};
