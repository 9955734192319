import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Formik } from 'formik';
import logo from '../../assets/img/fc_dallas_logo.png';
//import '../../assets/css/custom.css';
import { UserResetPasswordValidate } from '../../assets/validation/validation';
import { userAuthAction } from './../../redux/actions/userAuthAction';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import { Loader } from '../../errorPages/Loader';
import CircularProgress from '@mui/material/CircularProgress';
import UserLinkExpired from '../../errorPages/UserLinkExpired';

const UserResetPassword = (props) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  let navigate = useNavigate();
  useEffect(() => {
    if (id) {
      dispatch(userAuthAction.getRestPassword(id));
    }
  }, [dispatch, id]);

  const { verifyLinkStatus, isLoading } = useSelector(
    (state) => state.userauthentication
  );

  if (verifyLinkStatus) {
    return (
      <div>
        {isLoading === true ? <Loader className="opacity95" /> : ''}

        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            background: '#fff',
          }}
        >
          <Box
            sx={{
              // m: 1,
              textAlign: 'center',
              p: 4,
              background: '#e6e6e6',
              borderRadius: '8px',
              //},
            }}
          >
            <img src={logo} alt="Logo" />

            <Formik
              initialValues={{
                password: '',
                confirmPassword: '',
              }}
              enableReinitialize
              validationSchema={UserResetPasswordValidate}
              onSubmit={(values, { setErrors, setSubmitting }) => {
                dispatch(
                  userAuthAction.resetPassword(
                    id,
                    values,
                    setErrors,
                    setSubmitting,
                    navigate
                  )
                );
              }}
            >
              {({
                errors,
                touched,
                values,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
              }) => (
                <form onSubmit={handleSubmit}>
                  <div className="form_wrapper">
                    <div>
                      <Box
                        className="userResetPasswordWrap"
                        sx={{
                          maxWidth: '320px',
                          width: '100%',
                        }}
                      >
                        <Typography
                          variant="h3"
                          gutterBottom
                          component=""
                          mb={2}
                          sx={{ fontSize: '30px', mt: 3 }}
                        >
                          Reset Password
                        </Typography>
                        <Paper elevation={0} sx={{ background: 'none' }}>
                          <TextField
                            id="filled-password-input"
                            fullWidth
                            variant="outlined"
                            label="New Password"
                            name="password"
                            type={'password'}
                            autoComplete="new-password"
                            margin="normal"
                            error={errors.password && touched.password}
                            value={values.password}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            sx={{
                              '& .MuiFormLabel-root': {
                                '.MuiFormLabel-asterisk': {
                                  color: '#c6093b',
                                },
                              },
                              '& .MuiInputBase-root': {
                                position: 'relative',
                                background: '#fff',
                              },
                            }}
                            helperText={
                              errors.password &&
                              touched.password &&
                              errors.password
                            }
                            required={false}
                            InputLabelProps={{ required: true }}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    disabled
                                    //onClick={handleClickShowPassword}
                                    edge="end"
                                  >
                                    {<i className="icon-password"></i>}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                          <TextField
                            id="filled-password-input"
                            fullWidth
                            variant="outlined"
                            label="Confirm Password"
                            name="confirmPassword"
                            type={'password'}
                            autoComplete="confirm-password"
                            margin="normal"
                            sx={{
                              '& .MuiFormLabel-root': {
                                '.MuiFormLabel-asterisk': {
                                  color: '#c6093b',
                                },
                              },
                              '& .MuiInputBase-root': {
                                position: 'relative',
                                background: '#fff',
                              },
                            }}
                            error={
                              errors.confirmPassword && touched.confirmPassword
                            }
                            value={values.confirmPassword}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            helperText={
                              errors.confirmPassword &&
                              touched.confirmPassword &&
                              errors.confirmPassword
                            }
                            required={false}
                            InputLabelProps={{ required: true }}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    disabled
                                    aria-label="toggle password visibility"
                                    //onClick={handleClickShowPassword}
                                    edge="end"
                                  >
                                    {<i className="icon-password"></i>}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                          <Button
                            type="submit"
                            className="submit_btn"
                            variant="contained"
                            sx={{ mt: 5, mb: 2 }}
                            size="large"
                          >
                            {isSubmitting ? (
                              <CircularProgress size={20} />
                            ) : (
                              'Submit'
                            )}
                          </Button>
                        </Paper>
                      </Box>
                      <Typography
                        className="copyright_p"
                        variant="body1"
                        mt={2}
                        align="center"
                      >
                        ©{props.year}
                      </Typography>
                    </div>
                  </div>
                </form>
              )}
            </Formik>
          </Box>
        </Box>
      </div>
    );
  } else if (!verifyLinkStatus) {
    return (
      <div>
        {isLoading === true ? <Loader className="opacity95" /> : false}
        <UserLinkExpired />
      </div>
    );
  } else {
    return (
      <div>{isLoading === true ? <Loader className="opacity95" /> : false}</div>
    );
  }
};
export default UserResetPassword;
