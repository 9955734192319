import React from 'react';
import Box from '@mui/material/Box';
import { Link } from 'react-router-dom';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import Avatar from '@mui/material/Avatar';

const LinkExpired = () => {
    return (
        <div className="auth loginbox main-content my-5">
            <Box
                sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100vh',
                    background:"#001f5b",
                    '& > :not(style)': {
                    m: 1,
                    border: '1px solid #E5E5E5',
                    boxShadow: '0px 4px 53px rgba(20, 121, 255, 0.09)',
                    borderRadius: '4px',
                    },
                }}
                >
                    <Box sx={{
                        m: 1,
                        background:'#fff',
                        width: 495,
                        padding:'44px 50px 44px 44px',
                        textAlign: 'center',
                    }}>
                        <Paper elevation={0}>
                            <Avatar sx={{ justifyContent: 'center', margin: '0  auto 20px', width: 65, height: 65, bgcolor:"#001f5b"  }}>
                                <LinkOffIcon />
                            </Avatar>
                            <Typography variant="h3" gutterBottom component="h3"  mb={1}>
                                Link Expired
                            </Typography>
                            <Typography variant="subtitle1" gutterBottom component="" mb={1}>
                                Your reset password link has expired/invalid
                            </Typography>
                            <Button 
                                className='btn submit_btn' 
                                variant="contained" 
                                component={Link}
                                to="/"
                                sx={{marginTop: "15px!important"}} 
                                size="large"
                            >
                                Back to Login
                            </Button>
                        </Paper>
                    </Box>
                </Box>
            </div>

    );
};
export default LinkExpired;