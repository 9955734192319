import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
//import CardMedia from "@mui/material/CardMedia";
import CardActions from "@mui/material/CardActions";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import config from "../../configs";
import { newsArticleAction } from "../../redux/actions/newsArticleAction";
import Divider from '@mui/material/Divider';

export default function NewsArticleView() {
  const [articleSatusOne, setArticleSatusOne] = useState(true);
  const [articleSatusTwo, setArticleSatusTwo] = useState(true);
  const [articleSatusThree, setArticleSatusThree] = useState(true);
  const [articleSatusFour, setArticleSatusFour] = useState(true);
  const [data, setData] = React.useState([]);
  const dispatch = useDispatch();
  const { id } = useParams();

  // const handleStatusChange = (event) => {
  //   setStatuss(event.target.value);
  // };

  useEffect(() => {
    dispatch(newsArticleAction.edit(id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const { newsArticleData, newsArticleData_status } = useSelector(
    (state) => state.newsarticle
  );
  

  useEffect(() => {
    if (newsArticleData_status === true) {
      setData(newsArticleData);
    }
  }, [newsArticleData_status, newsArticleData]);

  useEffect(() => { 

    if(data && data!==undefined && data !== ""){
      setArticleSatusOne(data.statusOne);
      setArticleSatusTwo(data.statusTwo);
      setArticleSatusThree(data.statusThree);
      setArticleSatusFour(data.statusFour);
    }
}, [data])

  // useEffect(() => {
  //   if (data && data !== undefined && data !== "") {
  //     if (data.status !== undefined) {
  //       setStatuss(data.status);
  //     }
  //     if (data.imagePath && data.imagePath !== undefined) {
  //       let arrValue = [];
  //       let arrObject = {
  //         path: data.imagePath,
  //       };
  //       arrValue.push(arrObject);
  //       setImages(arrValue);
  //     }
  //   }
  // }, [data]);


  return (
    <Box sx={{ mb: 3 }} className="cardWrapper">
      <div className="pageTitleWrap">
        <Stack direction="row" spacing={1} alignItems="center">
          <Typography
            type="submit"
            variant="text"
            component={Link}
            to={"/news/view/" + id}
          >
            {" "}
            News
          </Typography>
          <Typography className="" component={"h2"} variant="h2">
            / View
          </Typography>
        </Stack>
      </div>
      <Formik
        // initialValues={{
        //   name: data && data.name ? data.name : "",
        //   redirectUrl:
        //     data && data.redirectUrl ? data.redirectUrl : "",
        //   status: statuss,
        // }}
        initialValues={{
          articleOne: data && data.articleOne ? data.articleOne : "",
          articleTwo: data && data.articleTwo ? data.articleTwo : "",
          articleThree: data && data.articleThree ? data.articleThree : "",
          articleFour: data && data.articleFour ? data.articleFour : "",
          statusOne: articleSatusOne ? 'true' : 'false',
          statusTwo: articleSatusTwo ? 'true' : 'false',
          statusThree: articleSatusThree ? 'true' : 'false',
          statusFour: articleSatusFour ? 'true' : 'false',
        }}
        enableReinitialize
        onSubmit={(values, {setErrors, setSubmitting}) => {}}
      >
        {({
          errors,
          touched,
          values,
          handleChange,
          handleBlur,
          handleSubmit,
          handleReset,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit}>
            <Card sx={{}} variant="outlined" className="formWrapper">
                <CardHeader title="View"/>
                    <CardContent> 
                        <Grid container spacing={2} alignItems="center" direction="row" justifyContent="center">
                          <Grid item lg={2} xl={2} sx={{pr:2}}>
                            <Typography className="" component={"h6"} variant="h6" sx={{ fontSize: 15 }}>
                                Position 1
                            </Typography>
                          </Grid>

                          <Grid item lg={6} xl={6} sx={{pr:6}}>
                            <TextField
                                error={errors.articleOne && touched.articleOne}
                                label="Article ID"
                                fullWidth 
                                name="articleOne"
                                value={values.articleOne}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                helperText={(errors.articleOne && touched.articleOne) && errors.articleOne}
                                margin="normal"
                                variant="outlined"
                                disabled
                            />
                          </Grid>

                          <Grid item lg={4} xl={4} sx={{pr:4}}>
                            <FormControl sx={{mt:2, width:"100%", textAlign:"left"}}>
                              <FormLabel id="demo-row-radio-buttons-group-label">Status {" "}</FormLabel>
                                <RadioGroup
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="statusOne"
                                    value={articleSatusOne ? "true" : "false"}
                                    // checked={articleSatusOne}
                                    onChange={(event) => {
                                        handleChange(event);
                                        setArticleSatusOne(event.target.value === 'true');
                                        // handleStatusChange(event,  'statusOne'); 
                                    }}
                                >
                                  <FormControlLabel disabled value="true" control={<Radio />} label="Active" />
                                  <FormControlLabel disabled value="false"control={<Radio />} label="Inactive" />
                              </RadioGroup>
                            </FormControl>
                          </Grid>
                        </Grid>

                        <Grid item xs={12} sx={{mt:3, mb:2}}>
                            <Divider orientation="horizontal" />
                        </Grid>

                        <Grid container spacing={2} alignItems="center" direction="row" justifyContent="center">
                            <Grid item lg={2} xl={2} sx={{pr:2}}>
                                <Typography className="" component={"h6"} variant="h6" sx={{ fontSize: 15 }}>
                                    Position 2
                                </Typography>
                            </Grid>

                            <Grid item lg={6} xl={6} sx={{pr:6}}>
                                <TextField
                                    error={errors.articleTwo && touched.articleTwo}
                                    label="Article ID"
                                    fullWidth 
                                    name="articleTwo"
                                    value={values.articleTwo}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    helperText={(errors.articleTwo && touched.articleTwo) && errors.articleTwo}
                                    margin="normal"
                                    variant="outlined"
                                    disabled
                                />
                            </Grid>

                            <Grid item lg={4} xl={4} sx={{pr:4}}>
                                <FormControl sx={{mt:2, width:"100%", textAlign:"left"}}>
                                    <FormLabel id="demo-row-radio-buttons-group-label">Status {" "}</FormLabel>
                                    <RadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="statusTwo"
                                        value={articleSatusTwo ? "true" : "false"}
                                        onChange={(event) => {
                                            handleChange(event);
                                            setArticleSatusTwo(event.target.value === 'true');
                                        }}
                                    >
                                        <FormControlLabel disabled value={true} control={<Radio />} label="Active" />
                                        <FormControlLabel disabled value={false} control={<Radio />} label="Inactive" />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} sx={{mt:3, mb:2}}>
                            <Divider orientation="horizontal" />
                        </Grid>

                        <Grid container spacing={2} alignItems="center" direction="row" justifyContent="center">
                            <Grid item lg={2} xl={2} sx={{pr:2}}>
                                <Typography className="" component={"h6"} variant="h6" sx={{ fontSize: 15 }}>
                                    Position 3
                                </Typography>
                            </Grid>

                            <Grid item lg={6} xl={6} sx={{pr:6}}>
                                <TextField
                                    error={errors.articleThree && touched.articleThree}
                                    label="Article ID"
                                    fullWidth 
                                    name="articleThree"
                                    value={values.articleThree}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    helperText={(errors.articleThree && touched.articleThree) && errors.articleThree}
                                    margin="normal"
                                    variant="outlined"
                                    disabled
                                />
                            </Grid>

                            <Grid item lg={4} xl={4} sx={{pr:4}}>
                                <FormControl sx={{mt:2, width:"100%", textAlign:"left"}}>
                                    <FormLabel id="demo-row-radio-buttons-group-label">Status {" "}</FormLabel>
                                    <RadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="statusThree"
                                        value={articleSatusThree ? "true" : "false"}
                                        onChange={(event) => {
                                            handleChange(event);
                                            setArticleSatusThree(event.target.value === 'true');
                                        }}
                                    >
                                        <FormControlLabel disabled value={true} control={<Radio />} label="Active" />
                                        <FormControlLabel disabled value={false} control={<Radio />} label="Inactive" />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} sx={{mt:3, mb:2}}>
                            <Divider orientation="horizontal" />
                        </Grid>

                        <Grid container spacing={2} alignItems="center" direction="row" justifyContent="center">
                            <Grid item lg={2} xl={2} sx={{pr:2}}>
                                <Typography className="" component={"h6"} variant="h6" sx={{ fontSize: 15 }}>
                                    Position 4
                                </Typography>
                            </Grid>

                            <Grid item lg={6} xl={6} sx={{pr:6}}>
                                <TextField
                                    error={errors.articleFour && touched.articleFour}
                                    label="Article ID"
                                    fullWidth 
                                    name="articleFour"
                                    value={values.articleFour}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    helperText={(errors.articleFour && touched.articleFour) && errors.articleFour}
                                    margin="normal"
                                    variant="outlined"
                                    disabled
                                />
                            </Grid>

                            <Grid item lg={4} xl={4} sx={{pr:4}}>
                                <FormControl sx={{mt:2, width:"100%", textAlign:"left"}}>
                                    <FormLabel id="demo-row-radio-buttons-group-label">Status {" "}</FormLabel>
                                    <RadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="statusFour"
                                        value={articleSatusFour ? "true" : "false"}
                                        onChange={(event) => {
                                            handleChange(event);
                                            setArticleSatusFour(event.target.value === 'true');
                                        }}
                                    >
                                        <FormControlLabel disabled value={true} control={<Radio />} label="Active" />
                                        <FormControlLabel disabled value={false} control={<Radio />} label="Inactive" />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                        </Grid>

                    </CardContent>
                        
                    <CardActions sx={{ justifyContent: "center" }}>
                        <Button
                          type="button"
                          variant="contained"
                          color="primary"
                          component={Link}
                          to={"/news/edit/" + id}
                        >
                          Edit
                        </Button>
                    </CardActions>
                </Card>
          </form>
        )}
      </Formik>
    </Box>
  );
}
