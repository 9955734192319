import { fanCamTypes } from '../types/fanCamTypes'
const initialState = {loading:false, fanCam_data:[], fanCam_status:false, fanCamData:[], fanCamData_status:false};
export const fanCam = (state = initialState , action) => {
    switch(action.type){
        case fanCamTypes.GETALL_FANCAM_REQUEST:
            return {
                loading: true
            };
        case fanCamTypes.GETALL_FANCAM_SUCCESS :
            return {
                ...state,
                loading: false,
                fanCam_status:true,
                fanCam_data:action.data.menuItem  
            }
            
        case fanCamTypes.GETALL_FANCAM_ERROR :
            return {
                loading: false,
                error : action.error
            }
            case fanCamTypes.ADD_FANCAM_REQUEST :
                return {
                    loading: true,
                    data : action.data
                };
            case fanCamTypes.ADD_FANCAM_SUCCESS :
                return  {
                    loading: false,
                    item:action.data
                }
            case fanCamTypes.ADD_FANCAM_ERROR :
                return {
                    loading: false,
                    error : action.error, 
                }    
            case fanCamTypes.EDIT_FANCAM_REQUEST :
                return {
                    loading: true,
                };
            case fanCamTypes.EDIT_FANCAM_SUCCESS :
                return  {
                    ...state,
                    loading: false,
                    fanCamData_status: true, 
                    fanCamData:action.data,
                }
            case fanCamTypes.EDIT_FANCAM_ERROR :
                return {
                    loading: false,
                    error : action.error
                }
    
            case fanCamTypes.UPDATE_FANCAM_REQUEST :
                return {
                    loading: true,
                    data : action.data
                };
            case fanCamTypes.UPDATE_FANCAM_SUCCESS :
                return  {
                    loading: false,
                    item:action.data
                }
            case fanCamTypes.UPDATE_FANCAM_ERROR :
                return {
                    loading: false,
                    error : action.error, 
                }
            default :
                return state
    }
}