import React from "react";
import { useDispatch } from "react-redux";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
//import DialogTitle from '@mui/material/DialogTitle';
import Slide from "@mui/material/Slide";
import { shopAction } from "../../redux/actions/shopAction";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function DeleteDialog(props) {
  const dispatch = useDispatch();

  const handleDelete = () => {
    if (props.product_id) {
      dispatch(shopAction.deleteProduct(props.product_id, props.close));
    }
  };

  return (
    <Dialog
      open={props.open}
      TransitionComponent={Transition}
      //onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Are you sure <span>you want to delete this product?</span>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={handleDelete}>
          Yes
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => {
            props.close();
          }}
        >
          No
        </Button>
      </DialogActions>
    </Dialog>
  );
}
