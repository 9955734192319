import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
//import { createLogger } from 'redux-logger';
import { authentication } from './reducers/authentication.reducer';
import { shops } from './reducers/shop.reducer';
import { tickets } from './reducers/ticket.reducer';
import { banners } from './reducers/banner.reducer';
import { maps } from './reducers/map.reducer';
import { settings } from './reducers/setting.reducer';
import { tradablebits } from './reducers/tradableBits.reducer';
import { userauthentication } from './reducers/user.authentication.reducer';
import { cueIntegration } from './reducers/cue.reducer';
import { fanCam } from './reducers/fanCam.reducer';
import { playerInfo } from './reducers/playerInfo.reducer';
import { gameslider } from './reducers/gameslider.reducer';
import { promotile } from './reducers/promotile.reducer';
import { newsarticle } from './reducers/newsarticle.reducer';

export const rootReducer = combineReducers({
  authentication,
  shops,
  tickets,
  banners,
  maps,
  settings,
  tradablebits,
  userauthentication,
  cueIntegration,
  fanCam,
  playerInfo,
  gameslider,
  promotile,
  newsarticle
});

//const loggerMiddleware = createLogger();
//export const store = createStore(rootReducer,applyMiddleware(thunkMiddleware,loggerMiddleware));
export const store = createStore(rootReducer, applyMiddleware(thunkMiddleware));
