import { authTypes } from './../types/authTypes';
let accessToken = localStorage.getItem('accessToken');
const initialState = accessToken ? { loggedIn: true, isLoading : false } : { loggedIn:false, verifyLinkStatus:false, isLoading : false};

export const authentication = (state= initialState, action) => {
    switch(action.type){
        case authTypes.LOGIN_REQUEST :
            return { 
                ...state,
                isLoading : true}
        case authTypes.LOGIN_SUCCESS : 
            return { 
                ...state,
                loggedIn:true,
                isLoading : false,
                data : action.data 
            }
        case authTypes.LOGIN_ERROR : 
            return { 
                ...state,
                loggedIn:false,
                isLoading : false,
                error : action.error }

        case authTypes.FORGOT_PASSWORD_REQUEST : 
            return { isLoading : true}
        case authTypes.FORGOT_PASSWORD_SUCCESS : 
            return { 
                ...state,
                isLoading : false,
                forgortData : action.data 
            }
        case authTypes.FORGOT_PASSWORD_ERROR : 
            return { error : action.error }

        case authTypes.RESET_PASSWORD_REQUEST :
            return { 
                ...state,
                isLoading : true}
        case authTypes.RESET_PASSWORD_SUCCESS : 
            return { 
                ...state,
                isLoading : false,
                data : action.data }
        case authTypes.RESET_PASSWORD_ERROR : 
            return { 
                ...state,
                isLoading : false,
                error : action.error 
            }
        case authTypes.GET_RESET_PASSWORD_REQUEST :
            return { 
                ...state,
                verifyLinkStatus:"",
                isLoading : true}
        case authTypes.GET_RESET_PASSWORD_SUCCESS : 
            return { 
                ...state,
                isLoading : false,
                verifyLinkStatus:action.data,
                data : action.data }
        case authTypes.GET_RESET_PASSWORD_ERROR : 
            return { 
                ...state,
                isLoading : false,
                verifyLinkStatus:false,
                error : action.error 
            }

        case authTypes.LOGOUT : 
            return { 
                loggedIn:false,
                isLoading : false }
   
        default :
            return state
    }

}