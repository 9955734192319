import { gamesliderTypes } from '../types/gamesliderTypes'
const initialState = {loading:false, slider_data:[], slider_status:false, gameSliderData:[], gameSliderData_status:false};
export const gameslider = (state = initialState , action) => {
    switch(action.type){
        case gamesliderTypes.GETALL_GAMESLIDER_REQUEST:
            return {
                loading: true
            };
        case gamesliderTypes.GETALL_GAMESLIDER_SUCCESS :
            return {
                ...state,
                loading: false,
                slider_status:true,
                slider_data:action.data.gameSlider  
            }
            
        case gamesliderTypes.GETALL_GAMESLIDER_ERROR :
            return {
                loading: false,
                error : action.error
            }
            case gamesliderTypes.ADD_GAMESLIDER_REQUEST :
                return {
                    loading: true,
                    data : action.data
                };
            case gamesliderTypes.ADD_GAMESLIDER_SUCCESS :
                return  {
                    loading: false,
                    item:action.data
                }
            case gamesliderTypes.ADD_GAMESLIDER_ERROR :
                return {
                    loading: false,
                    error : action.error, 
                }    
            case gamesliderTypes.EDIT_GAMESLIDER_REQUEST :
                return {
                    loading: true,
                };
            case gamesliderTypes.EDIT_GAMESLIDER_SUCCESS :
                return  {
                    ...state,
                    loading: false,
                    gameSliderData_status: true, 
                    gameSliderData:action.data,
                }
            case gamesliderTypes.EDIT_GAMESLIDER_ERROR :
                return {
                    loading: false,
                    error : action.error
                }
    
            case gamesliderTypes.UPDATE_GAMESLIDER_REQUEST :
                return {
                    loading: true,
                    data : action.data
                };
            case gamesliderTypes.UPDATE_GAMESLIDER_SUCCESS :
                return  {
                    loading: false,
                    item:action.data
                }
            case gamesliderTypes.UPDATE_GAMESLIDER_ERROR :
                return {
                    loading: false,
                    error : action.error, 
                }
            default :
                return state
    }
}