export const ticketTypes = {
    GETALL_TICKET_REQUEST  : 'GETALL_TICKET_REQUEST',
    GETALL_TICKET_SUCCESS  : 'GETALL_TICKET_SUCCESS',
    GETALL_TICKET_ERROR  : 'GETALL_TICKET_ERROR',

    ADD_TICKET_REQUEST : 'ADD_TICKET_REQUEST',
    ADD_TICKET_SUCCESS : 'ADD_TICKET_SUCCESS',
    ADD_TICKET_ERROR  : 'ADD_TICKET_ERROR',

    EDIT_TICKET_REQUEST : 'EDIT_TICKET_REQUEST',
    EDIT_TICKET_SUCCESS : 'EDIT_TICKET_SUCCESS',
    EDIT_TICKET_ERROR  : 'EDIT_TICKET_ERROR',

    UPDATE_TICKET_REQUEST : 'UPDATE_TICKET_REQUEST',
    UPDATE_TICKET_SUCCESS : 'UPDATE_TICKET_SUCCESS',
    UPDATE_TICKET_ERROR  : 'UPDATE_TICKET_ERROR',

    DELETE_TICKET_REQUEST : 'DELETE_TICKET_REQUEST',
    DELETE_TICKET_SUCCESS : 'DELETE_TICKET_SUCCESS',
    DELETE_TICKET_ERROR  : 'DELETE_TICKET_ERROR',

    CHANGE_ORDER_TICKET_REQUEST : 'CHANGE_ORDER_TICKET_REQUEST',
    CHANGE_ORDER_TICKET_SUCCESS : 'CHANGE_ORDER_TICKET_SUCCESS',
    CHANGE_ORDER_TICKET_ERROR  : 'CHANGE_ORDER_TICKET_ERROR',


}