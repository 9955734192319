import { gamesliderTypes } from '../types/gamesliderTypes';
import { gamesliderService } from '../../services/gamesliderService'; 
import { toast } from 'react-toastify';

export const gamesliderAction = { 
    getAll,
    add,
    edit,
    update,
}

function getAll() {
    return dispatch => {
        dispatch({type:gamesliderTypes.GETALL_GAMESLIDER_REQUEST}); 
        gamesliderService.getAll().then(res => {
            if (res.status) {
                dispatch({type:gamesliderTypes.GETALL_GAMESLIDER_SUCCESS, data: res.data });
                //toast.success(res.message);
            } else {
                dispatch({type:gamesliderTypes.GETALL_GAMESLIDER_ERROR}); 
                toast.error(res.message);
            }
        })

    }
}
function add(data, setErrors, setSubmitting, navigate){
    return dispatch => {
        dispatch({type:gamesliderTypes.ADD_GAMESLIDER_REQUEST});
        gamesliderService.add(data).then(res => {
            if(res.status){
               dispatch({type:gamesliderTypes.ADD_GAMESLIDER_SUCCESS, data});
               toast.success(res.message);
               setSubmitting(false);
               setTimeout(function () {
                    dispatch(gamesliderAction.getAll());
                }, 500);
               //navigate(-1);
            }else{
                dispatch({type:gamesliderTypes.ADD_GAMESLIDER_ERROR, error:res.error});
                toast.error(res.message);
                setSubmitting(false);
                if(res.error) {
                    setErrors(res.error);
                }
            }
        })
    }
}


function update(code, data, setErrors, setSubmitting, navigate){    
    return dispatch => {
        dispatch({type:gamesliderTypes.UPDATE_GAMESLIDER_REQUEST});
        gamesliderService.update(code,  data).then(res => {
            if(res.status){
                dispatch({type:gamesliderTypes.UPDATE_GAMESLIDER_SUCCESS, data:res.data});
                toast.success(res.message);
                setSubmitting(false);
                navigate("/gameslider/view/"+code);
            }else{ 
                dispatch({type:gamesliderTypes.UPDATE_GAMESLIDER_ERROR, error:res.error});
                toast.error(res.message);
                setSubmitting(false);
                if(res.error) {
                    setErrors(res.error);
                    setSubmitting(false);
                }
            }
        })
    }
}

function edit(id){
    return dispatch => {
        dispatch({type:gamesliderTypes.EDIT_GAMESLIDER_REQUEST});
        gamesliderService.edit(id).then(res => {
            if(res.status){
                dispatch({type:gamesliderTypes.EDIT_GAMESLIDER_SUCCESS, data:res.data})
            }else{
                dispatch({type:gamesliderTypes.EDIT_GAMESLIDER_ERROR, error:res.error});
                toast.error(res.message);
            }
        })
    }
}
