import * as Yup from 'yup';

export const LoginValidate = Yup.object().shape({
  email: Yup.string()
    .email('Enter a valid Email Address')
    .max(64, 'Email Address cannot exceed 64 characters')
    .min(8, 'Email Address cannot be less than 8 characters')
    .required('Email Address cannot be empty'),
  password: Yup.string()
    .required('Password cannot be empty')
    .max(16, 'Password cannot exceed 16 characters'),
});

export const ForgotPaswdValidate = Yup.object().shape({
  email: Yup.string()
    .email('Enter a valid Email Address')
    .max(64, 'Email Address cannot exceed 64 characters')
    .min(8, 'Email Address cannot be less than 8 characters')
    .required('Email Address cannot be empty'),
});

export const ResetPasswordValidate = Yup.object().shape({
  password: Yup.string()
    .required('Password cannot be empty')
    .min(8, 'Password cannot be less than 8 characters')
    .max(16, 'Password cannot exceed 16 characters')
    //eslint-disable-next-line
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{1,})/,
      'Password must contain at least 1 digit, 1 lowercase letter,1 uppercase letter and 1 special character'
    ),
  confirmPassword: Yup.string()
    .required('Confirm Password cannot be empty')
    .oneOf([Yup.ref('password'), null], 'Password mismatch! Retry'),
});

export const userSetPasswordValidation = Yup.object().shape({
  password: Yup.string()
    .required('Password cannot be empty')
    .min(8, 'Password cannot be less than 8 characters')
    .max(16, 'Password cannot exceed 16 characters')
    //eslint-disable-next-line
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{1,})/,
      'Password must contain at least 1 digit, 1 lowercase letter,1 uppercase letter and 1 special character'
    ),
  confirmPassword: Yup.string()
    .required('Confirm Password cannot be empty')
    .oneOf([Yup.ref('password'), null], 'Password mismatch! Retry'),
});

export const ProductValidation = Yup.object().shape({
  productName: Yup.string()
    .max(64, 'Product Name cannot exceed 64 characters')
    .required('Product Name cannot be empty'),
  productPrice: Yup.string()
    .max(6, 'Product Price cannot exceed 6 characters')
    .required('Product Price cannot be empty'),
  url: Yup.string()
    .max(2048, 'URL cannot exceed 2048 characters')
    .min(8, 'URL cannot be less than 8 characters')
    .url('Enter a valid URL')
    .required('URL cannot be empty'),
});

export const TicketValidation = Yup.object().shape({
  eventName: Yup.string()
    .max(128, 'Banner Name cannot exceed 128 characters')
    .required('Banner Name cannot be empty'),
  buttonText: Yup.string()
    .max(16, 'Button Text cannot exceed 16 characters'),
    //.required('Button Text cannot be empty'),
  buttonUrl: Yup.string()
    .max(2048, 'URL cannot exceed 2048 characters')
    .min(8, 'URL cannot be less than 8 characters')
    .url('Enter a valid URL'),
    //.required('URL cannot be empty'),
});

export const BannerValidation = Yup.object().shape({
  bannerName: Yup.string()
    .max(128, 'Banner Name cannot exceed 128 characters')
    .required('Banner Name cannot be empty'),
  buttonText: Yup.string()
    .max(16, 'Button Text cannot exceed 16 characters')
    .required('Button Text cannot be empty'),
  buttonUrl: Yup.string()
    .max(2048, 'URL cannot exceed 2048 characters')
    .min(8, 'URL cannot be less than 8 characters')
    .url('Enter a valid URL')
    .required('URL cannot be empty'),
});

export const TradableBitsValidation = Yup.object().shape({
  name: Yup.string()
    .max(128, 'Name cannot exceed 128 characters')
    .required('Name cannot be empty'),
  tradableBitsUrl: Yup.string()
    .max(2048, 'URL cannot exceed 2048 characters')
    .min(8, 'URL cannot be less than 8 characters')
    .url('Enter a valid URL')
    .required('URL cannot be empty'),
});

export const StadiumValidation = Yup.object().shape({
  stadiumName: Yup.string()
    .max(128, 'Stadium Name cannot exceed 128 characters')
    .required('Stadium Name cannot be empty'),
});

export const SettingValidation = Yup.object().shape({
  year: Yup.string().required('Please select the year'),
  concessionURL: Yup.string()
    .max(2048, 'URL cannot exceed 2048 characters')
    .min(8, 'URL cannot be less than 8 characters')
    .url('Enter a valid URL')
    .required('URL cannot be empty'),
    isShowSlider: Yup.string().required('Please choose the Show Match Slider'),  
});

export const ChangePasswordValidation = Yup.object().shape({
  oldpassword: Yup.string().required('Current Password cannot be empty'),
  password: Yup.string()
    .required('New Password cannot be empty')
    .matches(/^\S+$/, 'Password cannot contain spaces')
    .min(8, 'New Password should contain minimum 8 characters')
    .max(16, 'New Password cannot exceed 16 characters')
    //eslint-disable-next-line
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{1,})/,
      'New Password must contain at least 1 digit, 1 lowercase letter,1 uppercase letter and 1 special character'
    ),
  confirmPassword: Yup.string()
    .required('Confirm Password cannot be empty')
    .oneOf([Yup.ref('password'), null], 'Password mismatch! Retry'),
});

export const UserResetPasswordValidate = Yup.object().shape({
  password: Yup.string()
    .required('Password cannot be empty')
    .min(8, 'Password cannot be less than 8 characters')
    .max(16, 'Password cannot exceed 16 characters')
    //eslint-disable-next-line
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{1,})/,
      'Password must contain at least 1 digit, 1 lowercase letter,1 uppercase letter and 1 special character'
    ),
  confirmPassword: Yup.string()
    .required('Confirm Password cannot be empty')
    .oneOf([Yup.ref('password'), null], 'Password mismatch! Retry'),
});

export const FanCamValidation = Yup.object().shape({
  name: Yup.string()
    .max(128, 'Name cannot exceed 128 characters')
    .required('Name cannot be empty'),
  redirectURL: Yup.string()
    .max(2048, 'URL cannot exceed 2048 characters')
    .min(8, 'URL cannot be less than 8 characters')
    .url('Enter a valid URL')
    .required('URL cannot be empty'),
});

export const cueValidation = Yup.object().shape({
  name: Yup.string()
    .max(128, 'Name cannot exceed 128 characters')
    .required('Name cannot be empty'),
});


export const PlayerInfoValidation = Yup.object().shape({
  altFirstName: Yup.string()
    .max(64, 'First Name cannot exceed 64 characters')
    .matches(/^[a-zA-Z\s!@#$%^&*()\-_=+\[\]{};:'",.<>/?]*$/, 'First Name cannot contain numeric values'),
    altLastName: Yup.string()
    .max(64, 'Last Name cannot exceed 64 characters')
    .matches(/^[a-zA-Z\s!@#$%^&*()\-_=+\[\]{};:'",.<>/?]*$/, 'Last Name cannot contain numeric values'),

    altShirtNumber: Yup.string()
    .max(3, 'Shirt Number cannot exceed 3 characters')
    .matches(/^[0-9]*$/, 'Shirt Number cannot contain alphabets or special characters'),
});



export const GameSliderValidation = Yup.object().shape({
  name: Yup.string()
    .max(128, 'Name cannot exceed 128 characters')
    .required('Name cannot be empty'),
  sliderUrl: Yup.string()
    .max(2048, 'URL cannot exceed 2048 characters')
    .min(8, 'URL cannot be less than 8 characters')
    .url('Enter a valid URL')
    .required('URL cannot be empty'),
});


export const PromoTileValidation = Yup.object().shape({
  redirectUrl: Yup.string()
    .max(2048, 'URL cannot exceed 2048 characters')
    .min(8, 'URL cannot be less than 8 characters')
    .url('Enter a valid URL')
    .required('URL cannot be empty'),
});

// Helper function to test uniqueness
const uniqueArticleID = (value, context) => {
let articles = ['articleOne', 'articleTwo', 'articleThree', 'articleFour'];
if (context.parent) {
    // Remove elements in reverse order to prevent index shifting issues
    if (context.parent.statusFour === "false") {
        articles.splice(3, 1); // Remove the fourth element
    }
    if (context.parent.statusThree === "false") {
        articles.splice(2, 1); // Remove the third element
    }
    if (context.parent.statusTwo === "false") {
        articles.splice(1, 1); // Remove the second element
    }
    if (context.parent.statusOne === "false") {
        articles.splice(0, 1); // Remove the first element
    }
}
//console.log(articles, 'article');

const articlesValues = articles.map(article => context.parent[article]);
const uniqueArticles = new Set(articlesValues.filter(Boolean));

// If all values are unique, return true
if (uniqueArticles.size === articlesValues.length) {
return true;
}

// If there are duplicates, only show the error for the current field if it's not unique
const valueOccurrences = articlesValues.filter(articleValue => articleValue === value).length;
return valueOccurrences === 1;
};
export const NewsArticleValidation = Yup.object().shape({
  // articleOne: Yup.string()
  //     .max(48, 'Article ID cannot exceed 48 characters')
  //     .min(32, 'Article ID cannot be less than 32 characters')
  //     .matches(noSpecialChars, 'Article ID cannot contain spaces or special characters (!, @, #, $, %, ^, &, *, (, ))')
  //     .required('Article ID cannot be empty')
  //     .test('unique', 'Article ID must be unique', uniqueArticleID),

  articleOne: Yup.string().when('statusOne', {
    is: (value) => value === 'true',
    then: (schema) => schema
      .max(64, 'Article ID cannot exceed 64 characters')
      .min(32, 'Article ID cannot be less than 32 characters')
      .matches(/^\S*$/, 'Article ID cannot contain spaces')
      .required('Article ID cannot be empty')
      .test('unique', 'Article ID is duplicated.  Please provide a different Article ID', uniqueArticleID),
    otherwise: (schema) => schema.notRequired()
      .max(64, 'Article ID cannot exceed 64 characters')
      .min(32, 'Article ID cannot be less than 32 characters')
      .matches(/^\S*$/, 'Article ID cannot contain spaces')
  }),

  articleTwo: Yup.string().when('statusTwo', {
    is: (value) => value === 'true',
    then: (schema) => schema
      .max(64, 'Article ID cannot exceed 64 characters')
      .min(32, 'Article ID cannot be less than 32 characters')
      .matches(/^\S*$/, 'Article ID cannot contain spaces')
      .required('Article ID cannot be empty')
      .test('unique', 'Article ID is duplicated.  Please provide a different Article ID', uniqueArticleID),
    otherwise: (schema) => schema.notRequired()
      .max(64, 'Article ID cannot exceed 64 characters')
      .min(32, 'Article ID cannot be less than 32 characters')
      .matches(/^\S*$/, 'Article ID cannot contain spaces')
  }),

  articleThree: Yup.string().when('statusThree', {
    is: (value) => value === 'true',
    then: (schema) => schema
      .max(64, 'Article ID cannot exceed 64 characters')
      .min(32, 'Article ID cannot be less than 32 characters')
      .matches(/^\S*$/, 'Article ID cannot contain spaces')
      .required('Article ID cannot be empty')
      .test('unique', 'Article ID is duplicated.  Please provide a different Article ID', uniqueArticleID),
      otherwise: (schema) => schema.notRequired()
      .max(64, 'Article ID cannot exceed 64 characters')
      .min(32, 'Article ID cannot be less than 32 characters')
      .matches(/^\S*$/, 'Article ID cannot contain spaces')
  }),

  articleFour: Yup.string().when('statusFour', {
    is: (value) => value === 'true',
    then: (schema) => schema
      .max(64, 'Article ID cannot exceed 64 characters')
      .min(32, 'Article ID cannot be less than 32 characters')
      .matches(/^\S*$/, 'Article ID cannot contain spaces')
      .required('Article ID cannot be empty')
      .test('unique', 'Article ID is duplicated.  Please provide a different Article ID', uniqueArticleID),
    otherwise: (schema) => schema.notRequired()
      .max(64, 'Article ID cannot exceed 64 characters')
      .min(32, 'Article ID cannot be less than 32 characters')
      .matches(/^\S*$/, 'Article ID cannot contain spaces')
  }),
  
});