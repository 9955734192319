export const gamesliderTypes = {
    GETALL_GAMESLIDER_REQUEST  : 'GETALL_GAMESLIDER_REQUEST',
    GETALL_GAMESLIDER_SUCCESS  : 'GETALL_GAMESLIDER_SUCCESS',
    GETALL_GAMESLIDER_ERROR  : 'GETALL_GAMESLIDER_ERROR',

    ADD_GAMESLIDER_REQUEST : 'ADD_GAMESLIDER_REQUEST',
    ADD_GAMESLIDER_SUCCESS : 'ADD_GAMESLIDER_SUCCESS',
    ADD_GAMESLIDER_ERROR  : 'ADD_GAMESLIDER_ERROR',

    EDIT_GAMESLIDER_REQUEST : 'EDIT_GAMESLIDER_REQUEST',
    EDIT_GAMESLIDER_SUCCESS : 'EDIT_GAMESLIDER_SUCCESS',
    EDIT_GAMESLIDER_ERROR  : 'EDIT_GAMESLIDER_ERROR',

    UPDATE_GAMESLIDER_REQUEST : 'UPDATE_GAMESLIDER_REQUEST',
    UPDATE_GAMESLIDER_SUCCESS : 'UPDATE_GAMESLIDER_SUCCESS',
    UPDATE_GAMESLIDER_ERROR  : 'UPDATE_GAMESLIDER_ERROR',
}