export const authTypes = {
    LOGIN_REQUEST: 'LOGIN_REQUEST',
    LOGIN_SUCCESS: 'LOGIN_SUCCESS',
    LOGIN_ERROR: 'LOGIN_ERROR',

    REGISTER_REQUEST: 'REGISTER_REQUEST',
    REGISTER_SUCCESS: 'REGISTER_SUCCESS',
    REGISTER_ERROR: 'REGISTER_ERROR',

    VERIFY_EMAIL_REQUEST: 'VERIFY_EMAIL_REQUEST',
    VERIFY_EMAIL_SUCCESS: 'VERIFY_EMAIL_SUCCESS',
    VERIFY_EMAIL_ERROR: 'VERIFY_EMAIL_ERROR',

    FORGOT_PASSWORD_REQUEST: 'FORGOT_PASSWORD_REQUEST',
    FORGOT_PASSWORD_SUCCESS: 'FORGOT_PASSWORD_SUCCESS',
    FORGOT_PASSWORD_ERROR: 'FORGOT_PASSWORD_ERROR',

    RESET_PASSWORD_REQUEST: 'RESET_PASSWORD_REQUEST',
    RESET_PASSWORD_SUCCESS: 'RESET_PASSWORD_SUCCESS',
    RESET_PASSWORD_ERROR: 'RESET_PASSWORD_ERROR',

    SET_PASSWORD_REQUEST: 'SET_PASSWORD_REQUEST',
    SET_PASSWORD_SUCCESS: 'SET_PASSWORD_SUCCESS',
    SET_PASSWORD_ERROR: 'SET_PASSWORD_ERROR',

    GET_SET_PASSWORD_REQUEST: 'GET_SET_PASSWORD_REQUEST',
    GET_SET_PASSWORD_SUCCESS: 'GET_SET_PASSWORD_SUCCESS',
    GET_SET_PASSWORD_ERROR: 'GET_SET_PASSWORD_ERROR',

    GET_RESET_PASSWORD_REQUEST: 'GET_RESET_PASSWORD_REQUEST',
    GET_RESET_PASSWORD_SUCCESS: 'GET_RESET_PASSWORD_SUCCESS',
    GET_RESET_PASSWORD_ERROR: 'GET_RESET_PASSWORD_ERROR',

    ISAUTHENTICATED_REQUEST: 'ISAUTHENTICATED_REQUEST',
    ISAUTHENTICATED_SUCCESS: 'ISAUTHENTICATED_SUCCESS',
    ISAUTHENTICATED_ERROR: 'ISAUTHENTICATED_ERROR',

    VERIFY_COMPANY_REQUEST: 'VERIFY_COMPANY_REQUEST',
    VERIFY_COMPANY_SUCCESS: 'VERIFY_COMPANY_SUCCESS',
    VERIFY_COMPANY_ERROR: 'VERIFY_COMPANY_ERROR',

    ACTIVATE_COMPANY_REQUEST: 'ACTIVATE_COMPANY_REQUEST',
    ACTIVATE_COMPANY_SUCCESS: 'ACTIVATE_COMPANY_SUCCESS',
    ACTIVATE_COMPANY_ERROR: 'ACTIVATE_COMPANY_ERROR',

    LOGOUT: 'LOGOUT'
}