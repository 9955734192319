export const bannerTypes = {
    GETALL_BANNER_REQUEST  : 'GETALL_BANNER_REQUEST',
    GETALL_BANNER_SUCCESS  : 'GETALL_BANNER_SUCCESS',
    GETALL_BANNER_ERROR  : 'GETALL_BANNER_ERROR',

    ADD_BANNER_REQUEST : 'ADD_BANNER_REQUEST',
    ADD_BANNER_SUCCESS : 'ADD_BANNER_SUCCESS',
    ADD_BANNER_ERROR  : 'ADD_BANNER_ERROR',

    EDIT_BANNER_REQUEST : 'EDIT_BANNER_REQUEST',
    EDIT_BANNER_SUCCESS : 'EDIT_BANNER_SUCCESS',
    EDIT_BANNER_ERROR  : 'EDIT_BANNER_ERROR',

    UPDATE_BANNER_REQUEST : 'UPDATE_BANNER_REQUEST',
    UPDATE_BANNER_SUCCESS : 'UPDATE_BANNER_SUCCESS',
    UPDATE_BANNER_ERROR  : 'UPDATE_BANNER_ERROR',

    DELETE_BANNER_REQUEST : 'DELETE_BANNER_REQUEST',
    DELETE_BANNER_SUCCESS : 'DELETE_BANNER_SUCCESS',
    DELETE_BANNER_ERROR  : 'DELETE_BANNER_ERROR',

    CHANGE_ORDER_BANNER_REQUEST : 'CHANGE_ORDER_BANNER_REQUEST',
    CHANGE_ORDER_BANNER_SUCCESS : 'CHANGE_ORDER_BANNER_SUCCESS',
    CHANGE_ORDER_BANNER_ERROR  : 'CHANGE_ORDER_BANNER_ERROR',


}