/* Base API URL */
import config from './index';

/* AUTH */
const AUTH = {
  LOGIN: config.apiBaseUrl + '/api/v1/auth/admin/login',
  FORGOT_PASSWORD: config.apiBaseUrl + '/api/v1/auth/admin/forgotPassword',
  RESET_PASSWORD: config.apiBaseUrl + '/api/v1/auth/admin/resetPassword',
  GET_RESET_PASSWORD:
    config.apiBaseUrl + '/api/v1/auth/admin/checkResetPassword',
};
/* USER AUTH */
const USER_AUTH = {
  RESET_PASSWORD: config.apiBaseUrl + '/api/v1/auth/resetPassword',
  GET_RESET_PASSWORD: config.apiBaseUrl + '/api/v1/auth/resetPassword',
};

/* AUTH */
const SHOP = {
  GETALL: config.apiBaseUrl + '/api/v1/admin/shop',
  ADD: config.apiBaseUrl + '/api/v1/admin/shop',
  GET_INDIVIDUAL: config.apiBaseUrl + '/api/v1/admin/shop',
  UPDATE_INDIVIDUAL: config.apiBaseUrl + '/api/v1/admin/shop',
  DELETE: config.apiBaseUrl + '/api/v1/admin/shop',
  CHANGE_ORDER: config.apiBaseUrl + '/api/v1/admin/shop',
};

const TICKET = {
  GETALL: config.apiBaseUrl + '/api/v1/admin/tickets',
  ADD: config.apiBaseUrl + '/api/v1/admin/tickets',
  GET_INDIVIDUAL: config.apiBaseUrl + '/api/v1/admin/tickets',
  UPDATE_INDIVIDUAL: config.apiBaseUrl + '/api/v1/admin/tickets',
  DELETE: config.apiBaseUrl + '/api/v1/admin/tickets',
  CHANGE_ORDER: config.apiBaseUrl + '/api/v1/admin/tickets',
};

const BANNER = {
  GETALL: config.apiBaseUrl + '/api/v1/admin/offerBanners',
  ADD: config.apiBaseUrl + '/api/v1/admin/offerBanners',
  GET_INDIVIDUAL: config.apiBaseUrl + '/api/v1/admin/offerBanners',
  UPDATE_INDIVIDUAL: config.apiBaseUrl + '/api/v1/admin/offerBanners',
  DELETE: config.apiBaseUrl + '/api/v1/admin/offerBanners',
  CHANGE_ORDER: config.apiBaseUrl + '/api/v1/admin/offerBanners',
};

const STADIUM_MAP = {
  GETALL: config.apiBaseUrl + '/api/v1/admin/stadiumMaps',
  ADD: config.apiBaseUrl + '/api/v1/admin/stadiumMaps',
  GET_INDIVIDUAL: config.apiBaseUrl + '/api/v1/admin/stadiumMaps',
  UPDATE_INDIVIDUAL: config.apiBaseUrl + '/api/v1/admin/stadiumMaps',
  DELETE: config.apiBaseUrl + '/api/v1/admin/stadiumMaps',
  CHANGE_ORDER: config.apiBaseUrl + '/api/v1/admin/stadiumMaps',
};
const SETTINGS = {
  GET: config.apiBaseUrl + '/api/v1/admin/settings',
  UPDATE: config.apiBaseUrl + '/api/v1/admin/settings',
  CHANGE_PASSWORD: config.apiBaseUrl + '/api/v1/admin/settings',
};

const TRADABLEBITS = {
  GETALL: config.apiBaseUrl + '/api/v1/admin/tradableBits',
  ADD: config.apiBaseUrl + '/api/v1/admin/tradableBits',
  GET_INDIVIDUAL: config.apiBaseUrl + '/api/v1/admin/tradableBits',
  UPDATE_INDIVIDUAL: config.apiBaseUrl + '/api/v1/admin/tradableBits',
};

const FANCAM = {
  GETALL: config.apiBaseUrl + '/api/v1/admin/menuItem',
  ADD: config.apiBaseUrl + '/api/v1/admin/menuItem',
  GET_INDIVIDUAL: config.apiBaseUrl + '/api/v1/admin/menuItem',
  UPDATE_INDIVIDUAL: config.apiBaseUrl + '/api/v1/admin/menuItem',
};


const CUE_INTEGRATION = {
  GETALL: config.apiBaseUrl + '/api/v1/admin/cueIntegration',
  ADD: config.apiBaseUrl + '/api/v1/admin/cueIntegration',
  GET_INDIVIDUAL: config.apiBaseUrl + '/api/v1/admin/cueIntegration',
  UPDATE_INDIVIDUAL: config.apiBaseUrl + '/api/v1/admin/cueIntegration',
};

const PLAYERINFO = {
  GETALL: config.apiBaseUrl + '/api/v1/admin/players',
  GET_INDIVIDUAL: config.apiBaseUrl + '/api/v1/admin/players',
  UPDATE_INDIVIDUAL: config.apiBaseUrl + '/api/v1/admin/players',
};


const GAMESLIDER = {
  GETALL: config.apiBaseUrl + '/api/v1/admin/gameSlider',
  ADD: config.apiBaseUrl + '/api/v1/admin/gameSlider',
  GET_INDIVIDUAL: config.apiBaseUrl + '/api/v1/admin/gameSlider',
  UPDATE_INDIVIDUAL: config.apiBaseUrl + '/api/v1/admin/gameSlider',
};

const PROMOTILE = {
  GETALL: config.apiBaseUrl + '/api/v1/admin/promoTile',
  ADD: config.apiBaseUrl + '/api/v1/admin/promoTile',
  GET_INDIVIDUAL: config.apiBaseUrl + '/api/v1/admin/promoTile',
  UPDATE_INDIVIDUAL: config.apiBaseUrl + '/api/v1/admin/promoTile',
};

const NEWSARTICLE = {
  GETALL: config.apiBaseUrl + '/api/v1/admin/newsArticle',
  ADD: config.apiBaseUrl + '/api/v1/admin/newsArticle',
  GET_INDIVIDUAL: config.apiBaseUrl + '/api/v1/admin/newsArticle',
  UPDATE_INDIVIDUAL: config.apiBaseUrl + '/api/v1/admin/newsArticle',
};



export {
  AUTH,
  SHOP,
  BANNER,
  TICKET,
  STADIUM_MAP,
  SETTINGS,
  TRADABLEBITS,
  USER_AUTH,
  FANCAM,
  CUE_INTEGRATION,
  PLAYERINFO,
  GAMESLIDER,
  PROMOTILE,
  NEWSARTICLE
};
