export const newsarticleTypes = {
    GETALL_NEWSARTICLE_REQUEST  : 'GETALL_NEWSARTICLE_REQUEST',
    GETALL_NEWSARTICLE_SUCCESS  : 'GETALL_NEWSARTICLE_SUCCESS',
    GETALL_NEWSARTICLE_ERROR  : 'GETALL_NEWSARTICLE_ERROR',

    ADD_NEWSARTICLE_REQUEST : 'ADD_NEWSARTICLE_REQUEST',
    ADD_NEWSARTICLE_SUCCESS : 'ADD_NEWSARTICLE_SUCCESS',
    ADD_NEWSARTICLE_ERROR  : 'ADD_NEWSARTICLE_ERROR',

    EDIT_NEWSARTICLE_REQUEST : 'EDIT_NEWSARTICLE_REQUEST',
    EDIT_NEWSARTICLE_SUCCESS : 'EDIT_NEWSARTICLE_SUCCESS',
    EDIT_NEWSARTICLE_ERROR  : 'EDIT_NEWSARTICLE_ERROR',

    UPDATE_NEWSARTICLE_REQUEST : 'UPDATE_NEWSARTICLE_REQUEST',
    UPDATE_NEWSARTICLE_SUCCESS : 'UPDATE_NEWSARTICLE_SUCCESS',
    UPDATE_NEWSARTICLE_ERROR  : 'UPDATE_NEWSARTICLE_ERROR',
}