export const tradableBitsTypes = {
    GETALL_TRADABLEBITS_REQUEST  : 'GETALL_TRADABLEBITS_REQUEST',
    GETALL_TRADABLEBITS_SUCCESS  : 'GETALL_TRADABLEBITS_SUCCESS',
    GETALL_TRADABLEBITS_ERROR  : 'GETALL_TRADABLEBITS_ERROR',

    ADD_TRADABLEBITS_REQUEST : 'ADD_TRADABLEBITS_REQUEST',
    ADD_TRADABLEBITS_SUCCESS : 'ADD_TRADABLEBITS_SUCCESS',
    ADD_TRADABLEBITS_ERROR  : 'ADD_TRADABLEBITS_ERROR',

    EDIT_TRADABLEBITS_REQUEST : 'EDIT_TRADABLEBITS_REQUEST',
    EDIT_TRADABLEBITS_SUCCESS : 'EDIT_TRADABLEBITS_SUCCESS',
    EDIT_TRADABLEBITS_ERROR  : 'EDIT_TRADABLEBITS_ERROR',

    UPDATE_TRADABLEBITS_REQUEST : 'UPDATE_TRADABLEBITS_REQUEST',
    UPDATE_TRADABLEBITS_SUCCESS : 'UPDATE_TRADABLEBITS_SUCCESS',
    UPDATE_TRADABLEBITS_ERROR  : 'UPDATE_TRADABLEBITS_ERROR',
}