import { settingTypes } from '../types/settingTypes';
import { settingService } from '../../services/settingService';
import { toast } from 'react-toastify';

export const settingAction = {
    getAll,
    update,
    changePassword,
}

function getAll() {
    return dispatch => {
        dispatch({type:settingTypes.GETALL_SETTING_REQUEST}); 
        settingService.getAll().then(res => {
            if (res.status) {
                dispatch({type:settingTypes.GETALL_SETTING_SUCCESS, data: res.data });
                //toast.success(res.message);
            } else {
                dispatch({type:settingTypes.GETALL_SETTING_ERROR}); 
                toast.error(res.message);
            }
        })

    }
}
function update(data, setErrors, setSubmitting){    
    return dispatch => {
        dispatch({type:settingTypes.UPDATE_SETTING_REQUEST});
        settingService.update(data).then(res => {
            if(res.status){
                dispatch({type:settingTypes.UPDATE_SETTING_SUCCESS, data:res.data});
                toast.success(res.message);
                setSubmitting(false);
                setTimeout(function () {
                    dispatch(settingAction.getAll());
                }, 500);
            }else{ 
                dispatch({type:settingTypes.UPDATE_SETTING_ERROR, error:res.error});
                toast.error(res.message);
                setSubmitting(false);
                if(res.error) {
                    setErrors(res.error);
                    setSubmitting(false);
                }
            }
        })
    }
}


function changePassword(data, setErrors, setSubmitting, onCloseModal){    
    return dispatch => {
        dispatch({type:settingTypes.CHANGE_PASSWORD_REQUEST});
        settingService.changePassword(data).then(res => {
            if(res.status){
                dispatch({type:settingTypes.CHANGE_PASSWORD_SUCCESS, data:res.data});
                toast.success(res.message);
                setSubmitting(false);
                onCloseModal();
            }else{ 
                dispatch({type:settingTypes.CHANGE_PASSWORD_ERROR, error:res.error});
                toast.error(res.message);
                setSubmitting(false);
                if(res.error) {
                    setErrors(res.error);
                    setSubmitting(false);
                }
            }
        })
    }
}