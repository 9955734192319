export const playerInfoTypes = {
    GETALL_PLAYER_INFO_REQUEST  : 'GETALL_PLAYER_INFO_REQUEST',
    GETALL_PLAYER_INFO_SUCCESS  : 'GETALL_PLAYER_INFO_SUCCESS',
    GETALL_PLAYER_INFO_ERROR  : 'GETALL_PLAYER_INFO_ERROR',

    GET_SINGLE_PLAYER_INFO_REQUEST : 'GET_SINGLE_PLAYER_INFO_REQUEST',
    GET_SINGLE_PLAYER_INFO_SUCCESS : 'GET_SINGLE_PLAYER_INFO_SUCCESS',
    GET_SINGLE_PLAYER_INFO_ERROR  : 'GET_SINGLE_PLAYER_INFO_ERROR',

    UPDATE_PLAYER_INFO_REQUEST : 'UPDATE_PLAYER_INFO_REQUEST',
    UPDATE_PLAYER_INFO_SUCCESS : 'UPDATE_PLAYER_INFO_SUCCESS',
    UPDATE_PLAYER_INFO_ERROR  : 'UPDATE_PLAYER_INFO_ERROR',
}