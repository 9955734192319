import { settingTypes } from '../types/settingTypes'
const initialState = {loading:false, settings_data:[], settings_status:false};
export const settings = (state = initialState , action) => {
    switch(action.type){
        case settingTypes.GETALL_SETTING_REQUEST:
            return {
                loading: true
            };
        case settingTypes.GETALL_SETTING_SUCCESS :
            return {
                ...state,
                loading: false,
                settings_status:true,
                settings_data:action.data 
            }
            
        case settingTypes.GETALL_SETTING_ERROR :
            return {
                loading: false,
                error : action.error
            }
    
            case settingTypes.UPDATE_SETTING_REQUEST :
                return {
                    loading: true,
                    data : action.data
                };
            case settingTypes.UPDATE_SETTING_SUCCESS :
                return  {
                    loading: false,
                    item:action.data
                }
            case settingTypes.UPDATE_SETTING_ERROR :
                return {
                    loading: false,
                    error : action.error, 
                }
            default :
                return state
    }
}