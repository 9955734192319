import { playerInfoTypes } from '../types/playerInfoTypes';
import { playerInfoService } from '../../services/playerInfoService';
import { toast } from 'react-toastify';

export const playerInfoAction = {
    getAll,
    getSingleItem,
    update,

}
function getAll() {
    return dispatch => {
        dispatch({type:playerInfoTypes.GETALL_PLAYER_INFO_REQUEST}); 
        playerInfoService.getAll().then(res => {
            if (res.status) {
                dispatch({type:playerInfoTypes.GETALL_PLAYER_INFO_SUCCESS, data: res.data });
                //toast.success(res.message);
            } else {
                dispatch({type:playerInfoTypes.GETALL_PLAYER_INFO_ERROR}); 
                toast.error(res.message);
            }
        })

    }
}
function update(code, data, setErrors, setSubmitting, navigate){    
    return dispatch => {
        dispatch({type:playerInfoTypes.UPDATE_PLAYER_INFO_REQUEST});
        playerInfoService.update(code,  data).then(res => {
            if(res.status){
                dispatch({type:playerInfoTypes.UPDATE_PLAYER_INFO_SUCCESS, data:res.data});
                toast.success(res.message);
                setSubmitting(false);
                navigate(-1);
            }else{ 
                dispatch({type:playerInfoTypes.UPDATE_PLAYER_INFO_ERROR, error:res.error});
                toast.error(res.message);
                setSubmitting(false);
                if(res.error) {
                    setErrors(res.error);
                    setSubmitting(false);
                }
            }
        })
    }
}
function getSingleItem(id){
    return dispatch => {
        dispatch({type:playerInfoTypes.GET_SINGLE_PLAYER_INFO_REQUEST});
        playerInfoService.getSingleItem(id).then(res => {
            if(res.status){
                dispatch({type:playerInfoTypes.GET_SINGLE_PLAYER_INFO_SUCCESS, data:res.data})
            }else{
                dispatch({type:playerInfoTypes.GET_SINGLE_PLAYER_INFO_ERROR, error:res.error});
                toast.error(res.message);
            }
        })
    }
}

