import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import Typography from '@mui/material/Typography'; 
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
//import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import CircularProgress from '@mui/material/CircularProgress';
import { SettingValidation } from '../../assets/validation/validation';
import { settingAction } from '../../redux/actions/settingAction';
import { Loader } from "../../errorPages/Loader";

export default function GeneralSettings() {
    const dispatch = useDispatch();
    const [data, setData] = React.useState([]);
    const [yearValue, setYearValue] = React.useState(new Date().getFullYear());
    const [urlValue, setURLValue] = React.useState('');
    const [isShowSlider, setIsShowSlider] = React.useState(false);

    const [yearId, setYearId] = React.useState(null);


    let years = Array.from(Array((new Date().getFullYear() + 10) - new Date().getFullYear()), (_, i) => (i + 2022).toString());

    useEffect(() => { 
        dispatch(settingAction.getAll()); 
    }, [dispatch]);

    const {settings_data , settings_status , loading } = useSelector(state => state.settings);   

    useEffect(() => { 
        if(settings_status === true){
            setData(settings_data);
        }
    }, [settings_status, settings_data])


    useEffect(() => { 
        if(data) {
            if(data.year) {
                setYearValue(data.year);
            }
            if(data.concessionURL) {
                setURLValue(data.concessionURL);
            }
            if(data.isShowSlider) {
                setIsShowSlider(data.isShowSlider);
            }
            if(data._id) {
            setYearId(data._id);
            }
        }
    }, [data])

    console.log(isShowSlider, "isShowSlider");

  return (
    <Box sx={{ mb:3 }} className='cardWrapper'>
        {loading ? <Loader/> : '' }
         <div className="pageTitleWrap">
            <Stack  direction="row" spacing={1}   alignItems="center">
                <Typography className="" component={"h2"} variant="h2" >
                   General Settings
                </Typography>
            </Stack>
        </div>
        <Formik
            initialValues={{
                year : yearValue,
                concessionURL:urlValue,
                isShowSlider: isShowSlider
            }}
            validationSchema={SettingValidation}
            enableReinitialize
            onSubmit={(values, {setErrors, setSubmitting}) => {
                let form_data  = {
                  year:  values.year,
                  settingsId: yearId,
                  concessionURL:values.concessionURL,
                  isShowSlider:  values.isShowSlider
                } 
                dispatch(settingAction.update(form_data, setErrors, setSubmitting));  
            }}>
            {({ errors, touched, values, handleChange, handleBlur, handleSubmit, handleReset, isSubmitting }) => (
            <form onSubmit={handleSubmit}> 
                <Card sx={{}} variant="outlined" className="formWrapper">
                    {/* <CardHeader title="Default Schedule Year"/> */}
                    <CardContent > 
                        <Grid container spacing={0} alignItems="center">
                            <Grid item lg={3} xl={3}>
                            <Typography className="" component={"p"} variant="body1" >
                                Default Schedule Year
                            </Typography>  
                            </Grid>
                            <Grid item lg={4} xl={4} sx={{pr:5}}>
                                <TextField
                                    error={errors.year && touched.year}
                                    label="Year"
                                    fullWidth 
                                    name="year"
                                    select
                                    value={values.year}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    helperText={(errors.year && touched.year) && errors.year}
                                    margin="none"
                                    variant="outlined"
                                    required={false}
                                    InputLabelProps={{ required: true }} 
                                >
                                  {years.map((year,  index) => {
                                      return(
                                          <MenuItem key={index} value={year}>{year}</MenuItem>
                                      )
                                  })}
                                </TextField>
                          </Grid>
                        </Grid>
                        <Grid container sx={{my:3}} spacing={0} alignItems="center">
                            <Grid item lg={3} xl={3}>
                            <Typography className="" component={"p"} variant="body1" >
                                Concession URL
                            </Typography>  
                            </Grid>
                            <Grid item lg={4} xl={4} sx={{pr:5}}>
                                <TextField
                                    error={errors.concessionURL && touched.concessionURL}
                                    label="URL"
                                    fullWidth 
                                    name="concessionURL"
                                    value={values.concessionURL}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    helperText={(errors.concessionURL && touched.concessionURL) && errors.concessionURL}
                                    margin="none"
                                    variant="outlined"
                                    required={false}
                                    InputLabelProps={{ required: true }} 
                                >
                                </TextField>
                          </Grid>
                        </Grid>
                        <Grid container spacing={0} alignItems="center">
                            <Grid item lg={3} xl={3}>
                            <Typography className="" component={"p"} variant="body1" >
                                Show Game Slider
                            </Typography>  
                            </Grid>
                            <Grid item lg={4} xl={4} sx={{pr:5}}>
                                <TextField
                                    error={errors.isShowSlider && touched.isShowSlider}
                                    label="Choose"
                                    fullWidth 
                                    name="isShowSlider"
                                    select
                                    value={values.isShowSlider}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    helperText={(errors.isShowSlider && touched.isShowSlider) && errors.isShowSlider}
                                    margin="none"
                                    variant="outlined"
                                    required={false}
                                    InputLabelProps={{ required: true }} 
                                >
                                    <MenuItem value={true}>Yes</MenuItem>
                                    <MenuItem value={false}>No</MenuItem>
                                </TextField>
                          </Grid>
                        </Grid>
                    </CardContent>
                    <CardActions sx={{justifyContent:"center"}}>
                        <Button type="submit" variant="contained" color="primary"
                          disabled ={isSubmitting} 
                        > {isSubmitting ? <CircularProgress size={20}/> :  "Save"}</Button> 
                        {/* <Button type="button" variant="contained" color="secondary"
                        onClick={(e) => {    
                            handleReset();
                            //navigate(-1);
                        }}
                        >Cancel</Button> */}
                    </CardActions>
                </Card>  
            </form>
            )}
        </Formik>    
    </Box>
  )
}
