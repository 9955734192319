import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

export const Loader = (props) => {
  const opactiyClass = props.className ? props.className : '';

  return (
    <div className={'fullPageLoader ' + opactiyClass}>
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
          '& > :not(style)': {
            m: 1,
          },
        }}
      >
        <CircularProgress />
      </Box>
    </div>
  );
};
