import { GAMESLIDER } from '../configs/ApiActionUrl';
import { httpService } from './httpService';
export const gamesliderService = {
    getAll,
    add,
    edit,
    update,
}

function getAll(){   
    return httpService.get(GAMESLIDER.GETALL)
        .then(res => {
            if (res.data) {
                return res.data;
            }
            return res;
        });
}

function add(data){
    return httpService.post(GAMESLIDER.ADD, data, {headers: {'Content-Type': 'multipart/form-data'}})
    .then(res => {
        if (res.data) {
            return res.data;
        }
        return res;
    });
}
function edit(id){
    return httpService.get(GAMESLIDER.GET_INDIVIDUAL+"/"+id)
        .then(res => {
            if (res.data) {
                return res.data;
            }
            return res;
        });
}
function update(code, data){
    return httpService.put(GAMESLIDER.UPDATE_INDIVIDUAL+"/"+code, data, {headers: {'Content-Type': 'multipart/form-data'}})   
    .then(res => {
        if (res.data) {
            return res.data;
        }
        return res;
    });
}