import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import LoginForm from './components/auth/LoginForm';
import ForgetPasswordForm from './components/auth/ForgetPassword';
import { history } from './helpers/history';
import { NotFoundComponent } from './errorPages/NotFoundComponent';
import { Outlet, Navigate } from 'react-router-dom';
import ResetPasswordForm from './components/auth/ResetPassword';
import UserResetPassword from './components/userAuth/resetPassword';
import UserSuccessResetPassword from './errorPages/SuccessfulkResetPassword';
import { HeaderLayout } from './components/layout/Header';

const PrivateRoute = () => {
  const auth = localStorage.getItem('accessToken');
  return auth ? <Outlet /> : <Navigate to="/" />;
};

const today = new Date();
const year = today.getFullYear();

function AppRoutes() {
  return (
    <BrowserRouter history={history}>
      <Routes>
        <Route exact path="/" element={<LoginForm year={year} />} />
        <Route
          exact
          path="/forget-password"
          element={<ForgetPasswordForm year={year} />}
        />
        <Route
          exact
          path="/reset-password/:id"
          element={<ResetPasswordForm year={year} />}
        />

        <Route
          exact
          path="/auth/reset-password/:id"
          element={<UserResetPassword year={year} />}
        />

        <Route
          exact
          path="/successful-reset-password"
          element={<UserSuccessResetPassword year={year} />}
        />

        <Route exact path="/" element={<PrivateRoute />}>
          <Route exact path="/*" element={<HeaderLayout />} />
        </Route>

        <Route path="/404" exact={true} element={<NotFoundComponent />} />
      </Routes>
    </BrowserRouter>
  );
}

export default AppRoutes;
